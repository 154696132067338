import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

function ArrowLeftIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path
          d="M0.12241 11.2962C0.0836067 11.3427 0.0528221 11.3979 0.0318165 11.4586C0.0108109 11.5194 0 11.5845 0 11.6502C0 11.716 0.0108109 11.7811 0.0318165 11.8419C0.0528221 11.9026 0.0836067 11.9578 0.12241 12.0042L2.77408 15.1862C2.81282 15.2327 2.8588 15.2695 2.90939 15.2946C2.95999 15.3197 3.01421 15.3326 3.06895 15.3325C3.1237 15.3325 3.17791 15.3195 3.22847 15.2943C3.27904 15.2691 3.32497 15.2322 3.36366 15.1857C3.40234 15.1393 3.43302 15.0841 3.45394 15.0234C3.47485 14.9626 3.4856 14.8976 3.48556 14.8319C3.48552 14.7662 3.4747 14.7011 3.45371 14.6405C3.43272 14.5798 3.40198 14.5247 3.36324 14.4782L1.00658 11.6502L3.36324 8.82224C3.40198 8.77582 3.43272 8.7207 3.45371 8.66002C3.4747 8.59934 3.48552 8.53429 3.48556 8.4686C3.4856 8.4029 3.47485 8.33783 3.45394 8.27712C3.43302 8.21641 3.40234 8.16123 3.36366 8.11474C3.32497 8.06825 3.27904 8.03136 3.22847 8.00618C3.17791 7.981 3.1237 7.96801 3.06895 7.96796C3.01421 7.96792 2.95999 7.98081 2.90939 8.00591C2.8588 8.03101 2.81282 8.06782 2.77408 8.11424L0.12241 11.2962ZM19.5841 11.1502L0.41741 11.1502V12.1502L19.5841 12.1502V11.1502Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath>
          <rect width="20" height="20" fill="currentColor" transform="translate(0 0.33252)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}

export { ArrowLeftIcon };
