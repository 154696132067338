import CalendarIcon from '@/assets/inputs/calendar.svg';
import { TextFieldProps } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { ComponentProps } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

type DateFieldProps = Omit<UseControllerProps<any>, 'ref'> & {
  label?: string;
  value?: Date;
  format?: string;
  datePickerProps?: Partial<ComponentProps<typeof DatePicker>>;
  textFieldProps?: Partial<TextFieldProps>;
};

export const DateField = (props: DateFieldProps) => {
  const { label, value, format, datePickerProps, textFieldProps, ...controllerProps } = props;
  const {
    field: { ref, ...fieldProps },
    fieldState: { error },
  } = useController({ defaultValue: value, ...controllerProps });

  const CustomCalendarIcon = () => (
    <img src={CalendarIcon} alt="Date picker opening icon" width={32} />
  );

  return (
    <DatePicker
      sx={{
        '.MuiInputAdornment-root .MuiIconButton-root': {
          padding: '0px',
          margin: '0px',
        },
        '.MuiOutlinedInput-input': {
          borderRight: 'none',
        },
      }}
      slots={{
        openPickerIcon: CustomCalendarIcon,
      }}
      slotProps={{
        textField: {
          label,
          inputRef: ref,
          error: Boolean(error),
          helperText: error?.message,
          ...textFieldProps,
          // inputProps: { ...textFieldProps?.inputProps, readOnly: true }, // Set read-only
        },
      }}
      {...datePickerProps}
      {...(format && { format })}
      {...fieldProps}
    />
  );
};
