import { StepContainer } from '../../StepContainer';
import { AddMoreButton } from '../../UI/buttons';
import { DEFAULT_TYPE_FIELD, LABEL_DISCOUNT_OPTIONS } from './constants';
import {
  LearnAboutYourPracticeFormInputs as FormInputs,
  useLearnAboutYourPracticeForm,
} from './hooks/useLearnAboutYourPracticeForm';
import { InfoModal } from '@/components/Modals/InfoModal';
import { FormRow } from '@/components/UIKit/Form/styled';
import { TrashIcon, AddIcon } from '@/components/UIKit/Icons';
import { ExpandableCheckbox } from '@/components/UIKit/Inputs/Checkbox/ExpandableCheckbox';
import { BoxedRadioGroup } from '@/components/UIKit/Inputs/RadioGroup/RadioGroup';
import { NumberTextField } from '@/components/UIKit/Inputs/TextField/NumberTextField';
import { TextField } from '@/components/UIKit/Inputs/TextField/TextField';
import useBreakpointCheck from '@/hooks/useBreakpointCheck';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  BoxProps,
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
  Tooltip,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { useFieldArray } from 'react-hook-form';

type Props = {
  defaultValues?: Partial<FormInputs>;
  onBack: () => void;
  onContinue: (data: FormInputs) => Promise<void>;
};

// FIELDS
const FIELDS = {
  facialPeelSunscreenTypes: {
    label:
      'What type of facials/peels + sunscreen packages does your practice plan to offer JOYA members?',
    type: 'json',
    example: [
      {
        facialPeelType: 'some type',
        sunscreenType: 'some type',
        cost: '12.50',
      },
      {
        facialPeelType: 'some type 2',
        sunscreenType: 'some type 2',
        cost: '16.50',
      },
    ],
  },
  privateLabelDiscount: {
    label: 'Private label discount',
    type: 'single-select',
    selectOptions: LABEL_DISCOUNT_OPTIONS,
    example: LABEL_DISCOUNT_OPTIONS[0],
  },
  brandLabelDiscount: {
    label: 'Brand label discount',
    type: 'single-select',
    selectOptions: LABEL_DISCOUNT_OPTIONS,
    example: LABEL_DISCOUNT_OPTIONS[0],
  },
  selectedEmrPmrSchedulingSystems: {
    label: 'What EMR/PMR/Scheduling systems are utilized in your practice?',
    type: 'json',
    example: {
      ADVANCED_MD: true,
      EZDERM: false,
      OTHER: { isChecked: true, specifyText: 'Other system' },
    },
  },
};
export { FIELDS as LetsLearnAboutYourPracticeFields };

export const LetsLearnAboutYourPractice = (props: Props) => {
  const isMobile = useBreakpointCheck('sm');

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useLearnAboutYourPracticeForm({
    defaultValues: {
      facialPeelSunscreenTypes: [DEFAULT_TYPE_FIELD],
      ...props.defaultValues,
    },
  });

  const {
    fields: facialPeelSunscreenTypesFields,
    append: addFacialPeelSunscreenTypesField,
    remove: removeFacialPeelSunscreenTypesField,
  } = useFieldArray({ control, name: 'facialPeelSunscreenTypes' });

  const facialPeelSunscreenTypes = watch('facialPeelSunscreenTypes');
  const costExceedsLimit = facialPeelSunscreenTypes
    .map((item, index) => {
      return Number(item.cost) > 40 ? index : -1;
    })
    .filter((index) => index !== -1);

  const [formErrMessage, setFormErrMessage] = useState<string>();
  const [showWarningDialog, setShowWarningDialog] = useState<boolean>(false);

  const onSubmit = async (data: FormInputs) => {
    const { facialPeelSunscreenTypes } = data;

    const anyCostExceedsLimit = facialPeelSunscreenTypes.some((item) => parseFloat(item.cost) > 40);

    if (!showWarningDialog && anyCostExceedsLimit) {
      setShowWarningDialog(true);
      return;
    }

    setFormErrMessage(undefined);
    await props.onContinue(data);
  };

  let hasGroupError: boolean = false;

  // Ensure errors is defined and has the expected structure
  if (errors && Array.isArray(errors.facialPeelSunscreenTypes)) {
    hasGroupError = errors.facialPeelSunscreenTypes.some(
      (error) => error?.facialPeelType || error?.sunscreenType || error?.cost,
    );
  }

  return (
    <StepContainer
      heading="Let’s Learn about your practice"
      description="JOYA member benefits."
      onBack={props.onBack}
      onContinue={() => handleSubmit(onSubmit)()}
    >
      {/* FACIAL PEEL AND SUNSCREEN SECTION */}
      <Typography variant="h4" mb="24px">
        {FIELDS['facialPeelSunscreenTypes'].label}
      </Typography>
      <TooltipText
        mb="24px"
        text="Reimbursement process"
        tooltipText="This benefit is received annually to the patient. JOYA Health processes reimbursement on quarterly basis for sunscreen distributed and facial/peel services inducted."
      />
      {facialPeelSunscreenTypesFields.map((field, index: number) => {
        const costExceedsLimitError = costExceedsLimit.includes(index);
        return (
          <ThreeFieldsRow key={field.id} isMobile={isMobile}>
            <TextField
              control={control}
              register={register(`facialPeelSunscreenTypes.${index}.facialPeelType`)}
              label={index === 0 ? 'Facial/Peel Type' : undefined}
              placeholder="Type"
              InputProps={{
                error:
                  !!errors.facialPeelSunscreenTypes?.[index]?.sunscreenType || costExceedsLimitError
                    ? true
                    : false,
              }}
              helperText={errors.facialPeelSunscreenTypes?.[index]?.facialPeelType?.message}
            />
            <AddIcon sx={{ marginTop: 'auto' }} />
            <TextField
              control={control}
              register={register(`facialPeelSunscreenTypes.${index}.sunscreenType`)}
              sx={{ height: '100%', justifyContent: 'flex-end' }}
              label={index === 0 ? 'Sunscreen Type' : undefined}
              placeholder="Type"
              InputProps={{
                error:
                  !!errors.facialPeelSunscreenTypes?.[index]?.sunscreenType || costExceedsLimitError
                    ? true
                    : false,
              }}
              helperText={errors.facialPeelSunscreenTypes?.[index]?.sunscreenType?.message}
            />

            <ShortFieldContainer isTablet={isMobile}>
              <NumberTextField
                control={control}
                register={register(`facialPeelSunscreenTypes.${index}.cost`)}
                label={index === 0 ? 'Cost of materials to the practice' : undefined}
                numberFieldProps={{ maxDecimals: 2 }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  error:
                    !!errors.facialPeelSunscreenTypes?.[index]?.cost || costExceedsLimitError
                      ? true
                      : undefined,
                }}
                helperText={errors.facialPeelSunscreenTypes?.[index]?.cost?.message}
              />
            </ShortFieldContainer>
            <Stack>
              <FieldDeleteButton
                color="secondary"
                sx={{ ...(index === 0 && { visibility: 'hidden' }) }}
                onClick={() => removeFacialPeelSunscreenTypesField(index)}
              >
                <TrashIcon />
              </FieldDeleteButton>
            </Stack>
          </ThreeFieldsRow>
        );
      })}
      <FieldHelperText isError={Boolean(hasGroupError)}>
        This is a required field you need to enter.
      </FieldHelperText>
      <Box mb="64px">
        <AddMoreButton onClick={() => addFacialPeelSunscreenTypesField(DEFAULT_TYPE_FIELD)} />
      </Box>

      {/* DISCOUNT OFFERS SECTION */}
      <Typography variant="h4" mb="16px">
        What discount will you provide for skincare products?
      </Typography>
      <TooltipText
        text="What we recommend?"
        tooltipText="One of the benefits JOYA members will receive from your practice is a discount on skincare/products. Generally, this discount is in the range of 20-30%. Some practices choose to discount private label at 30%, and other brands at 20%. Keep in mind, this benefit also helps drive more skincare/product sales in your practice."
        mb="24px"
      />
      <FormRow>
        <BoxedRadioGroup
          label={FIELDS.privateLabelDiscount.label}
          control={control}
          register={register('privateLabelDiscount')}
          row
          options={LABEL_DISCOUNT_OPTIONS.map((option) => ({
            value: option,
            label: option,
          }))}
          allowDeselect
        />
      </FormRow>
      <FormRow mb="7px">
        <BoxedRadioGroup
          label={FIELDS.brandLabelDiscount.label}
          control={control}
          register={register('brandLabelDiscount')}
          row
          options={LABEL_DISCOUNT_OPTIONS.map((option) => ({
            value: option,
            label: option,
          }))}
          allowDeselect
        />
      </FormRow>
      <Box mb="56px">
        <FieldHelperText>On average, JOYA practices are offering 20%-30%.</FieldHelperText>
      </Box>

      {/* EMR/PMR/Scheduling SECTION */}
      <Typography variant="h4" mb="24px" maxWidth="400px">
        {FIELDS['selectedEmrPmrSchedulingSystems'].label}
      </Typography>
      <ExpandableCheckbox
        control={control}
        register={register('selectedEmrPmrSchedulingSystems.MODERNIZING_MEDICINE')}
        label="Modernizing Medicine"
      />
      <ExpandableCheckbox
        control={control}
        register={register('selectedEmrPmrSchedulingSystems.NEXTECH')}
        label="Nextech"
      />
      <ExpandableCheckbox
        control={control}
        register={register('selectedEmrPmrSchedulingSystems.EZDERM')}
        label="Ezderm"
      />
      <ExpandableCheckbox
        control={control}
        register={register('selectedEmrPmrSchedulingSystems.AESTHETIC_RECORDS')}
        label="Aesthetic records"
      />
      <ExpandableCheckbox
        control={control}
        register={register('selectedEmrPmrSchedulingSystems.ADVANCED_MD')}
        label="AdvancedMD"
      />
      <ExpandableCheckbox
        control={control}
        register={register('selectedEmrPmrSchedulingSystems.OTHER.isChecked')}
        label="Other"
      >
        <TextField
          control={control}
          register={register('selectedEmrPmrSchedulingSystems.OTHER.specifyText')}
          placeholder="Specify here"
          fullWidth
          error={!!errors.selectedEmrPmrSchedulingSystems?.OTHER?.specifyText}
          helperText={errors.selectedEmrPmrSchedulingSystems?.OTHER?.specifyText?.message}
        />
      </ExpandableCheckbox>
      {formErrMessage && <FormHelperText error>{formErrMessage}</FormHelperText>}
      {showWarningDialog && (
        <InfoModal
          onContinue={() => handleSubmit(onSubmit)()}
          onClose={() => setShowWarningDialog(false)}
          title="The combined total for facial/peel + sunscreen exceeds $40."
          text="Please note that the cost refers to the cost of goods to your practice, not the patient. You can still continue with this value, but we will only reimburse you up to $40."
        />
      )}
    </StepContainer>
  );
};

LetsLearnAboutYourPractice.defaultProps = {
  onBack: () => {},
  onContinue: async () => {},
};

const TooltipText = (props: BoxProps & { text: string; tooltipText: string }) => {
  const theme = useTheme();
  const { text, tooltipText, ...boxProps } = props;
  return (
    <Box {...boxProps} display="flex" alignItems="center">
      <Tooltip title={tooltipText} placement="top">
        <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
          <InfoIcon sx={{ mr: 0.5, color: theme.palette.primary.main }} />
          <Typography variant="body2Medium" sx={{ color: '#78756E', textDecoration: 'underline' }}>
            {text}
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  );
};

const ThreeFieldsRow = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<{ isMobile: boolean }>`
  display: flex;
  flex: 1;
  align-items: flex-end;

  margin-bottom: 12px;

  > :nth-child(1),
  > :nth-child(2) {
    margin-right: 12px;
  }

  > :nth-child(3) {
    margin-right: 28px;
  }

  > :not(:nth-child(2)):not(:last-child) {
    flex: 1;
    height: 100%;
    justify-content: flex-end;
  }

  & svg {
    margin-bottom: 12px;
  }

  > :last-child {
    display: flex;
  }

  ${({ isMobile }) =>
    isMobile &&
    `
    flex-direction: column;
    gap: 16px;
  `}
`;

const ShortFieldContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isTablet',
})<{ isTablet: boolean }>`
  width: 160px;

  display: flex;
  ${({ isTablet }) =>
    isTablet &&
    `
    width: 100%;
  `}
`;

const FieldDeleteButton = styled(IconButton)`
  align-self: center;
  height: 100%;
  justify-content: center;
  margin-left: 8px;
`;

// interface FieldHelperTextProps extends TypographyProps {
//   error?: boolean;
// }

const FieldHelperText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isError',
})<{ isError?: boolean }>`
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #78756e;
  ${({ isError }) =>
    isError &&
    `
    color: #C1281D;
  `}
`;

FieldHelperText.defaultProps = {
  display: 'block',
  color: 'text.secondary',
};
