import { SvgIcon, SvgIconProps } from '@mui/material';

function AddIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2496 5.75009C13.2496 5.33582 12.9138 5 12.4997 5C12.0855 5 11.7497 5.33582 11.7497 5.75009V10.751H6.74991C6.33575 10.751 6 11.0869 6 11.5011C6 11.9154 6.33575 12.2512 6.74991 12.2512H11.7497V17.2522C11.7497 17.6664 12.0855 18.0023 12.4997 18.0023C12.9138 18.0023 13.2496 17.6664 13.2496 17.2522V12.2512H18.2494C18.6636 12.2512 18.9993 11.9154 18.9993 11.5011C18.9993 11.0869 18.6636 10.751 18.2494 10.751H13.2496V5.75009Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export { AddIcon };
