import { StepContainer } from '../../StepContainer';
import { ExpandableCheckbox } from '@/components/UIKit/Inputs/Checkbox/ExpandableCheckbox';
import { PhoneTextField } from '@/components/UIKit/Inputs/TextField/PhoneTextField';
import { URLTextField } from '@/components/UIKit/Inputs/TextField/URLTextField';
import { SurveySectionFields } from '@/scenes/Onboarding/types';
import { cleanPhoneNum, formatPhoneNum } from '@/utils/formatting';
import { yupPhoneNumber, yupURLWithoutHttps } from '@/utils/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormHelperText } from '@mui/material';
import { useState } from 'react';
import { UseFormProps, useForm } from 'react-hook-form';
import * as yup from 'yup';

type Props = {
  onBack: () => void;
  onContinue: (data: FormInputs) => Promise<void>;
  defaultValues?: Partial<FormInputs>;
};

// FIELDS
// IMPORTANT: treat this as a contract, this determines which values gets passed up to the backend
const FIELDS: SurveySectionFields<FormInputs> = {
  isOnlinePortalChecked: {
    label: 'Online portal',
    type: 'boolean',
    example: true,
  },
  onlinePortalUrl: {
    label: 'Online portal',
    type: 'string',
    example: 'https://www.example.com',
  },
  isPhoneSchedulingChecked: {
    label: 'Phone scheduling',
    type: 'boolean',
    example: true,
  },
  phoneNumForScheduling: {
    label: '',
    type: 'string',
    example: '123-456-7890',
  },
};

export { FIELDS as SchedulingMethodFields };

export const SchedulingMethod = (props: Props) => {
  const { control, handleSubmit, register } = useSchedulingMethodForm({
    defaultValues: {
      isOnlinePortalChecked: false,
      isPhoneSchedulingChecked: false,
      ...props.defaultValues,
      phoneNumForScheduling: formatPhoneNum(props.defaultValues?.phoneNumForScheduling || ''),
    },
  });
  const [formErrMessage, setFormErrMessage] = useState<string>();

  const onSubmit = async (data: FormInputs) => {
    if (!data.isOnlinePortalChecked && !data.isPhoneSchedulingChecked) {
      setFormErrMessage('Please select at least one scheduling method');
      return;
    }

    setFormErrMessage(undefined);
    await props.onContinue({
      ...data,
      ...(data.phoneNumForScheduling && {
        phoneNumForScheduling: cleanPhoneNum(data.phoneNumForScheduling),
      }),
    });
  };

  return (
    <StepContainer
      heading="Which scheduling method do you prefer for JOYA patients?"
      description="JOYA members will follow your booking process."
      onBack={props.onBack}
      onContinue={() => handleSubmit(onSubmit)()}
    >
      <ExpandableCheckbox
        label={FIELDS.isOnlinePortalChecked.label}
        control={control}
        register={register('isOnlinePortalChecked')}
      >
        <URLTextField control={control} register={register('onlinePortalUrl')} fullWidth />
      </ExpandableCheckbox>
      <ExpandableCheckbox
        label={FIELDS.isPhoneSchedulingChecked.label}
        control={control}
        register={register('isPhoneSchedulingChecked')}
      >
        <PhoneTextField control={control} register={register('phoneNumForScheduling')} fullWidth />
      </ExpandableCheckbox>
      {formErrMessage && <FormHelperText error>{formErrMessage}</FormHelperText>}
    </StepContainer>
  );
};
SchedulingMethod.defaultProps = {
  onBack: () => {},
  onContinue: async () => {},
};

// form fields
// IMPORTANT: treat this as a contract, this is how values are read from the backend (see defaultValues prop)
type FormInputs = {
  isOnlinePortalChecked: boolean;
  onlinePortalUrl?: string;
  isPhoneSchedulingChecked: boolean;
  phoneNumForScheduling?: string;
};

const useSchedulingMethodForm = (props?: UseFormProps<FormInputs>) => {
  let defaultValues = props?.defaultValues;
  const validationSchema: yup.SchemaOf<FormInputs> = yup.object({
    isOnlinePortalChecked: yup.boolean().required(),
    onlinePortalUrl: yup.string().when('isOnlinePortalChecked', {
      is: true,
      then: yupURLWithoutHttps().required(),
    }),
    isPhoneSchedulingChecked: yup.boolean().required(),
    phoneNumForScheduling: yup.string().when('isPhoneSchedulingChecked', {
      is: true,
      then: yupPhoneNumber().required(),
    }),
  });

  return useForm<FormInputs>({
    ...props,
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });
};
