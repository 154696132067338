import { CloseIcon } from '../../Icons';
import { Day } from './components/Day';
import { useDateStates } from './hooks/useDateStates';
import { useMonthChanger } from './hooks/useMonthChanger';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  TextFieldProps,
  styled,
} from '@mui/material';
import {
  CalendarIcon,
  DateCalendar,
  DateCalendarProps,
  DateField,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect, useRef, useState } from 'react';
import { Control, useController } from 'react-hook-form';

type Props = {
  control: Control<any>;
  name: string;
  label?: string;
  value?: {
    startDate: Date;
    endDate: Date;
  };
  dualCalendars?: boolean;
  dateCalendarProps?: Partial<DateCalendarProps<Date>>;
  textFieldProps?: Partial<TextFieldProps>;
  className?: string;
  format?: string;
};

function DateRangeSelect(props: Props) {
  const {
    className,
    control,
    name,
    label,
    value,
    dualCalendars,
    dateCalendarProps = {},
    textFieldProps = {},
    format = 'P',
  } = props;

  const { field } = useController({ control, name, defaultValue: value });

  const { cal1Val, cal2Val, handleMonthChangeCal1, handleMonthChangeCal2 } = useMonthChanger({
    dualCalendars,
    value,
  });

  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    selectedStartDate,
    selectedEndDate,
    setDateRange,
    handlePickedDate,
    clearDates,
    selectedRangeDisplay,
  } = useDateStates({
    value,
    dateFormat: format,
  });

  const [open, setOpen] = useState(false);
  const toggleDatePopover = () => setOpen((prev) => !prev);

  const inputRef = useRef<HTMLInputElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const parsedDateCalendarProps: Partial<DateCalendarProps<Date>> = {
    disableFuture: false,
    onChange: handlePickedDate,
    views: ['year', 'month', 'day'],
    slots: {
      day: Day,
      leftArrowIcon: ChevronLeft,
      rightArrowIcon: ChevronRight,
    },
    slotProps: {
      day: {
        startDate: startDate,
        endDate: endDate,
      } as any,
      previousIconButton: {
        sx: { fontSize: '24px' },
      },
      nextIconButton: {
        sx: { fontSize: '24px' },
      },
    },
    ...dateCalendarProps,
  };

  const popoverDateFieldProps = {
    InputProps: { sx: { fontSize: '14px' } },
    format: format,
    sx: {
      'width': '128px',
      '.MuiInputBase-root': { paddingRight: '0px' },
      'input': { borderRight: '0px' },
    },
    size: 'small' as const,
    disableFuture: parsedDateCalendarProps.disableFuture,
  };

  useEffect(() => {
    field.onChange({
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStartDate, selectedEndDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangeTextField
        className={className}
        label={label}
        value={selectedRangeDisplay}
        onClick={toggleDatePopover}
        {...textFieldProps}
        InputProps={{
          ref: inputRef,
          readOnly: true,
          endAdornment: (
            <>
              {!!selectedRangeDisplay && (
                <IconButton
                  color="secondary"
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    clearDates();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
              <InputAdornment position="end">
                <CalendarIcon />
              </InputAdornment>
            </>
          ),
          sx: { fontSize: '16px' },
          ...textFieldProps.InputProps,
        }}
      />
      <Popover
        open={open}
        anchorEl={inputRef.current}
        onClose={toggleDatePopover}
        anchorOrigin={{
          vertical: 65,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <PopoverContent ref={popoverRef} dualCalendars={dualCalendars}>
          <DateCalendar
            {...parsedDateCalendarProps}
            value={cal1Val}
            onMonthChange={(month: Date) => {
              setStartDate(startDate);
              setEndDate(endDate);
              handleMonthChangeCal1(month);
            }}
            onYearChange={(year: Date) => {
              setStartDate(startDate);
              setEndDate(endDate);
              handleMonthChangeCal1(year);
            }}
          />
          {dualCalendars && (
            <DateCalendar
              {...parsedDateCalendarProps}
              value={cal2Val}
              onChange={handlePickedDate}
              onMonthChange={(month: Date) => {
                setStartDate(startDate);
                setEndDate(endDate);
                handleMonthChangeCal2(month);
              }}
              onYearChange={(year: Date) => {
                setStartDate(startDate);
                setEndDate(endDate);
                handleMonthChangeCal2(year);
              }}
            />
          )}
          {dualCalendars && <Divider sx={{ gridArea: 'divider' }} />}
          <Footer hasDateFields={dualCalendars}>
            {dualCalendars && (
              <>
                <DateField value={startDate} onChange={setStartDate} {...popoverDateFieldProps} />
                <Dash>&mdash;</Dash>
                <DateField value={endDate} onChange={setEndDate} {...popoverDateFieldProps} />
                <div />
              </>
            )}
            <Button variant="text" onClick={toggleDatePopover}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setDateRange();
                toggleDatePopover();
              }}
              sx={{ minWidth: '120px' }}
            >
              Apply
            </Button>
          </Footer>
        </PopoverContent>
      </Popover>
    </LocalizationProvider>
  );
}

const DateRangeTextField = styled(TextField)`
  min-width: 280px;

  .MuiInputBase-input {
    border-right: 0;
    padding-right: 6px;
  }

  .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
    padding-left: 0;
  }
`;

const PopoverContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'dualCalendars',
})<{ dualCalendars?: boolean }>`
  display: grid;
  ${(props) =>
    props.dualCalendars
      ? `
    grid-template:
      'cal1 cal2'
      'divider divider'
      'footer footer'
      / 1fr 1fr;
  `
      : `
  grid-template:
      'cal1'
      'footer'
      / 1fr;
  `}
  overflow: auto;
  position: relative;
  z-index: 0;

  .MuiDateCalendar-root + .MuiDateCalendar-root {
    border-left: 1px solid ${(props) => props.theme.palette.grey[200]};
  }

  .MuiPickersDay-root {
    &.Mui-selected {
      background: #fff;
      color: ${(props) => props.theme.palette.text.primary};

      &.Mui-disabled {
        color: ${(props) => props.theme.palette.text.disabled};
      }

      &:hover:not(.is-first-day, .is-last-day) {
        background: #4046fc0a;
      }
    }

    &.MuiPickersDay-today {
      background: #0b4fb30d;
      border-color: transparent;
      color: ${(props) => props.theme.palette.primary.main};

      &.is-first-day,
      &.is-last-day {
        border: 0;
      }
    }

    &.is-last-day,
    &.MuiPickersDay-hiddenDaySpacingFiller {
      padding: 0;
      margin: 0 2px;
    }
  }
`;

const Footer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hasDateFields',
})<{ hasDateFields?: boolean }>`
  padding: 16px;
  grid-area: footer;
  display: grid;
  gap: 12px;
  align-items: center;
  ${(props) =>
    props.hasDateFields
      ? `
    grid-template:
      'startDate dash endDate spacer cancel apply'
      / auto auto auto 1fr auto auto;
  `
      : `
    grid-template:
      'cancel apply'
      / auto auto;
    justify-content: space-between;
  `}
`;

const Dash = styled('div')`
  color: ${(props) => props.theme.palette.grey[500]};
  font-weight: 500;
  width: 8px;
  overflow: hidden;
  align-self: end;
  margin-bottom: 11px;
`;

export { DateRangeSelect };
