import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material';

export const GrayButton = styled(LoadingButton)`
  background: #f6f5f4;
  color: ${(props) => props.theme.palette.text.primary};
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.2;
  text-align: center;
  opacity: 0.8;

  .MuiLoadingButton-loadingIndicator {
    color: #888;
  }

  :before {
    background: #eceae6;
  }

  :disabled {
    background: #f6f5f4;
    :not(.MuiLoadingButton-loading) {
      color: ${(props) => props.theme.palette.text.secondary};
    }
  }

  :hover {
    opacity: 1;
    background: #f6f5f4;
    box-shadow: none;
  }
`;
GrayButton.defaultProps = {
  variant: 'contained',
};
