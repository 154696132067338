import { GlobalContext } from '../GlobalContext';
import { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';

const AuthenticatedRoute = () => {
  const { loggedInProvider } = useContext(GlobalContext);
  const location = useLocation();

  // if they are not logged in move to login page
  if (!loggedInProvider || loggedInProvider.loginNeeded) {
    return (
      <Navigate
        to={{
          pathname: '/login',
        }}
        state={{
          from: location,
          requiresAuth: true,
        }}
        replace
      />
    );
  }

  const isDoneWithSurvey = loggedInProvider.survey?.state === 'done';

  // if already done with survey
  // - cant go back to /onboarding (ie: should be /onboarding/providers-in-your-practice, not just /onboarding)
  // - cant go back to /add-your-team, they can access same functionality of "add your team" page in dashboard ("manage access modal")
  if (isDoneWithSurvey) {
    if (location.pathname === '/onboarding' || location.pathname === '/add-your-team') {
      return <Navigate to="/dashboard" replace />;
    }
  }

  // if they have not started their survey (or we have created it right from here), they can only access /onboarding or /add-your-team (the default redirect)
  if (
    !loggedInProvider.survey ||
    !loggedInProvider.survey?.questions ||
    loggedInProvider.survey?.questions?.length === 0
  ) {
    if (location.pathname === '/add-your-team' || location.pathname === '/onboarding') {
      return <Outlet />;
    }
    return <Navigate to="/add-your-team" replace />;
  }

  // if they have not completed their survey, they can only access /onboarding
  // - this also ensures they cant jump to a specific survey section (ie: if route was "/onboarding/providers-in-your-practice" it would send them to "/onboarding")
  if (!isDoneWithSurvey) {
    if (location.pathname === '/onboarding') {
      return <Outlet />;
    }
    return <Navigate to="/onboarding" replace />;
  }

  return <Outlet />;
};

export { AuthenticatedRoute };
