import { SvgIcon, SvgIconProps } from '@mui/material';

function ChevronDownIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5303 14.7762C12.2374 15.0691 11.7626 15.0691 11.4697 14.7762L6.46967 9.77618C6.17678 9.48329 6.17678 9.00841 6.46967 8.71552C6.76256 8.42263 7.23744 8.42263 7.53033 8.71552L12 13.1852L16.4697 8.71552C16.7626 8.42263 17.2374 8.42263 17.5303 8.71552C17.8232 9.00841 17.8232 9.48329 17.5303 9.77618L12.5303 14.7762Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export { ChevronDownIcon };
