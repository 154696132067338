import { hasClaimedSkinCancerCheck } from '../../utils/misc';
import { BenefitsCard } from './components/BenefitsCard';
import { JoyaWalletCard } from './components/JoyaWalletCard';
import { InfoModal } from '@/components/Modals/InfoModal';
import { GrayButton } from '@/components/UIKit/Button/styled';
import { AddIcon, ArrowLeftIcon } from '@/components/UIKit/Icons';
import useBreakpointCheck from '@/hooks/useBreakpointCheck';
import { useDownloadManager } from '@/hooks/useDownloadManager';
import { Member } from '@/types/apiContract/member';
import { Visit } from '@/types/apiContract/visit';
import { Box, Button, Typography, styled } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  member: Member;
  visits: Visit[];
  pickedDate: Date;
  onTodaysVisitClick: () => void;
};
export const UserProfile = (props: Props) => {
  const { member, visits, pickedDate } = props;
  const navigate = useNavigate();
  const { download, loading } = useDownloadManager();
  const isTablet = useBreakpointCheck('tablet');
  const isMobile = useBreakpointCheck('sm');
  const onDownloadClick = async () => {
    await download({
      fileName: `survey-${member.joyaId}`,
      url: member.survey,
    });
  };

  const [showWarningDialog, setShowWarningDialog] = useState<boolean>(false);

  const visitIds = visits.map((visit) => visit.id);
  const claimedVisitIdsWithDates = member.consumerClaimHistory.benefitsInfo
    .filter((benefit) => benefit.claimed)
    .map((benefit) => ({
      claimedByVisitId: benefit.claimedByVisitId,
      claimedOn: benefit.claimedOn,
    }));
  // check if any of the claimed benefits visit ids is not in visitIds which means that
  // claim was made by visit in another provider
  const wasProviderChangedForTheMember = claimedVisitIdsWithDates.some(
    (claimedVisit) => !visitIds.includes(claimedVisit.claimedByVisitId),
  );

  const isCurrentProviderHasVisitAfterPreviousProvider = visits.some((visit) =>
    claimedVisitIdsWithDates.some(
      (claimedVisit) =>
        !!claimedVisit.claimedOn && new Date(visit.createdAt) > new Date(claimedVisit.claimedOn),
    ),
  );

  // show popup if there is visit with claimed benefits from previous provider
  // and current provider does not have visits after previous provider
  if (
    !showWarningDialog &&
    wasProviderChangedForTheMember &&
    !isCurrentProviderHasVisitAfterPreviousProvider
  ) {
    setShowWarningDialog(true);
  }

  let fullName = member.firstName;
  if (member.middleName) fullName += ` ${member.middleName}`;
  fullName += ` ${member.lastName}`;

  return (
    <Body>
      <Box mt="32px" display="flex" justifyContent="space-between" mb="24px">
        <Button onClick={() => navigate('/dashboard')} startIcon={<ArrowLeftIcon />}>
          Back
        </Button>
        <Typography display="block" color="text.secondary" textAlign="end">
          {member.joyaId && (
            <Typography display="block" variant="body3">
              Joya ID {member.joyaId}
            </Typography>
          )}
          <Typography variant="body3">
            Member since {format(parseISO(member.createdAt), 'MMMM d')}
          </Typography>
        </Typography>
      </Box>
      <NameText display="block" mb={isMobile ? '30px' : '12px'}>
        {fullName}
      </NameText>

      <Box
        mb="30px"
        gap="20px"
        display="flex"
        alignItems={isMobile ? 'flex-start' : 'center'}
        flexDirection={isMobile ? 'column' : 'row'}
      >
        {isTablet && (
          <Button
            variant="contained"
            color="primary"
            onClick={props.onTodaysVisitClick}
            startIcon={<AddIcon />}
          >
            Add new visit
          </Button>
        )}
        <GrayButton onClick={onDownloadClick} loading={loading}>
          Download skin profile
        </GrayButton>
      </Box>

      <Box mb="16px">
        <JoyaWalletCard
          hasClaimedSkinCheck={hasClaimedSkinCancerCheck(member)}
          claimHistory={member.consumerClaimHistory}
          pickedDate={pickedDate}
        />
      </Box>
      <Box>
        <BenefitsCard benefitsInfo={member.consumerClaimHistory.benefitsInfo} />
      </Box>
      {showWarningDialog && (
        <InfoModal
          onContinue={() => Promise.resolve()}
          onClose={() => setShowWarningDialog(false)}
          title="This member has used some JOYA benefits in previous visits with another provider"
          text="This member requested to switch providers. Available benefits are updated based on what they
          utilized with their previous provider; however, their previous visits will not be visible to you on the
          visits history page."
          showContinueButton={false}
          closeButtonText={'Close'}
        />
      )}
    </Body>
  );
};

const Body = styled(Box)`
  max-width: 728px;

  ${(props) => props.theme.breakpoints.down('tablet')} {
    max-width: 100%;
    width: 100%;
  }
`;

const NameText = styled(Typography)`
  font-family: The Seasons;
  font-size: 48px;
  font-weight: 400;
  line-height: 53px;
  letter-spacing: 0px;
`;
