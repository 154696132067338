import { AddIcon } from '@/components/UIKit/Icons';
import { Button, ButtonProps, styled } from '@mui/material';

export const AddMoreButton = (props: ButtonProps & { btnText?: string }) => {
  const { btnText = 'Add', ...rest } = props;
  return (
    <Button variant="text" sx={{ gap: 0 }} startIcon={<AddIcon />} {...rest}>
      {btnText}
    </Button>
  );
};

export const CardButton = styled(Button)(({ theme }) => ({
  'padding': '24px',
  'display': 'flex',
  'width': '100%',
  'background': '#fff',
  'justifyContent': 'center',
  'borderRadius': '12px',
  'boxShadow': '0px 2px 16px rgba(181, 176, 168, 0.1)',
  'transitionDuration': '0.2s',
  'textTransform': 'none',
  'color': theme.palette.text.primary,
  '&:hover': {
    boxShadow: '0px 2px 16px rgba(181, 176, 168, 0.25)',
    background: '#fff',
    color: theme.palette.text.secondary,
  },
}));
