import {
  hasLowerCase,
  hasNumber,
  hasSpecialCharacter,
  hasUpperCase,
  isPhoneNum,
  isValidUrl,
  isGoogleMapsUrl,
} from './validation';
import * as EmailValidator from 'email-validator';
import * as yup from 'yup';

// a "string" number - when u want to display something with 2 decimals (ie: 5.00)
export const yupNumber = () => {
  return yup.string().matches(/^\d*(\.\d+)?$/, 'Must be a number');
};

export const yupTrueNumber = () => {
  return yup
    .number()
    .transform((value, origValue) => (origValue === '' ? null : value)) // fix issue where undefined shows "NaN" error instead of "required"
    .typeError('Must be a number')
    .nullable();
};

export const yupPhoneNumber = () => {
  return yup.string().test('is-phonenumber', 'Invalid phone number', (val) => {
    if (!val) return true; // allow empty value
    return isPhoneNum(val);
  });
};

export const yupEmail = () => {
  return yup.string().test('is-email', 'Invalid email', (val) => {
    if (!val) return true; // allow empty value
    return EmailValidator.validate(val);
  });
};

export const yupURLWithoutHttps = () => {
  return yup.string().test('is-url-no-https', 'Invalid URL', (val) => {
    if (!val) return true; // allow empty value
    const urlWithHttps = `https://${val}`;
    return isValidUrl(urlWithHttps);
  });
};

export const yupPassword = () => {
  return yup
    .string()
    .required()
    .min(6, 'Must be at least 6 characters')
    .test('has-lowercase', 'Must have lowercase character', (val) => hasLowerCase(val))
    .test('has-uppercase', 'Must have uppercase character', (val) => hasUpperCase(val))
    .test('has-one-lowercase', 'Must have number', (val) => hasNumber(val))
    .test('has-one-lowercase', 'Must have special character', (val) => hasSpecialCharacter(val));
};

export const yupGoogleMapsUrl = () => {
  return yup.string().test('is-google-maps-url', 'Invalid Google Maps URL', (val) => {
    return isGoogleMapsUrl(val);
  });
};
