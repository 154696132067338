// Discount Offers Options
const LABEL_DISCOUNT_OPTIONS_CONST = ['15%', '20%', '25%', '30%'] as const;
export const LABEL_DISCOUNT_OPTIONS = [...LABEL_DISCOUNT_OPTIONS_CONST];
export type LabelDiscountType = (typeof LABEL_DISCOUNT_OPTIONS)[number];

export const DEFAULT_TYPE_FIELD = {
  facialPeelType: '',
  sunscreenType: '',
  cost: '0.00',
};
