import { Slide } from '@mui/material';
import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles/createTheme';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef } from 'react';

const DIALOG_PADDING = '32px';
export const feedback = (theme: Theme): Components => ({
  MuiDialog: {
    defaultProps: {
      PaperProps: {
        elevation: 24,
      },
      TransitionComponent: forwardRef(function Transition(
        props: TransitionProps & {
          children: React.ReactElement<any, any>;
        },
        ref: React.Ref<unknown>,
      ) {
        return <Slide direction="up" ref={ref} {...props} />;
      }),
    },
    styleOverrides: {
      paper: {
        // allow <form> to be <Dialog />'s direct child while maintaining paper scroll for <DialogContent>
        '& > form': {
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        },
        'padding': '0px',
        'border': 'none',
        'borderRadius': '8px',
      },
      paperFullScreen: {
        borderRadius: '0px',
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        '> *': {
          flex: 1,
        },
        'borderTop': `1px solid ${theme.palette.grey[200]}`,
        'padding': DIALOG_PADDING,
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        position: 'relative',
        padding: DIALOG_PADDING,
        paddingTop: '40px',
      },
    },
  },
  MuiDialogTitle: {
    defaultProps: {
      variant: 'h3',
    },
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        padding: DIALOG_PADDING,
        paddingTop: '40px',
      },
    },
  },
});
