import { Components, Theme } from '@mui/material';

export const utils = (theme: Theme): Components<Theme> => ({
  MuiCssBaseline: {
    styleOverrides: {
      ':root': {
        '--app-full-svh': '100vh',
      },
      '@supports (height: 1svh)': {
        ':root': {
          '--app-full-svh': '100svh',
        },
      },
    },
  },
});
