export function checkIfImageExists(imageUrl: string): Promise<{ doesExist: boolean }> {
  return new Promise((resolve) => {
    let imageData = new Image();

    imageData.onload = () => {
      resolve({ doesExist: true });
    };

    imageData.onerror = () => {
      resolve({ doesExist: false });
    };

    imageData.src = imageUrl;
  });
}
