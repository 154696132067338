import { PracticePromotionModalView, PracticePromotionsFormInputs } from './View';
import { PromotionTimingType, PromotionType } from './constants';
import { GlobalContext } from '@/components/GlobalContext';
import {
  CreatePromotionRequest,
  Promotion,
  UpdatePromotionRequest,
  UploadTermsOrExclusionsRequest,
} from '@/types/apiContract/promotion';
import { handleApiError } from '@/utils/feedback';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useContext, useState } from 'react';

type Props = {
  currentPromotion?: Promotion;
  onClose: () => void;
  onComplete: () => Promise<void>;
};

export const PracticePromotionModalContainer = (props: Props) => {
  const { loggedInProvider } = useContext(GlobalContext);
  const [uploadProgress, setUploadProgress] = useState<number>();

  // create new or edit current (if currentProvider exists)
  const createPracticePromotionMutation = useMutation({
    onError: handleApiError,
    mutationFn: (values: PracticePromotionsFormInputs) => {
      const promotion = formValsToPromotion(values);

      // create new
      if (!props.currentPromotion) {
        const createPromotionRequest: CreatePromotionRequest = promotion;
        return axios.post<Promotion>(
          `practices/${loggedInProvider.id}/promotions`,
          createPromotionRequest,
        );
      }

      // edit current
      const updatePromotionRequest: UpdatePromotionRequest = {
        id: props.currentPromotion.id,
        ...promotion,
      };
      return axios.put<Promotion>(
        `/practices/${loggedInProvider.id}/promotions/${props.currentPromotion.id}`,
        updatePromotionRequest,
      );
    },
  });

  const uploadTermsOrExclusionsMutation = useMutation({
    onError: handleApiError,
    mutationFn: async (params: { promotionId: string; termsOrExclusionsFile: File }) => {
      const { promotionId, termsOrExclusionsFile } = params;
      const formData = new FormData();
      formData.append(
        'terms-or-exclusions' satisfies keyof UploadTermsOrExclusionsRequest,
        termsOrExclusionsFile,
      );
      return axios.post(
        `practices/${loggedInProvider.id}/promotions/${promotionId}/terms-or-exclusions`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            if (!progressEvent.total) return;
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          },
        },
      );
    },
  });

  const deleteTermsOrExclusionsMutation = useMutation({
    onError: handleApiError,
    mutationFn: async (params: { promotionId: string; termsOrExclusionsFileId: string }) => {
      const { promotionId, termsOrExclusionsFileId } = params;
      return axios.delete(
        `practices/${loggedInProvider.id}/promotions/${promotionId}/terms-or-exclusions/${termsOrExclusionsFileId}`,
      );
    },
  });

  const updateTermsOrExclusionsMutation = useMutation({
    onError: handleApiError,
    mutationFn: async (params: {
      promotionId: string;
      termsOrExclusionsFileId: string;
      termsOrExclusionsFile: File;
    }) => {
      const { promotionId, termsOrExclusionsFileId, termsOrExclusionsFile } = params;
      const formData = new FormData();
      formData.append(
        'terms-or-exclusions' satisfies keyof UploadTermsOrExclusionsRequest,
        termsOrExclusionsFile,
      );
      return axios.put(
        `practices/${loggedInProvider.id}/promotions/${promotionId}/terms-or-exclusions/${termsOrExclusionsFileId}`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            if (!progressEvent.total) return;
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(percentCompleted);
          },
        },
      );
    },
  });

  const onSubmit = async (values: PracticePromotionsFormInputs) => {
    const { data } = await createPracticePromotionMutation.mutateAsync(values);

    // TODO: Refactor to add possibility of multiple upload
    if (
      values.termsOrExclusionsFiles &&
      (!props.currentPromotion?.termsAndExclusionsFileUrls ||
        props.currentPromotion?.termsAndExclusionsFileUrls.length === 0)
    ) {
      // Case 1. Upload new terms or exclusions if there are no existing terms or exclusions
      // doing try catch since still want to give feedback that the promotion was created even if file upload failed
      await handleMutation(
        uploadTermsOrExclusionsMutation.mutateAsync,
        {
          promotionId: data.id,
          termsOrExclusionsFile: values.termsOrExclusionsFiles,
        },
        props.onComplete,
        props.onClose,
      );
      return;
    } else if (props.currentPromotion?.termsAndExclusionsFileUrls) {
      // Case 2. Update or Delete existing terms or exclusions
      const filesForDeletion = props.currentPromotion?.termsAndExclusionsFileUrls.filter(
        (fileInfo) => fileInfo.status === 'DELETED',
      );
      const filesForUpdate = props.currentPromotion?.termsAndExclusionsFileUrls.filter(
        (fileInfo) => fileInfo.status === 'UPDATED',
      );

      const deletionPromise = filesForDeletion?.map((fileInfo) => {
        console.log('Delete file: ', fileInfo.id);
        return handleMutation(
          deleteTermsOrExclusionsMutation.mutateAsync,
          {
            promotionId: data.id,
            termsOrExclusionsFileId: fileInfo.id,
          },
          () => Promise.resolve(),
          () => {},
        );
      });
      const updatePromise = filesForUpdate?.map((fileInfo) => {
        console.log('Update file: ', fileInfo.id);
        return handleMutation(
          updateTermsOrExclusionsMutation.mutateAsync,
          {
            promotionId: data.id,
            termsOrExclusionsFileId: fileInfo.id,
            termsOrExclusionsFile: values.termsOrExclusionsFiles,
          },
          () => Promise.resolve(),
          () => {},
        );
      });

      await Promise.all([...deletionPromise, ...updatePromise]);
    }

    await props.onComplete();
    props.onClose();
  };

  return (
    <PracticePromotionModalView
      fromDashboard={loggedInProvider.fromDashboard}
      defaultValues={promotionToFormVals(props.currentPromotion)}
      defaultTermsOrExclusionsInfos={props.currentPromotion?.termsAndExclusionsFileUrls} // just displaying the url into img (not actually downloading the file)
      termsOrExclusionsUploadProgress={uploadProgress}
      onClose={props.onClose}
      onSubmit={onSubmit}
    />
  );
};

const handleMutation = async (
  mutationFunction: (params: any) => Promise<any>,
  params: any,
  onComplete: () => Promise<void>,
  onClose: () => void,
) => {
  try {
    await mutationFunction(params);
  } catch (e: any) {
    throw Error(e);
  } finally {
    await onComplete();
    onClose();
  }
};

const formValsToPromotion = (values: PracticePromotionsFormInputs) => {
  return {
    type: values.promotionType,
    product: values.product,
    description: values.promotionDescription,
    dateType: values.promotionTiming,
    startDate: values.promotionDateRange?.startDate,
    endDate: values.promotionDateRange?.endDate,
  };
};

const promotionToFormVals = (
  promotion?: Promotion,
): Partial<PracticePromotionsFormInputs> | undefined => {
  if (!promotion) return undefined;
  return {
    promotionType: promotion.type as PromotionType,
    product: promotion.product,
    promotionDescription: promotion.description,
    promotionTiming: promotion.dateType as PromotionTimingType,
    promotionDateRange: {
      startDate: promotion.startDate,
      endDate: promotion.endDate,
    },
  };
};
