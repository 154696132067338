import joyaLoadingSpinnerAnimationJSON from '@/assets/misc/animations/joya-loading-spinner.json';
import { Box, BoxProps } from '@mui/material';
import Lottie from 'react-lottie-player';

type Props = {
  sx?: BoxProps['sx'];
};
export const LoadingSpinner = (props: Props) => {
  return (
    <Box
      sx={{
        'svg path': { fill: 'currentColor' },
        ...props.sx,
      }}
    >
      <Lottie
        animationData={joyaLoadingSpinnerAnimationJSON}
        loop
        play
        style={{ height: '100%', display: 'flex' }}
      />
    </Box>
  );
};
