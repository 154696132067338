import { PASSWORD_GUIDELINES } from './constants';
import { FormInputs, Props } from './types';
import { FormRow } from '@/components/UIKit/Form/styled';
import { CheckCircleFilledIcon, CheckCircleOutlineIcon } from '@/components/UIKit/Icons';
import { TextField } from '@/components/UIKit/Inputs/TextField/TextField';
import { DialogCloseBtn } from '@/components/UIKit/Modal/misc';
import useOpenable from '@/hooks/useOpenable';
import { yupPassword } from '@/utils/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  ButtonBase,
  DialogActions,
  DialogContent,
  Dialog as MuiDialog,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { UseFormProps, useForm } from 'react-hook-form';
import * as yup from 'yup';

export const ChangePasswordModalView = (props: Props) => {
  const theme = useTheme();
  const { control, register, handleSubmit, watch } = useSetPasswordForm({});
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState({
    old: false,
    new: false,
    confirmNew: false,
  });
  const { isOpen, onClose } = useOpenable({
    shouldStartOpen: true,
    isOnCloseDisabled: isLoading,
  });

  const onSubmit = async (data: FormInputs) => {
    try {
      setIsLoading(true);
      await props.onSubmit(data);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordHideShow = (name: 'old' | 'new' | 'confirmNew') => {
    setShowPassword({
      ...showPassword,
      [name]: !showPassword[name],
    });
  };

  const newPassword = watch('newPassword');
  const passesAllGuidelines = PASSWORD_GUIDELINES.every((pg) => pg.test(newPassword));

  return (
    <Dialog open={isOpen} onClose={onClose} onTransitionExited={props.onClose}>
      <DialogContent>
        <DialogCloseBtn onClick={onClose} disabled={isLoading} />
        <Typography variant="h3" mb="12px">
          Change password
        </Typography>
        <Typography display="block" variant="body3Emphasized" mb="32px" color="text.secondary">
          Please enter your current password to change.
        </Typography>

        {/* old pass */}
        <FormRow mb="24px">
          <PasswordTextField
            control={control}
            register={register('oldPassword')}
            type={showPassword.old ? 'text' : 'password'}
            label="Old password"
            placeholder="Old Password"
            InputProps={{
              endAdornment: (
                <Box>
                  <ShowHideButton onClick={() => handlePasswordHideShow('old')}>
                    <Typography variant="body2Medium">
                      {showPassword.old ? 'Hide' : 'Show'}
                    </Typography>
                  </ShowHideButton>
                </Box>
              ),
            }}
          />
        </FormRow>
        {/* new pass */}
        <FormRow mb="24px">
          <PasswordTextField
            control={control}
            register={register('newPassword')}
            type={showPassword.new ? 'text' : 'password'}
            label="New password"
            placeholder="New Password"
            InputProps={{
              endAdornment: (
                <Box>
                  <ShowHideButton onClick={() => handlePasswordHideShow('new')}>
                    <Typography variant="body2Medium">
                      {showPassword.new ? 'Hide' : 'Show'}
                    </Typography>
                  </ShowHideButton>
                </Box>
              ),
            }}
          />
        </FormRow>
        {/* confirm new pass */}
        <FormRow mb="16px">
          <PasswordTextField
            control={control}
            register={register('confirmNewPassword')}
            type={showPassword.confirmNew ? 'text' : 'password'}
            label="Confirm new password"
            placeholder="Confirm New Password"
            InputProps={{
              endAdornment: (
                <Box>
                  <ShowHideButton onClick={() => handlePasswordHideShow('confirmNew')}>
                    <Typography variant="body2Medium">
                      {showPassword.confirmNew ? 'Hide' : 'Show'}
                    </Typography>
                  </ShowHideButton>
                </Box>
              ),
            }}
          />
        </FormRow>

        {PASSWORD_GUIDELINES.map((pg) => {
          const isPassing = pg.test(newPassword);

          return (
            <Box mb="10px" display="flex" alignItems="center" key={pg.message}>
              {isPassing ? (
                <CheckCircleFilledIcon
                  fontSize="inherit"
                  sx={{ color: theme.palette.primary.main }}
                />
              ) : (
                <CheckCircleOutlineIcon fontSize="inherit" sx={{ color: '#C0BDB7' }} />
              )}
              <Typography ml="12px">{pg.message}</Typography>
            </Box>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Box>
          <Button variant="text" onClick={onClose} disabled={isLoading} fullWidth>
            Cancel
          </Button>
        </Box>
        <Box>
          <LoadingButton
            onClick={() => handleSubmit(onSubmit)()}
            loading={isLoading}
            disabled={!passesAllGuidelines}
            variant="contained"
            fullWidth
          >
            Save
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const ShowHideButton = styled(ButtonBase)`
  padding: 15px 17px 15px 0px;
`;

const PasswordTextField = styled(TextField)`
  .MuiInputBase-root {
    padding-right: 0px;
  }
  && {
    input {
      border-right: 0px;
    }
  }
`;

const Dialog = styled(MuiDialog)`
  .MuiPaper-root {
    gap: 24px;
    border-radius: 12px;
    max-width: 700px;
    width: 100%;
  }
`;

const useSetPasswordForm = (props?: UseFormProps<FormInputs>) => {
  let defaultValues = props?.defaultValues;
  const validationSchema: yup.SchemaOf<FormInputs> = yup.object({
    oldPassword: yupPassword(),
    newPassword: yupPassword(),
    confirmNewPassword: yupPassword().oneOf([yup.ref('newPassword')], 'Passwords must match'),
  });

  return useForm<FormInputs>({
    ...props,
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });
};
