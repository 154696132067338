import { NewVisitDrawer } from './components/NewVisitDrawer';
import { UserProfile } from './components/UserProfile';
import { VisitHistory } from './components/VisitHistory';
import { LoadingScreen } from '@/components/GlobalContext/components/LoadingScreen';
import useOpenable from '@/hooks/useOpenable';
import { Member } from '@/types/apiContract/member';
import { Visit, VisitReason } from '@/types/apiContract/visit';
import { Box, Container } from '@mui/material';
import { styled } from '@mui/system';
import { useState } from 'react';

type Props = {
  visits: Visit[];
  member: Member;
  visitReasons: VisitReason[];
  onNewVisitComplete: () => Promise<void>;
  isLoading: boolean;
};
export const CheckoutView = (props: Props) => {
  const {
    isOpen: isNewVisitDrawerOpen,
    onClose: onNewVisitDrawerClose,
    onOpen: onNewVisitDrawerOpen,
  } = useOpenable();

  const [pickedDate, setPickedDate] = useState<Date>(new Date());

  if (props.isLoading) {
    return <LoadingScreen details="Loading member..." />;
  }

  return (
    <>
      <NewVisitDrawer
        open={isNewVisitDrawerOpen}
        onDateChange={setPickedDate}
        pickedDate={pickedDate}
        onClose={onNewVisitDrawerClose}
        onComplete={props.onNewVisitComplete}
        member={props.member}
        visits={props.visits}
        visitReasons={props.visitReasons}
      />
      <Body>
        <Container>
          <ContentContainer>
            <UserProfile
              onTodaysVisitClick={onNewVisitDrawerOpen}
              member={props.member}
              visits={props.visits}
              pickedDate={pickedDate}
            />
            <VisitHistory
              visits={props.visits}
              onTodaysVisitClick={onNewVisitDrawerOpen}
              member={props.member}
              visitReasons={props.visitReasons}
            />
          </ContentContainer>
        </Container>
      </Body>
    </>
  );
};

const Body = styled(Box)`
  margin-bottom: 8px;

  ${(props) => props.theme.breakpoints.down('tablet')} {
    padding: 0px 8px;
  }
`;

const ContentContainer = styled(Box)`
  display: flex;
  gap: 40px;

  ${(props) => props.theme.breakpoints.down('tablet')} {
    flex-direction: column;
    align-items: center;
  }
`;
