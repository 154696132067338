import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
  Typography,
} from '@mui/material';
import { useId } from 'react';
import { Controller, UseControllerProps, UseFormRegisterReturn } from 'react-hook-form';

export type SelectProps = MuiSelectProps & {
  control?: UseControllerProps<any>['control'];
  register?: UseFormRegisterReturn<any>;
  label?: string | React.ReactElement;
  options: string[] | number[] | { label: string; value: string }[];
};

// <Select /> component that can be easily integrated with react-hook-form (ie: errors)
// - goal is to reduce as much boilerplate in forms as possible
export const Select = (props: SelectProps) => {
  const {
    control,
    register,
    label,
    options,
    defaultValue = '', // fix issue where value isn't updating when select is initially empty (no defaultValue)
    ...originalSelectProps
  } = props;

  const inputId = useId();
  const uniqueOptions = Array.from(new Set(options.map((x) => x)));
  const renderedOptions = uniqueOptions.map((opt) => {
    if (typeof opt === 'string' || typeof opt === 'number') {
      return (
        <MenuItem key={opt} value={opt}>
          {opt}
        </MenuItem>
      );
    }
    return (
      <MenuItem key={opt.value} value={opt.value}>
        {opt.label}
      </MenuItem>
    );
  });

  // show placeholder if no value is selected
  const getSelectProps = (fieldValue: any): MuiSelectProps => {
    const isOnPlaceholder = fieldValue === '' || fieldValue === undefined;
    return {
      ...(isOnPlaceholder && {
        renderValue: () => {
          return (
            <Typography component="span" {...(isOnPlaceholder && { color: 'text.secondary' })}>
              {props.placeholder}
            </Typography>
          );
        },
      }),
      ...originalSelectProps,
    };
  };

  if (control && register) {
    return (
      <Controller
        control={control}
        name={register.name}
        defaultValue={defaultValue}
        render={({ field, fieldState: { error } }) => {
          const selectProps = getSelectProps(field.value);
          return (
            <FormControl error={Boolean(error)} sx={{ ...(props.fullWidth && { width: '100%' }) }}>
              {label && <InputLabel htmlFor={inputId}>{label}</InputLabel>}
              <MuiSelect
                {...field}
                {...selectProps}
                ref={register.ref} // fix issue where field does not get focused on error (happens on fields that use Controller)
                inputProps={{
                  ...selectProps.inputProps,
                  id: inputId,
                }}
              >
                {renderedOptions}
              </MuiSelect>
              {error?.message && <FormHelperText>{error.message}</FormHelperText>}
            </FormControl>
          );
        }}
      />
    );
  }

  const selectProps = getSelectProps(props.value);
  return <MuiSelect {...selectProps}>{renderedOptions}</MuiSelect>;
};
