import { Box, Typography } from '@mui/material';

type Props = {
  title: string;
  description: string;
};
export const AuthFormHeading = (props: Props) => {
  return (
    <Box>
      <Typography variant="h3" mb="12px" sx={{ textWrap: 'pretty' }}>
        {props.title}
      </Typography>
      <Typography component="div" variant="body1Light" mb="32px">
        {props.description}
      </Typography>
    </Box>
  );
};
