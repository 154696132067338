import { Close, Info, Circle } from '@mui/icons-material';
import { Alert, AlertColor, IconButton, Snackbar, SnackbarProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';

export type KdvSnack = {
  dismissNode?: React.ReactNode;
  icon?: React.ReactNode;
  severity?: AlertColor | 'extreme';
  message: string;
  snackProps?: SnackbarProps;
};

let addSnack: ((snack: KdvSnack) => { clear: () => void }) | null = null;
let clearAllSnacks: (() => void) | null = null;

export default function SnackbarManager() {
  const [snacks, setSnacks] = useState<KdvSnack[]>([]);

  addSnack = (snack: KdvSnack) => {
    const indexOfNewSnack = snacks.length;

    setSnacks((prevSnacks) => [...prevSnacks, snack]);

    return {
      clear: () => clearSnack(indexOfNewSnack),
    };
  };

  clearAllSnacks = () => setSnacks([]);

  const clearSnack = (index: number) => {
    setSnacks(snacks.filter((_v, i) => i !== index));
  };

  return (
    <>
      {snacks.map((snack, index) => {
        const isExtremeSeverity = snack.severity === 'extreme';
        const classProp: { className?: string } = {};
        if (isExtremeSeverity) classProp.className = 'is-extreme';
        const severity = snack.severity === 'extreme' ? 'error' : snack.severity || 'info';

        let icon = snack.icon;
        if (isExtremeSeverity) {
          icon = (
            <IconBacking>
              <Circle htmlColor="#fff" className="is-backing" />
              <Info />
            </IconBacking>
          );
        }

        return (
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            autoHideDuration={5000}
            open
            onClose={() => clearSnack(index)}
            key={index}
            {...snack.snackProps}
          >
            <SnackAlert severity={severity} icon={icon} variant="filled" {...classProp}>
              <SnackAlertInner>
                <Typography>{snack.message}</Typography>
                {snack.snackProps?.action && <Action>{snack.snackProps.action}</Action>}
                {snack.dismissNode && (
                  <DismissNode hasAction={!!snack.snackProps?.action}>
                    <IconButton
                      sx={{ fontSize: '20px' }}
                      color="inherit"
                      onClick={() => clearSnack(index)}
                    >
                      {snack.dismissNode}
                    </IconButton>
                  </DismissNode>
                )}
              </SnackAlertInner>
            </SnackAlert>
          </Snackbar>
        );
      })}
    </>
  );
}

export const snackIt = {
  clearAllSnackbars: () => {
    if (!clearAllSnacks) {
      return;
    }

    clearAllSnacks();
  },
  default: ({
    message,
    icon,
    severity,
    dismissNode = <Close />,
    snackProps,
  }: KdvSnack): void | { clear: () => void } => {
    if (!addSnack) {
      return;
    }

    return addSnack({
      dismissNode,
      icon,
      severity,
      message,
      snackProps,
    });
  },
};

const IconBacking = styled('div')`
  position: relative;
  display: flex;
  height: 24px;
  width: 24px;

  .is-backing {
    font-size: 22px;
    top: 1px;
    left: 1px;
  }

  & > * {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const SnackAlert = styled(Alert)`
  display: flex;
  align-items: center;
  &.is-extreme {
    background: ${(props) => props.theme.palette.grey[900]};

    .MuiAlert-icon {
      color: ${(props) => props.theme.palette.error.dark};
    }
  }
`;

const SnackAlertInner = styled('div')`
  display: flex;
  align-items: center;
  color: #fff;
`;

const Action = styled('div')`
  justify-self: flex-end;
  margin-left: 50px;

  & > button,
  & > button:hover {
    color: #fff;
  }
`;

interface DismissNodeProps {
  hasAction?: boolean;
}

const DismissNode = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hasAction',
})<DismissNodeProps>`
  border-left: 1px solid rgba(255, 255, 255, 0.24);
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 7px;
  margin-left: ${({ hasAction }) => (hasAction ? '0' : '50px')};
  .MuiSvgIcon-root {
    color: inherit;
    opacity: 0.8;
    transition: all 0.1s;
  }
  .MuiIconButton-root:hover > .MuiSvgIcon-root {
    opacity: 1;
  }
`;
