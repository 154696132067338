import { addMonths, isBefore, isSameMonth, subMonths } from 'date-fns';
import { useState } from 'react';

type Props = {
  dualCalendars?: boolean;
  value?: {
    startDate: Date;
    endDate: Date;
  };
};

function useMonthChanger({ dualCalendars, value }: Props) {
  let initialCal2Val = new Date();
  let initialCal1Val = new Date();

  if (dualCalendars) {
    if (value?.startDate && value?.endDate) {
      initialCal1Val = value.startDate;
      if (isSameMonth(value.startDate, value.endDate)) {
        initialCal2Val = addMonths(initialCal1Val, 1);
      } else {
        initialCal2Val = value.endDate;
      }
    } else {
      initialCal1Val = subMonths(initialCal2Val, 1);
    }
  } else if (value?.startDate && value?.endDate) {
    initialCal1Val = value.endDate;
  }

  const [cal1Val, setCal1Val] = useState<Date>(initialCal1Val);
  const [cal2Val, setCal2Val] = useState<Date>(initialCal2Val);

  return {
    cal1Val,
    cal2Val,
    handleMonthChangeCal1: (month: Date) => {
      setCal1Val(month);
      if (isBefore(cal2Val, month) || isSameMonth(cal2Val, month)) {
        setCal2Val(addMonths(month, 1));
      }
    },
    handleMonthChangeCal2: (month: Date) => {
      setCal2Val(month);
      if (isBefore(month, cal1Val) || isSameMonth(cal1Val, month)) {
        setCal1Val(subMonths(month, 1));
      }
    },
  };
}

export { useMonthChanger };
