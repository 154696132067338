import { TextField } from './TextField';
import { ComponentProps } from 'react';

type Props = ComponentProps<typeof TextField> & {
  numberFieldProps?: {
    maxDecimals?: number;
    selectAllOnFocus?: boolean;
  };
};
const defaultNumberFieldProps = {
  selectAllOnFocus: true, // selects all on click (makes it easier to update number for user)
} satisfies Props['numberFieldProps'];

// NOTE: most features of this component will only work if the control & register props from react-hook-form are applied (ie: onChangeFilter, onBlurFilter)
export const NumberTextField = (props: Props) => {
  const { numberFieldProps: origNumberFieldProps, ...fieldProps } = props;
  const numberFieldProps = {
    ...defaultNumberFieldProps,
    ...origNumberFieldProps,
  };
  const allowDecimals = (numberFieldProps.maxDecimals || 0) > 0;

  return (
    <TextField
      {...fieldProps}
      onChangeFilter={(val) => {
        return numbersOnly(val, {
          allowDecimals: allowDecimals,
          maxDecimals: numberFieldProps.maxDecimals,
        });
      }}
      onBlurFilter={(val) => {
        // strip off extra decimals, also ensures a real number is returned (ie: no leading zeros)
        if (numberFieldProps.maxDecimals !== undefined) {
          return Number(val).toFixed(numberFieldProps.maxDecimals);
        }
        return val;
      }}
      inputProps={{
        ...(numberFieldProps.selectAllOnFocus && {
          onFocus: (event) => event.target.select(),
        }),
        ...{ inputMode: 'decimal' },
        ...(!allowDecimals && { inputMode: 'numeric' }),
        ...fieldProps.inputProps,
      }}
    />
  );
};

const numbersOnly = (value: string, options: { allowDecimals: boolean; maxDecimals?: number }) => {
  if (options.allowDecimals) {
    const valWithDecimals = value.replace(/[^0-9.]/g, '');
    const [int, dec] = valWithDecimals.split('.');
    const hasDecimalPoint = dec !== undefined;

    if (options.maxDecimals) {
      if (dec && dec.length > options.maxDecimals) {
        return `${int}.${dec.slice(0, options.maxDecimals)}`;
      }
    }

    // if has decimal point, dont allow anymore decimal points
    if (hasDecimalPoint) {
      return `${int}.${dec}`;
    }

    return valWithDecimals;
  }

  const [int] = value.split('.'); // in case number w/ decimal is copy pasted in
  return int.replace(/\D/g, '');
};
