import { VisitCategory } from '../../../types';
import { VisitFormInputs } from '../hooks/useVisitForm';
import { MultiYesOrNoRadioGroup } from './MultiYesOrNoRadioGroup';
import { FormRow } from '@/components/UIKit/Form/styled';
import { Checkbox } from '@/components/UIKit/Inputs/Checkbox/Checkbox';
import { BoxedRadioGroup } from '@/components/UIKit/Inputs/RadioGroup/RadioGroup';
import { TextField } from '@/components/UIKit/Inputs/TextField/TextField';
import { VisitReason } from '@/types/apiContract/visit';
import { Box, Typography, styled } from '@mui/material';
import { startCase } from 'lodash-es';
import { Controller, useFormContext } from 'react-hook-form';

type Props = {
  category: VisitCategory;
  disabledVisitReasonTypes: VisitReason['type'][];
};
export const VisitCategoryGroup = (props: Props) => {
  const { category } = props;
  const { watch, setValue } = useFormContext<VisitFormInputs>();
  const selectedReasons = watch('selectedReasons');

  const onCheckboxChange = (reason: VisitReason, isChecked: boolean) => {
    const { type, allowedDetails } = reason;
    const newSelectedReasons = selectedReasons.filter((v) => v.type !== type);
    if (isChecked) {
      const hasExtraDetails = allowedDetails.length > 0;
      newSelectedReasons.push({
        isChecked,
        type,
        isDetailRequired: hasExtraDetails,
      });
    }
    setValue('selectedReasons', newSelectedReasons);
  };

  return (
    <Box>
      <Typography color="textSecondary" variant="h6" mb="24px">
        {startCase(category.name)}
      </Typography>
      {category.reasons.map((reason) => {
        const checkboxIndex = selectedReasons.findIndex((sr) => sr.type === reason.type);
        const isChecked = Boolean(selectedReasons[checkboxIndex]?.isChecked);
        const isDisabled = props.disabledVisitReasonTypes.includes(reason.type);

        return (
          <Box key={reason.type} sx={{ ':not(:last-of-type)': { mb: '16px' } }}>
            <Box display="flex" alignItems="center">
              <Checkbox
                checked={isChecked}
                onChange={(e) => onCheckboxChange(reason, e.target.checked)}
                label={
                  <Typography variant="body1Medium" sx={{ ...(isDisabled && { opacity: '0.56' }) }}>
                    {reason.name}
                  </Typography>
                }
                disabled={isDisabled}
              />
              {reason.label && (
                <Label>
                  <Typography variant="body3Medium" sx={{ ...(isDisabled && { opacity: '0.56' }) }}>
                    {reason.label}
                  </Typography>
                </Label>
              )}
            </Box>
            {isChecked && (
              <Box mt="16px">
                <ReasonDetailField reason={reason} checkboxIndex={checkboxIndex} />
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

const Label = styled(Box)`
  background: #f0f1ec;
  padding: 1px 8px;
  border-radius: 24px;
  display: flex;
`;

type ReasonDetailFieldProps = {
  reason: VisitReason;
  checkboxIndex: number;
};
const ReasonDetailField = (props: ReasonDetailFieldProps) => {
  const { checkboxIndex } = props;
  const value = props.reason.type;
  const { register, control } = useFormContext<VisitFormInputs>();

  if (value === 'SKIN_CANCER_DIAGNOSIS') {
    const OPTIONS = [
      { value: 'Excision' },
      { value: 'Cryotherapy' },
      { value: "Moh's surgery" },
      { value: 'Other' },
      { value: 'No' },
    ];

    // check that all options are valid
    const { validOptions, foundInvalidOptions } = filterValidOptions(
      value,
      OPTIONS,
      props.reason.allowedDetails,
    );
    if (foundInvalidOptions) return null;

    const fieldName = `selectedReasons.${checkboxIndex}.detail` as const;
    return (
      <Controller
        name={fieldName}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <MultiYesOrNoRadioGroup
            {...field}
            ref={register(fieldName).ref}
            options={validOptions}
            error={error}
          />
        )}
      />
    );
  }

  // if there are multiple options for the detail field, show radio buttons
  const isRadioGroupDetail = props.reason.allowedDetails.length > 1;
  if (isRadioGroupDetail) {
    const isRowStyle = value === 'SKIN_CANCER_SCREENING';

    return (
      <Box>
        <FormRow mb="0px">
          <BoxedRadioGroup
            control={control}
            register={register(`selectedReasons.${checkboxIndex}.detail`)}
            options={props.reason.allowedDetails.map((val) => {
              return { value: val, label: val };
            })}
            row={isRowStyle}
            styledProps={{
              isOutlined: true,
            }}
          />
        </FormRow>
      </Box>
    );
  }

  // if there is only one option for the detail field, show a text field
  const isASingleFieldDetail =
    props.reason.allowedDetails.length === 1 && props.reason.allowedDetails[0] === 'ANY';
  if (isASingleFieldDetail) {
    return (
      <Box>
        <FormRow mb="0px">
          <TextField
            control={control}
            register={register(`selectedReasons.${checkboxIndex}.detail`)}
            placeholder="Specify here"
            fullWidth
          />
        </FormRow>
      </Box>
    );
  }

  return null;
};

// filtering out invalid options (based on backend)
// - ideally we would use backend values directly but this allows full control of how frontend will look (ie: custom option group UI, sorting)
// - if the reason "type" or "allowedDetails" values from the backend ever change, the extra fields or options simply wont show up. so shouldnt run into issues (would just need to manually update frontend)
const filterValidOptions = (
  value: string,
  options: { value: string }[],
  allowedOptions: string[],
) => {
  let foundInvalidOptions = false;
  const validOptions = options.filter((opt) => {
    const isAllowed = allowedOptions.includes(opt.value);
    if (!isAllowed) {
      foundInvalidOptions = true;
      console.error(
        `Found invalid option (${
          opt.value
        }) in ${value}. It should be one of [${allowedOptions.join(
          ', ',
        )}]. It will be ignored, but should update the frontend options`,
      );
    }
    return isAllowed;
  });

  return { validOptions, foundInvalidOptions };
};
