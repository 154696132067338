import axios from 'axios';
import {
  CognitoUserPool,
  CognitoUser,
  ICognitoUserPoolData,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import config from '@/config';

const poolData: ICognitoUserPoolData = {
  UserPoolId: config.cognitoUserPoolId,
  ClientId: config.cognitoWebClientId,
};

function sendCustomChallengeAnswerAsync(
  cognitoUser: CognitoUser,
  answer: string,
): Promise<
  { type: 'success'; session: CognitoUserSession } | { type: 'customChallenge'; params: any }
> {
  return new Promise((resolve, reject) => {
    cognitoUser.sendCustomChallengeAnswer(answer, {
      onSuccess: (session: CognitoUserSession) => resolve({ type: 'success', session }),
      onFailure: reject,
      customChallenge: (challengeParams) =>
        resolve({ type: 'customChallenge', params: challengeParams }),
    });
  });
}

export async function respondToCustomChallengeDirect() {
  const params = new URLSearchParams(window.location.search);
  const sessionParam = params.get('session');
  const identityParam = params.get('identity');

  if (!sessionParam || !identityParam) {
    return false;
  }

  const decoded = atob(identityParam);
  const [email, secretAnswer] = decoded.split(':');
  if (!email || !secretAnswer) {
    return false;
  }

  try {
    const userPool = new CognitoUserPool(poolData);
    const cognitoUser = new CognitoUser({ Username: email, Pool: userPool });
    cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
    (cognitoUser as any).Session = sessionParam;
    (cognitoUser as any).signInUserSession = sessionParam;

    try {
      window.localStorage.clear();
      const result = await sendCustomChallengeAnswerAsync(cognitoUser, secretAnswer);
      if (result.type === 'success') {
        const accessToken = result.session.getAccessToken().getJwtToken();
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        console.info('Logged in, AccessToken set');
        return true;
      } else if (result.type === 'customChallenge') {
        console.warn('Another challenge needed:', result.params);
        return false;
      }
    } catch (err) {
      console.error('Error with sendCustomChallengeAnswerAsync:', err);
    }
    return false;
  } catch (err) {
    console.error('Main error:', err);
    return false;
  }
}
