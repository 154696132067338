import patientChartIllustration from '@/assets/checkout/patient-chart-illustration.svg';
import { AddIcon } from '@/components/UIKit/Icons';
import { Member } from '@/types/apiContract/member';
import { Visit, VisitReason } from '@/types/apiContract/visit';
import { formatNum } from '@/utils/formatting';
import { Box, Button, Card, Typography, styled } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { startCase } from 'lodash-es';

type VisitInfo = Visit['visitInfo'][0];
type VisitProp = Pick<Visit, 'id' | 'createdAt' | 'claimed' | 'visitDate'> & {
  visitInfo: Pick<VisitInfo, 'reason'>[];
};
type MemberCreditInfo = Member['consumerClaimHistory']['creditInfo'][0];
type MemberProp = {
  consumerClaimHistory: {
    creditInfo: Pick<MemberCreditInfo, 'claimedByVisitId' | 'totalCreditAmount'>[];
  };
};

type Props = {
  visits: VisitProp[];
  member: MemberProp;
  visitReasons: Pick<VisitReason, 'name' | 'type' | 'label'>[];
  onTodaysVisitClick: () => void;
};

export const VisitHistory = (props: Props) => {
  return (
    <Body>
      <Heading variant="h4">Visits history</Heading>
      {props.visits.length === 0 ? (
        <EmptyVisitsBlurb onTodaysVisitClick={props.onTodaysVisitClick} />
      ) : (
        <VisitsListContainer>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={props.onTodaysVisitClick}
              fullWidth
              startIcon={<AddIcon />}
            >
              Add new visit
            </Button>
          </Box>
          <VisitsList>
            {props.visits.map((v, i) => {
              const creditInfo = props.member.consumerClaimHistory.creditInfo.find((ci) => {
                return ci.claimedByVisitId === v.id;
              });

              const claimedAmount = Number(creditInfo?.totalCreditAmount);

              return (
                <VisitCard key={i}>
                  <Box display="flex" mb="16px" justifyContent="space-between">
                    <Typography variant="body3">Reasons for visit</Typography>
                    {/* <Typography variant="body3">
                      {format(parseISO(v.createdAt), "PP")}
                    </Typography> */}
                    {v.visitDate && (
                      <Typography variant="body3">{format(parseISO(v.visitDate), 'PP')}</Typography>
                    )}
                  </Box>
                  <Box display="flex" flexDirection="column" gap="8px">
                    {v.visitInfo.map((item) => {
                      const visitReasonData = props.visitReasons.find(
                        (vr) => vr.type === item.reason,
                      );
                      let name = visitReasonData?.name || startCase(item.reason.toLowerCase());

                      if (name === 'Other') {
                        name = startCase(item.reason.toLowerCase());
                      }

                      return (
                        <Box
                          key={item.reason}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="start"
                        >
                          <Typography variant="body3Emphasized">{name}</Typography>
                          {visitReasonData?.label && (
                            <Box p="1px 8px" bgcolor="#F0F1EC" borderRadius="24px" display="flex">
                              <Typography variant="body3Medium">{visitReasonData.label}</Typography>
                            </Box>
                          )}
                        </Box>
                      );
                    })}
                  </Box>
                  {v.claimed && (
                    <Box
                      pt="16px"
                      mt="16px"
                      borderTop="1px solid #E9EBED"
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Typography variant="body3">Claimed</Typography>
                      <Typography variant="body3">
                        {formatNum(claimedAmount, {
                          isDollars: true,
                        })}
                      </Typography>
                    </Box>
                  )}
                </VisitCard>
              );
            })}
          </VisitsList>
        </VisitsListContainer>
      )}
    </Body>
  );
};

const PADDING = '40px';
const Body = styled(Box)`
  background: #f6f5f4;
  padding-top: ${PADDING};
  border-radius: 8px;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 936px;

  ${(props) => props.theme.breakpoints.down('tablet')} {
    width: 100%;
    margin-bottom: 40px;
    height: auto;
  }
`;

const Heading = styled(Typography)`
  padding: 0px ${PADDING};
  margin-bottom: 32px;
`;

const VisitsListContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  > * {
    padding: 0px ${PADDING};
  }
`;

const VisitsList = styled(Box)`
  flex: 1;
  overflow: auto;
  padding-top: 44px;
  margin-top: -22px;
`;

const VisitCard = styled(Card)`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 22px;
`;

const EmptyVisitsBlurb = (props: { onTodaysVisitClick: () => void }) => {
  return (
    <VisitsContainer>
      <Box display="flex" mb="48px">
        <img src={patientChartIllustration} alt="patient chart" />
      </Box>
      <Typography variant="h3" mb="32px">
        Looks like it’s a first visit
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={props.onTodaysVisitClick}
        sx={{ width: '200px' }}
        startIcon={<AddIcon />}
      >
        Add new visit
      </Button>
    </VisitsContainer>
  );
};

const VisitsContainer = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 8px ${PADDING} ${PADDING} ${PADDING};
`;
