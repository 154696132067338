import { snackIt } from '@/components/SnackbarManager';
import { AxiosError, AxiosResponse } from 'axios';

export const handleApiError = (e: Error) => {
  if (e instanceof AxiosError) {
    const errMessage = getApiErrorMessage(e);
    handleError(errMessage);
  } else {
    handleError(e.message || 'An error occured');
  }
};

// attempts to get error message from api
export const getApiErrorMessage = (e: AxiosError) => {
  const errResponse = e.response?.data as
    | {
        message?: string;
        validationErrors?: string[];
      }
    | undefined;
  const errMessageFromResponse = errResponse?.message;
  const firstValidationError = errResponse?.validationErrors && errResponse?.validationErrors[0];

  // validation error is the most helpful so show that if exists
  return firstValidationError || errMessageFromResponse || 'An error occurred';
};

// these are for cases where the api request fails (ie: bad input) but it still returns 200.
// in these cases, api seems to return the error "message" in the response.data object
export const throwErrOnMessageResponse = (res: AxiosResponse) => {
  const errMessage = res.data.message;
  if (errMessage) {
    throw Error(errMessage);
  }
};

const handleError = (message: string) => {
  snackIt.default({
    severity: 'error',
    message: message,
  });
};
