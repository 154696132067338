import { HeroSectionCard } from './UI';
import joyaPresentationFileSrc from '@/assets/dashboard/file-downloads/JOYA Provider Onboarding Presentation by Davina Frick.pdf';
import joyaSOPFileSrc from '@/assets/dashboard/file-downloads/SOP Provider Onboarding.pdf';
import skinCancerAwarenessFileSrc from '@/assets/dashboard/file-downloads/Skin Cancer Awareness.pdf';
import { useDownloadManager } from '@/hooks/useDownloadManager';
import { Practice } from '@/types/apiContract/practice';
import { LoadingButton } from '@mui/lab';
import { Box, Typography, styled } from '@mui/material';

const DOWNLOAD_FILES = [
  {
    shortName: 'Skin Cancer Awareness',
    fileName: 'Skin Cancer Awareness.pdf',
    size: '0.2MB',
    type: 'PDF',
    url: skinCancerAwarenessFileSrc,
    isHiddenForDermatologyProvider: true,
  },
  {
    shortName: 'Joya Presentation',
    fileName: 'JOYA Provider Onboarding Presentation by Davina Frick.pdf',
    size: '0.6MB',
    type: 'PDF',
    url: joyaPresentationFileSrc,
  },
  {
    shortName: 'Joya SOP',
    fileName: 'SOP Provider Onboarding.pdf',
    size: '0.5MB',
    type: 'PDF',
    url: joyaSOPFileSrc,
  },
];
type Props = {
  provider: {
    type: Practice['type'];
  };
};
export const DownloadSection = (props: Props) => {
  return (
    <DownloadSectionBody>
      <Typography variant="h6" mb="40px">
        INFORMATION
      </Typography>

      <DownloadList>
        {DOWNLOAD_FILES.map((file) => {
          if (file.isHiddenForDermatologyProvider && props.provider.type === 'DERMATOLOGY') {
            return null;
          }

          return <DownloadItem key={file.shortName} file={file} />;
        })}
      </DownloadList>
    </DownloadSectionBody>
  );
};

const DownloadSectionBody = styled(HeroSectionCard)`
  padding: 20px 20px 10px 20px;
  width: 390px;
  ${(props) => props.theme.breakpoints.down('tablet')} {
    flex: 1;
    width: auto;
  }
`;

const DownloadList = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

type DownloadItemProps = {
  file: {
    shortName: string;
    size: string;
    type: string;
    url: string;
    fileName: string;
  };
};
const DownloadItem = (props: DownloadItemProps) => {
  const { file } = props;
  const { download, loading } = useDownloadManager();

  const onDownloadClick = async () => {
    await download({ url: file.url, fileName: file.fileName });
  };

  return (
    <DownloadItemBody>
      <Box display="flex" flexDirection="column">
        <Typography variant="body3" mb="2px">
          {file.shortName}
        </Typography>
        <FileInfoText>
          {file.type}, {file.size}
        </FileInfoText>
      </Box>
      <LoadingButton
        variant="contained"
        color="primary"
        size="small"
        onClick={onDownloadClick}
        loading={loading}
      >
        Download
      </LoadingButton>
    </DownloadItemBody>
  );
};

const DownloadItemBody = styled(Box)`
  padding: 8px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid #ffffff0d;
  :last-of-type {
    border-bottom: none;
  }
`;

const FileInfoText = styled(Typography)`
  font-size: 12px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 0.56);
`;
