import { VisitCategory } from '../../types';
import { FooterFields } from './components/FooterFields';
import { SelectProvidersStep } from './components/SelectProvidersStep';
import { VisitCategoryGroup } from './components/VisitCategoryGroup';
import { VisitFormInputs, useVisitForm } from './hooks/useVisitForm';
import { DateField } from '@/components/UIKit/Inputs/Date/DateField';
import { DialogCloseBtn } from '@/components/UIKit/Modal/misc';
import useBreakpointCheck from '@/hooks/useBreakpointCheck';
import { VisitReason } from '@/types/apiContract/visit';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  DialogContent,
  Drawer,
  FormHelperText,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { isAfter, isBefore, startOfQuarter, subQuarters } from 'date-fns';
import { useEffect, useState } from 'react';
import { FormProvider, Controller } from 'react-hook-form';

type SubmitValues = VisitFormInputs;
export type { SubmitValues as NewVisitSubmitValues };

type Props = {
  onClose: () => void;
  onDateChange: (date: Date) => void;
  open: boolean;
  visitCategories: VisitCategory[];
  onSubmit: (values: SubmitValues) => Promise<void>;
  hasDoneSkinCancerScreening: boolean;
  availableCredit: number;
  disabledVisitReasonTypes: VisitReason['type'][];
  memberCreatedAt: Date;
};

export const NewVisitDrawerView = (props: Props) => {
  const methods = useVisitForm({
    defaultValues: {
      selectedReasons: [],
      selectedProviders: [],
      visitDate: new Date(),
    },
  });
  const { handleSubmit, watch, control } = methods;
  const isTablet = useBreakpointCheck('tablet');
  const selectedReasons = watch('selectedReasons');
  const selectedProviders = watch('selectedProviders');
  const visitDate = watch('visitDate');

  useEffect(() => {
    props.onDateChange(visitDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitDate]);

  const [isLoading, setIsLoading] = useState(false);
  const [formErrMessage, setFormErrMessage] = useState<string>();
  const [isOnProvidersStep, setIsOnProvidersStep] = useState(false);

  const onSubmit = async (formValues: VisitFormInputs) => {
    // step 1 checks
    if (selectedReasons.length === 0) {
      setFormErrMessage('Please select at least one reason');
      return;
    }
    if (!formValues.visitDate) {
      setFormErrMessage('Please select a visit date');
      return;
    }
    if (isAfter(new Date(formValues.visitDate), new Date())) {
      setFormErrMessage('The visit date cannot be in the future');
      return;
    }
    setFormErrMessage(undefined);

    // if step 1 is done go to step 2
    if (!isOnProvidersStep) {
      setIsOnProvidersStep(true);
      return;
    }

    // step 2 checks
    if (selectedProviders.length === 0) {
      setFormErrMessage('Please select at least one provider');
      return;
    }
    setFormErrMessage(undefined);

    setIsLoading(true);
    try {
      await props.onSubmit(formValues);
    } catch (e: any) {
      throw Error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const onContinue = () => {
    handleSubmit(onSubmit)();
  };

  const onClose = () => {
    if (isLoading) return;
    props.onClose();
  };

  const renderContent = () => {
    if (isOnProvidersStep) {
      return <SelectProvidersStep />;
    }
    const shouldDisableDate = (day: any) => {
      // get first day of 2 quarters before current quarter
      // right now we do not allow to checkout visits which are more than 2 quarters old
      const today = new Date();
      const previousQuarterDate = subQuarters(today, 2);
      const firstDayOfPreviousQuarter = startOfQuarter(previousQuarterDate);
      return isBefore(day, props.memberCreatedAt) || isBefore(day, firstDayOfPreviousQuarter);
    };

    return (
      <Box>
        <Box maxWidth={360} mb={3}>
          <Controller
            name="visitDate"
            control={control}
            render={({ field }) => (
              <DateField
                name="visitDate"
                value={field.value}
                datePickerProps={{
                  disableFuture: true,
                  onChange: (date) => {
                    field.onChange(date);
                    props.onDateChange(date as Date);
                  },
                  format: 'MMMM dd, yyyy',
                  shouldDisableDate,
                }}
                textFieldProps={{
                  fullWidth: true,
                }}
              />
            )}
          />
        </Box>
        {props.visitCategories.map((cat) => {
          return (
            <Box key={cat.name} sx={{ ':not(:last-of-type)': { mb: '32px' } }}>
              <VisitCategoryGroup
                category={cat}
                disabledVisitReasonTypes={props.disabledVisitReasonTypes}
              />
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <Drawer anchor={isTablet ? 'bottom' : 'right'} open={props.open}>
      <FormProvider {...methods}>
        <DialogContent sx={{ p: '0px' }}>
          <DialogContentInner role="presentation">
            <Box p="40px 40px 24px 40px" flex={1}>
              <DialogCloseBtn disabled={isLoading} onClick={onClose} />
              <Stack spacing={1} mb={4}>
                <Typography variant="h3">Visit Date</Typography>
                <Typography variant="body2Medium">
                  Please enter the date of customer visit.
                </Typography>
              </Stack>
              {renderContent()}
            </Box>
            <Box p="20px 40px 32px 40px" sx={{ borderTop: '1px solid #F6F5F4' }}>
              {!isOnProvidersStep && (
                <FooterFields
                  visitCategories={props.visitCategories}
                  availableCredit={props.availableCredit}
                  hasDoneSkinCancerScreening={props.hasDoneSkinCancerScreening}
                />
              )}
              {formErrMessage && (
                <Box mb="16px">
                  <FormHelperText error>{formErrMessage}</FormHelperText>
                </Box>
              )}
              <Box display="flex" gap="16px">
                <Button variant="text" onClick={onClose} sx={{ width: '160px' }}>
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  onClick={onContinue}
                  loading={isLoading}
                  sx={{ flex: 1 }}
                >
                  {isOnProvidersStep ? 'Complete visit' : 'Continue'}
                </LoadingButton>
              </Box>
            </Box>
          </DialogContentInner>
        </DialogContent>
      </FormProvider>
    </Drawer>
  );
};

const DialogContentInner = styled(Box)`
  min-width: 570px;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.down('tablet')} {
    min-width: auto;
    width: 100%;
  }
`;
