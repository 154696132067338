import { LoggedInProvider } from '../../components/GlobalContext';
import { FillersOffers } from './components/Steps/FillersOffers';
import { GettingToKnowYou } from './components/Steps/GettingToKnowYou';
import { GettingToKnowYourPractice } from './components/Steps/GettingToKnowYourPractice';
import { InsuranceDetails } from './components/Steps/InsuranceDetails';
import { LetsLearnAboutYourPractice } from './components/Steps/LetsLearnAboutYourPractice';
import { NeurotoxinsOffers } from './components/Steps/NeurotoxinsOffers';
import { OurOfferings } from './components/Steps/OurOfferings';
import { PracticeEnergyBasedDevices } from './components/Steps/PracticeEnergyBasedDevices';
import { ProviderLanguages } from './components/Steps/PracticeLanguages';
import { PracticeLinks } from './components/Steps/PracticeLinks';
import { PracticePromotions } from './components/Steps/PracticePromotions';
import { ProvidersInYourPractice } from './components/Steps/ProvidersInYourPractice';
import { SchedulingMethod } from './components/Steps/SchedulingMethod';
import { YourSuperpowers } from './components/Steps/YourSuperpowers';
import { cleanBusinessDay } from './utils/misc';
import {
  SectionIdType,
  getCurrentSurveyStep,
  getStep,
  getSurveyAnswers,
  valuesToAnsweredSurveyQuestions,
} from './utils/survey';
// import { ChemicalPeelOffers } from "./components/Steps/ChemicalPeelOffers";
import {
  Offering,
  Practice,
  PracticeSystem,
  UpdatePracticeRequest,
} from '@/types/apiContract/practice';
// import { SkinTypes } from "./components/Steps/SkinTypes";
import { UpdateSurveyRequest } from '@/types/apiContract/survey';
import { Box, styled } from '@mui/material';
import { PropsWithChildren, useEffect, useState } from 'react';
import { Location, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

type LocationState = { from?: string } | null;
export type OnUpdateProviderParams = {
  data: UpdatePracticeRequest;
  uncheckedOfferings?: { id: string }[];
  overrideOfferingsWithoutId?: boolean;
};
type Props = {
  loggedInProvider: LoggedInProvider;
  offerings: Offering[];
  onUpdateSurvey: (params: {
    questions?: UpdateSurveyRequest['questions'];
    state?: UpdateSurveyRequest['state'];
  }) => Promise<void>;
  onUpdateProvider: (params: OnUpdateProviderParams) => Promise<void>;
  onUpdateProviderPhoto: (file: File) => Promise<void>;
  sectionIdFromURL?: SectionIdType;
};
export const OnboardingView = (props: Props) => {
  const {
    loggedInProvider,
    offerings,
    onUpdateSurvey,
    onUpdateProvider,
    onUpdateProviderPhoto,
    sectionIdFromURL,
  } = props;
  const navigate = useNavigate();
  const location = useLocation() as Location<LocationState>;
  const [searchParams] = useSearchParams();

  // --- Steps & Navigating ---
  const surveyQuestions = loggedInProvider.survey?.questions;

  const [isDermatology, setIsDermatology] = useState<boolean>(
    'DERMATOLOGY' === loggedInProvider.type,
  );

  const initStep = sectionIdFromURL
    ? getStep(sectionIdFromURL)
    : getCurrentSurveyStep({ surveyQuestions, isDermatology });
  const [step, setStep] = useState(initStep);

  // leave onboarding page to previous page (goes to /dashboard if not applicable)
  const backOutOfOnboardingPage = () => {
    let backTo = location.state?.from || '/dashboard';
    if (backTo.startsWith('/onboarding')) backTo = '/dashboard';
    searchParams.append('editingProfile', '1');
    navigate({
      pathname: backTo,
      search: searchParams.toString(),
    });
    return;
  };
  const onBack = () => {
    if (sectionIdFromURL) {
      backOutOfOnboardingPage();
      return;
    }

    window.scrollTo(0, 0);
    setStep((curr) => curr - 1);
  };
  const onContinue = () => {
    if (sectionIdFromURL) {
      backOutOfOnboardingPage();
      return;
    }

    if (step === getStep('insurance-details')) {
      navigate('/dashboard');
      return;
    }

    window.scrollTo(0, 0);
    setStep((curr) => curr + 1);
  };

  // when url route changes, go to that survey section
  useEffect(() => {
    if (!sectionIdFromURL) return;
    const newStep = getStep(sectionIdFromURL);
    setStep(newStep);
  }, [sectionIdFromURL]);

  // --- Organize Data ---
  // organize offerings data
  const joyaOfferings = offerings.filter((x) => x.type === 'JOYA');
  const neurotoxinOfferings = offerings.filter((x) => x.type === 'NEUROTOXIN');
  const fillerOfferings = offerings.filter((x) => x.type === 'FILLER');

  return (
    <Body>
      <StepContent isShowing={step === getStep('getting-to-know-you')}>
        <GettingToKnowYou
          defaultValues={getSurveyAnswers({
            surveyQuestions,
            sectionId: 'getting-to-know-you',
          })}
          onContinue={async (values) => {
            await onUpdateSurvey({
              questions: valuesToAnsweredSurveyQuestions({
                sectionId: 'getting-to-know-you',
                values,
              }),
            });
            onContinue();
          }}
          {...(sectionIdFromURL && {
            onBack: onBack,
          })}
        />
      </StepContent>
      <StepContent isShowing={step === getStep('getting-to-know-your-practice')}>
        <GettingToKnowYourPractice
          defaultValues={getSurveyAnswers({
            surveyQuestions,
            sectionId: 'getting-to-know-your-practice',
          })}
          avatarImgSrc={loggedInProvider.photo}
          onBack={onBack}
          onContinue={async (values) => {
            setIsDermatology(values.medicalSpecialty === 'DERMATOLOGY');

            // update survey
            await onUpdateSurvey({
              questions: valuesToAnsweredSurveyQuestions({
                sectionId: 'getting-to-know-your-practice',
                values,
              }),
            });

            // update practice
            await onUpdateProvider({
              data: {
                name: values.name,
                type: values.medicalSpecialty as Practice['type'],
                address: {
                  street: values.addressLine,
                  street2: values.addressLine2,
                  zip: values.zipCode,
                  city: values.city,
                  state: values.state,
                },
                phone: values.phoneNum,
                schedule: {
                  monday: cleanBusinessDay(values.businessHours.monday),
                  tuesday: cleanBusinessDay(values.businessHours.tuesday),
                  wednesday: cleanBusinessDay(values.businessHours.wednesday),
                  thursday: cleanBusinessDay(values.businessHours.thursday),
                  friday: cleanBusinessDay(values.businessHours.friday),
                  saturday: cleanBusinessDay(values.businessHours.saturday),
                  sunday: cleanBusinessDay(values.businessHours.sunday),
                },
              },
            });
            onContinue();
          }}
          onUploadAvatar={async (file) => {
            await onUpdateProviderPhoto(file);
          }}
        />
      </StepContent>
      <StepContent isShowing={step === getStep('practice-links')}>
        <PracticeLinks
          defaultValues={getSurveyAnswers({
            surveyQuestions,
            sectionId: 'practice-links',
          })}
          onBack={onBack}
          onContinue={async (values) => {
            // update survey
            await onUpdateSurvey({
              questions: valuesToAnsweredSurveyQuestions({
                sectionId: 'practice-links',
                values,
              }),
            });

            // update practice
            const providerLinks = Object.entries(values).map(([key, val]) => {
              return { type: key, url: val };
            });
            await onUpdateProvider({
              data: { links: providerLinks },
            });
            onContinue();
          }}
        />
      </StepContent>
      <StepContent isShowing={step === getStep('providers-in-your-practice')}>
        <ProvidersInYourPractice
          onBack={onBack}
          onContinue={() => {
            onContinue();
          }}
        />
      </StepContent>
      <StepContent isShowing={step === getStep('our-offerings')}>
        <OurOfferings
          isDermatology={isDermatology}
          offeringOptions={joyaOfferings}
          defaultValues={getSurveyAnswers({
            surveyQuestions,
            sectionId: 'our-offerings',
          })}
          onBack={onBack}
          onContinue={async (values) => {
            // update survey
            await onUpdateSurvey({
              questions: valuesToAnsweredSurveyQuestions({
                sectionId: 'our-offerings',
                values,
              }),
            });

            // check if an offer was removed
            const uncheckedOfferings = joyaOfferings.filter((off) => {
              const isChecked = values.offerings.find((x) => x.id === off.id);
              return !isChecked;
            });

            // update practice
            await onUpdateProvider({
              data: { offerings: getOfferingsWithoutName(values.offerings) },
              uncheckedOfferings,
            });
            onContinue();
          }}
        />
      </StepContent>
      <StepContent isShowing={step === getStep('neurotoxin-offers')}>
        <NeurotoxinsOffers
          offeringOptions={neurotoxinOfferings}
          defaultValues={getSurveyAnswers({
            surveyQuestions,
            sectionId: 'neurotoxin-offers',
          })}
          onBack={onBack}
          onContinue={async (values) => {
            // update survey
            await onUpdateSurvey({
              questions: valuesToAnsweredSurveyQuestions({
                sectionId: 'neurotoxin-offers',
                values,
              }),
            });

            // check if an offer was removed
            const uncheckedOfferings = neurotoxinOfferings.filter((off) => {
              const isChecked = values.offerings.find((x) => x.id === off.id);
              return !isChecked;
            });

            // update practice
            await onUpdateProvider({
              data: { offerings: getOfferingsWithoutName(values.offerings) },
              uncheckedOfferings,
            });
            onContinue();
          }}
        />
      </StepContent>
      <StepContent isShowing={step === getStep('fillers-offers')}>
        <FillersOffers
          offeringOptions={fillerOfferings}
          defaultValues={getSurveyAnswers({
            surveyQuestions,
            sectionId: 'fillers-offers',
          })}
          onBack={onBack}
          onContinue={async (values) => {
            // update survey
            await onUpdateSurvey({
              questions: valuesToAnsweredSurveyQuestions({
                sectionId: 'fillers-offers',
                values,
              }),
            });

            // check if an offer was removed
            const uncheckedOfferings = fillerOfferings.filter((off) => {
              const isChecked = values.offerings.find((x) => x.id === off.id);
              return !isChecked;
            });

            // update practice
            await onUpdateProvider({
              data: { offerings: getOfferingsWithoutName(values.offerings) },
              uncheckedOfferings,
            });
            onContinue();
          }}
        />
      </StepContent>
      <StepContent isShowing={step === getStep('practice-energy-based-devices')}>
        <PracticeEnergyBasedDevices
          defaultValues={getSurveyAnswers({
            surveyQuestions,
            sectionId: 'practice-energy-based-devices',
          })}
          onBack={onBack}
          onContinue={async (values) => {
            // update survey
            await onUpdateSurvey({
              questions: valuesToAnsweredSurveyQuestions({
                sectionId: 'practice-energy-based-devices',
                values,
              }),
            });

            // update practice
            // - practice object in backend takes in a devices array
            const devicesArr: Practice['devices'] = [];
            Object.entries(values).forEach(([key, val]) => {
              val.fields?.forEach((field) => {
                devicesArr.push({
                  type: key,
                  brand: field.brandName,
                });
              });
            });
            await onUpdateProvider({
              data: { devices: devicesArr },
            });
            onContinue();
          }}
        />
      </StepContent>
      <StepContent isShowing={step === getStep('practice-promotions')}>
        <PracticePromotions
          onBack={onBack}
          onContinue={() => {
            onContinue();
          }}
        />
      </StepContent>
      {/* ChemicalPeelOffers wont be in MVP, TODO: will bring this back later, and when this is brought back - remember to update steps accordingly
{/*      <StepContent isShowing={step === 9}>
        <ChemicalPeelOffers
          onBack={onBack}
          onContinue={(data) => {
            // TODO: handle data (ie: store in state or call api?)
            console.log(data);
            onContinue();
          }}
        />
      </StepContent>*/}
      {/* SkinTypes wont be in MVP, TODO: will bring this back later, and when this is brought back - remember to update steps accordingly
      <StepContent isShowing={step === 10}>
        <SkinTypes
          onBack={onBack}
          onContinue={(data) => {
            // TODO: handle data (ie: store in state or call api?)
            console.log(data);
            onContinue();
          }}
        />
      </StepContent>
      */}
      <StepContent isShowing={step === getStep('scheduling-method')}>
        <SchedulingMethod
          defaultValues={{
            ...getSurveyAnswers({
              surveyQuestions,
              sectionId: 'scheduling-method',
            }),
            phoneNumForScheduling: loggedInProvider.phone,
          }}
          onBack={onBack}
          onContinue={async (values) => {
            // updating survey
            await onUpdateSurvey({
              questions: valuesToAnsweredSurveyQuestions({
                sectionId: 'scheduling-method',
                values,
              }),
            });

            // updating practice
            const schedulingMethods: Practice['schedulingMethods'] = [];
            if (values.isOnlinePortalChecked && values.onlinePortalUrl) {
              schedulingMethods.push({
                type: 'onlinePortal',
                value: values.onlinePortalUrl,
              });
            }
            if (values.isPhoneSchedulingChecked && values.phoneNumForScheduling) {
              schedulingMethods.push({
                type: 'phone',
                value: values.phoneNumForScheduling,
              });
            }
            await onUpdateProvider({
              data: { schedulingMethods },
            });
            onContinue();
          }}
        />
      </StepContent>
      <StepContent isShowing={step === getStep('your-superpowers')}>
        <YourSuperpowers
          defaultValues={getSurveyAnswers({
            surveyQuestions,
            sectionId: 'your-superpowers',
          })}
          onBack={onBack}
          onContinue={async (values) => {
            await onUpdateSurvey({
              questions: valuesToAnsweredSurveyQuestions({
                sectionId: 'your-superpowers',
                values,
              }),
            });
            await onUpdateProvider({
              data: {
                superPower: values.specificTreatments,
              },
            });
            onContinue();
          }}
        />
      </StepContent>
      <StepContent isShowing={step === getStep('practice-languages')}>
        <ProviderLanguages
          defaultValues={getSurveyAnswers({
            surveyQuestions,
            sectionId: 'practice-languages',
          })}
          onBack={onBack}
          onContinue={async (values) => {
            // update survey
            await onUpdateSurvey({
              questions: valuesToAnsweredSurveyQuestions({
                sectionId: 'practice-languages',
                values,
              }),
            });

            // update practice languages if selected or set to english by default
            await onUpdateProvider({
              data: {
                languages:
                  values.languages && values.languages.length > 0
                    ? values.languages.map((language) => language.id)
                    : ['english'],
              },
            });
            onContinue();
          }}
        />
      </StepContent>
      <StepContent isShowing={step === getStep('lets-learn-about-your-practice')}>
        <LetsLearnAboutYourPractice
          defaultValues={getSurveyAnswers({
            surveyQuestions,
            sectionId: 'lets-learn-about-your-practice',
          })}
          onBack={onBack}
          onContinue={async (values) => {
            await onUpdateSurvey({
              questions: valuesToAnsweredSurveyQuestions({
                sectionId: 'lets-learn-about-your-practice',
                values,
              }),
              ...((loggedInProvider.type !== 'DERMATOLOGY' ||
                loggedInProvider.survey?.state === 'done') && {
                state: 'done',
              }),
            });

            // update practice
            const offeringsWithoutId: Omit<Offering, 'id'>[] = [];

            values.facialPeelSunscreenTypes.forEach((x) => {
              if (x.facialPeelType) {
                offeringsWithoutId.push({
                  type: 'FACIAL',
                  name: x.facialPeelType,
                  price: x.cost,
                });
              }
              if (x.sunscreenType) {
                offeringsWithoutId.push({
                  type: 'SUNSCREEN',
                  name: x.sunscreenType,
                  price: x.cost,
                });
              }
            });

            const systemsArr: PracticeSystem[] = [];
            Object.entries(values.selectedEmrPmrSchedulingSystems).forEach((entry) => {
              const key = entry[0];
              const val = entry[1];

              // if just boolean
              if (typeof val === 'boolean' && val) {
                systemsArr.push({ id: key });
              }

              // if is {isChecked: boolean, specifyText: string}
              if (typeof val !== 'boolean' && val && val.isChecked) {
                systemsArr.push({ id: key, name: val.specifyText });
              }
            });

            await onUpdateProvider({
              data: {
                ...(values.privateLabelDiscount && {
                  privateLabelDiscount: labelDiscountToNumber(values.privateLabelDiscount),
                }),
                ...(values.brandLabelDiscount && {
                  brandLabelDiscount: labelDiscountToNumber(values.brandLabelDiscount),
                }),
                offerings: offeringsWithoutId,
                systems: systemsArr,
              },
              overrideOfferingsWithoutId: true,
            });
            onContinue();
          }}
        />
      </StepContent>
      <StepContent isShowing={step === getStep('insurance-details') && isDermatology}>
        <InsuranceDetails
          onBack={onBack}
          onContinue={async () => {
            if (loggedInProvider.survey?.state !== 'done') {
              await onUpdateSurvey({ state: 'done' });
            }
            onContinue();
          }}
        />
      </StepContent>
    </Body>
  );
};

// backend takes in percent here as a whole number (ie: 35% -> 35)
const labelDiscountToNumber = (percentStr: string) => {
  if (percentStr === 'None') return 0;
  const wholeNumber = parseFloat(percentStr);
  return wholeNumber;
};

// does not render section when not in view (isShowing)
const StepContent = (props: PropsWithChildren<{ isShowing: boolean }>) => {
  if (!props.isShowing) {
    return null;
  }
  return <>{props.children}</>;
};

const getOfferingsWithoutName = (offerings: Offering[]): Omit<Offering, 'name'>[] => {
  return offerings.map((x) => {
    const { name, ...rest } = x;
    return rest;
  });
};

const Body = styled(Box)`
  min-height: calc(100svh - var(--app-header-height));
  display: flex;
  flex-direction: column;
  background: #171c17;
`;
