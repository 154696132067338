import { StepContainer } from '../../StepContainer';
import { AddableDeviceFields, DeviceFieldsData } from './components/AddableDeviceFields';
import { SurveySectionFields } from '@/scenes/Onboarding/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FieldErrors, UseFormProps, useForm } from 'react-hook-form';
import * as yup from 'yup';

type Props = {
  defaultValues?: Partial<FormInputs>;
  onBack: () => void;
  onContinue: (data: FormInputs) => Promise<void>;
};

const DEFAULT_VAL = { isChecked: false };
const DEVICE_TYPE_CONST = [
  { label: 'Laser hair removal', key: 'LASER_HAIR_REMOVAL' },
  { label: 'Resurfacing', key: 'RESURFACING' },
  { label: 'Skin tightening', key: 'SKIN_TIGHTENING' },
  { label: 'Body contouring', key: 'BODY_CONTOURING' },
  { label: 'Vascular lesion', key: 'VASCULAR_LESION' },
  { label: 'Hyperpigmentation', key: 'HYPERPIGMENTATION' },
  { label: 'Tattoo removal', key: 'TATTOO_REMOVAL' },
] as const;
export const DEVICE_TYPES = [...DEVICE_TYPE_CONST];
export type deviceTypesKey = (typeof DEVICE_TYPES)[number]['key'];

// FIELDS
// IMPORTANT: treat this as a contract, this determines which values gets passed up to the backend
const generateFields = () => {
  const fields = {} as SurveySectionFields<FormInputs>;
  DEVICE_TYPES.forEach((dt) => {
    fields[dt.key] = {
      label: dt.label,
      type: 'json',
      example: {
        isChecked: true,
        fields: [{ brandName: 'Brand name' }],
      },
    };
  });
  return fields;
};
const FIELDS = generateFields();
export { FIELDS as PracticeEnergyBasedDevicesFields };

export const PracticeEnergyBasedDevices = (props: Props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = usePracticeEnergyForm({
    defaultValues: {
      LASER_HAIR_REMOVAL: DEFAULT_VAL,
      RESURFACING: DEFAULT_VAL,
      SKIN_TIGHTENING: DEFAULT_VAL,
      BODY_CONTOURING: DEFAULT_VAL,
      VASCULAR_LESION: DEFAULT_VAL,
      HYPERPIGMENTATION: DEFAULT_VAL,
      TATTOO_REMOVAL: DEFAULT_VAL,
      ...props.defaultValues,
    },
  });

  const onSubmit = async (data: FormInputs) => {
    await props.onContinue(data);
  };

  return (
    <StepContainer
      heading="Practice energy-based devices"
      description="Please provide information about your equipment. Specify the brand name."
      onBack={props.onBack}
      onContinue={() => handleSubmit(onSubmit)()}
    >
      {DEVICE_TYPES.map((dt) => {
        const key = dt.key as deviceTypesKey;
        return (
          <Controller
            key={key}
            control={control}
            name={key}
            render={({ field }) => {
              return (
                <AddableDeviceFields
                  label={dt.label}
                  value={field.value}
                  onChange={field.onChange}
                  errors={errors[key] as FieldErrors<DeviceFieldsData>}
                />
              );
            }}
          />
        );
      })}
    </StepContainer>
  );
};
PracticeEnergyBasedDevices.defaultProps = {
  onBack: () => {},
  onContinue: async () => {},
};

// form fields
// IMPORTANT: treat this as a contract, this is how values are read from the backend (see defaultValues prop)
type FormInputs = {
  LASER_HAIR_REMOVAL: DeviceFieldsData;
  RESURFACING: DeviceFieldsData;
  SKIN_TIGHTENING: DeviceFieldsData;
  BODY_CONTOURING: DeviceFieldsData;
  VASCULAR_LESION: DeviceFieldsData;
  HYPERPIGMENTATION: DeviceFieldsData;
  TATTOO_REMOVAL: DeviceFieldsData;
};

const addableFieldYup = () =>
  yup.object({
    isChecked: yup.boolean().required(),
    fields: yup
      .array(
        yup
          .object({
            brandName: yup.string(),
          })
          .default(undefined)
          .notRequired(),
      )
      .when('isChecked', {
        is: true,
        then: yup.array(
          yup.object({
            brandName: yup.string().required(),
          }),
        ),
      }),
  });

const usePracticeEnergyForm = (props?: UseFormProps<FormInputs>) => {
  let defaultValues = props?.defaultValues;
  const validationSchema: yup.SchemaOf<FormInputs> = yup.object({
    LASER_HAIR_REMOVAL: addableFieldYup(),
    RESURFACING: addableFieldYup(),
    SKIN_TIGHTENING: addableFieldYup(),
    BODY_CONTOURING: addableFieldYup(),
    VASCULAR_LESION: addableFieldYup(),
    HYPERPIGMENTATION: addableFieldYup(),
    TATTOO_REMOVAL: addableFieldYup(),
  });

  return useForm<FormInputs>({
    ...props,
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });
};
