import { Insurance } from '../types';
import { TrashIcon } from '@/components/UIKit/Icons';
import useBreakpointCheck from '@/hooks/useBreakpointCheck';
import { ChevronLeft } from '@mui/icons-material';
import { Box, IconButton, Skeleton, Typography, styled } from '@mui/material';
import { useState } from 'react';

type Props = {
  insurances: Insurance[];
  onInsuranceChange: (insurance: Insurance) => void;
  isLoading?: boolean;
};
export const SelectedSection = (props: Props) => {
  const [focusedInsuranceId, setFocusedInsuranceId] = useState<string | null>(null);
  const focusedInsurance = props.insurances.find((ins) => ins.id === focusedInsuranceId);
  const isTablet = useBreakpointCheck('tablet');

  const renderContent = () => {
    if (!isTablet) {
      return (
        <>
          <InsuranceList
            {...props}
            focusedInsuranceId={focusedInsuranceId}
            onSelectInsurance={setFocusedInsuranceId}
          />
          <Box sx={{ background: 'rgba(233, 235, 237, 1)' }} width="1px" height="100%" />
          <SelectedInsurance
            isTablet={false}
            onBack={() => setFocusedInsuranceId(null)}
            focusedInsurance={focusedInsurance}
            onInsuranceChange={props.onInsuranceChange}
          />
        </>
      );
    }

    if (focusedInsuranceId) {
      return (
        <SelectedInsurance
          isTablet
          onBack={() => setFocusedInsuranceId(null)}
          focusedInsurance={focusedInsurance}
          onInsuranceChange={props.onInsuranceChange}
        />
      );
    }

    return (
      <InsuranceList
        {...props}
        focusedInsuranceId={focusedInsuranceId}
        onSelectInsurance={setFocusedInsuranceId}
      />
    );
  };

  return (
    <Body>
      <Box display="flex" gap="24px" flex={1}>
        {renderContent()}
      </Box>
    </Body>
  );
};

type ListProps = Props & {
  focusedInsuranceId: string | null;
  onSelectInsurance: (id: string) => void;
};
const InsuranceList = (props: ListProps) => {
  return (
    <Box flex={1}>
      <Typography variant="h4" mb="24px">
        Selected
      </Typography>
      {props.isLoading ? (
        <LoadingSkeleton />
      ) : (
        props.insurances.map((x) => {
          const isFocused = props.focusedInsuranceId === x.id;
          return (
            <InsuranceCard
              key={x.id}
              isFocused={isFocused}
              onClick={() => props.onSelectInsurance(x.id)}
            >
              <Box display="flex" justifyContent="space-between" gap="12px">
                <Box>
                  <Typography variant="body2" mb="2px">
                    {x.name}
                  </Typography>
                  <Typography variant="body3" color="text.secondary">
                    {x.plans.length} plans selected
                  </Typography>
                </Box>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    const newInsurance = { ...x, plans: [] };
                    props.onInsuranceChange(newInsurance);
                  }}
                >
                  <TrashIcon />
                </IconButton>
              </Box>
            </InsuranceCard>
          );
        })
      )}
    </Box>
  );
};

type SelectedProps = {
  focusedInsurance?: Insurance;
  onInsuranceChange: (insurance: Insurance) => void;
  isTablet: boolean;
  onBack: () => void;
};
const SelectedInsurance = (props: SelectedProps) => {
  const { focusedInsurance, onInsuranceChange, isTablet, onBack } = props;

  return (
    <Box flex={1}>
      {isTablet && (
        <Box mb="24px" display="flex" alignItems="flex-start" justifyContent="space-between">
          <Box display="flex" alignItems="flex-start" gap="8px">
            <IconButton onClick={onBack}>
              <ChevronLeft />
            </IconButton>
            <Box>
              {/*
              line-height, font, and button padding make these not
              align properly, so we're using a negative margin to compensate
            */}
              <Typography mt="-2px" variant="h4">
                {focusedInsurance!.name}
              </Typography>
              <Typography variant="body3">
                {focusedInsurance!.plans.length} plans selected
              </Typography>
            </Box>
          </Box>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              const newInsurance = { ...focusedInsurance!, plans: [] };
              props.onInsuranceChange(newInsurance);
              onBack();
            }}
          >
            <TrashIcon />
          </IconButton>
        </Box>
      )}
      {props.focusedInsurance?.plans.map((x, index) => {
        return (
          <InsurancePlan key={x.id}>
            <Typography variant="body2">{x.name}</Typography>
            <IconButton
              onClick={() => {
                // remove plan from focused insurance
                const newPlans = [...focusedInsurance!.plans];
                newPlans.splice(index, 1);
                const newInsurance = {
                  ...focusedInsurance!,
                  plans: newPlans,
                };

                onInsuranceChange(newInsurance);
              }}
            >
              <TrashIcon />
            </IconButton>
          </InsurancePlan>
        );
      })}
    </Box>
  );
};

const Body = styled(Box)`
  border: 1px solid #e9ebed;
  padding: 24px 20px;
  border-radius: 12px;
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const InsuranceCard = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isFocused',
})<{ isFocused?: boolean }>`
  cursor: pointer;
  border-radius: 12px;
  border: 1px solid #e9ebed;
  padding: 10px 16px;
  margin-bottom: 8px;

  ${(props) =>
    props.isFocused &&
    `
    border: 1px solid #FFD700;
    box-shadow: 0px 0px 0px 3px rgba(255, 217, 0, 0.16);
  `}
`;

const InsurancePlan = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 12px 0px;
  border-bottom: 0.5px solid rgba(233, 235, 237, 1);
  :last-of-type {
    border-bottom: 0px;
  }
`;

const LoadingSkeleton = () => {
  return (
    <Box pb="20px">
      {Array(3)
        .fill(null)
        .map((_, i) => (
          <Skeleton
            key={i}
            variant="rectangular"
            height={67}
            sx={{ my: '8px', borderRadius: '12px' }}
            width="100%"
          />
        ))}
    </Box>
  );
};
