import { Components, Theme } from '@mui/material';

const layout = (theme: Theme): Components<Theme> => ({
  MuiContainer: {
    styleOverrides: {
      root: {
        '&&': {
          paddingLeft: '40px',
          paddingRight: '40px',
        },
      },
    },
    defaultProps: {
      maxWidth: 'lg', // based on theme.breakpoints
    },
  },
});

export { layout };
