import { FormRow } from '@/components/UIKit/Form/styled';
import { TrashIcon } from '@/components/UIKit/Icons';
import { TextField } from '@/components/UIKit/Inputs/TextField/TextField';
import { DialogCloseBtn } from '@/components/UIKit/Modal/misc';
import useOpenable from '@/hooks/useOpenable';
import { AddMoreButton } from '@/scenes/Onboarding/components/UI/buttons';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { useState } from 'react';
import { UseFormProps, useFieldArray, useForm } from 'react-hook-form';
import * as yup from 'yup';

type Props = {
  onSubmit: (values: FormInputs) => Promise<void>;
  onClose: () => void;
};
const DEFAULT_PLAN_TYPE_FIELD: FormInputs['planTypes'][0] = {
  name: '',
};
export const NewInsuranceRequestModalView = (props: Props) => {
  const { control, register, handleSubmit } = useNewInsuranceRequestForm({
    defaultValues: {
      planTypes: [DEFAULT_PLAN_TYPE_FIELD],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'planTypes',
  });

  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onClose } = useOpenable({
    shouldStartOpen: true,
    isOnCloseDisabled: isLoading,
  });

  const onSubmit = async (values: FormInputs) => {
    setIsLoading(true);
    try {
      await props.onSubmit(values);
    } catch (e: any) {
      throw Error(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      onTransitionExited={props.onClose}
      sx={{
        '.MuiDialog-paper': {
          maxWidth: '700px',
          width: '100%',
        },
      }}
    >
      <DialogCloseBtn onClick={onClose} disabled={isLoading} />
      <DialogTitle sx={{ borderBottom: '0px', pb: '24px' }}>
        Tell us about your insurance?
      </DialogTitle>
      <DialogContent>
        <Alert severity="info" sx={{ mb: '24px' }}>
          Our team will review your insurance. We will notify you when you can add it to your list.
        </Alert>
        <FormRow>
          <TextField
            label="Insurance carrier"
            control={control}
            register={register('insuranceCarrier')}
            placeholder="Enter"
          />
        </FormRow>
        {fields.map((field, index) => {
          return (
            <FormRow key={field.id}>
              <TextField
                label="Plan type"
                control={control}
                register={register(`planTypes.${index}.name`)}
                placeholder="Enter"
                sx={{
                  input: { borderRight: '0px' },
                }}
                {...(index !== 0 && {
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => remove(index)}>
                          <TrashIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  },
                })}
              />
            </FormRow>
          );
        })}
        <AddMoreButton btnText="Add plan type" onClick={() => append(DEFAULT_PLAN_TYPE_FIELD)} />
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          loading={isLoading}
          onClick={() => handleSubmit(onSubmit)()}
          variant="contained"
        >
          Send
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

// form fields
type FormInputs = {
  insuranceCarrier: string;
  planTypes: { name: string }[];
};
export type { FormInputs as NewInsuranceRequestInputs };

const useNewInsuranceRequestForm = (props?: UseFormProps<FormInputs>) => {
  let defaultValues = props?.defaultValues;
  const validationSchema: yup.SchemaOf<FormInputs> = yup.object({
    insuranceCarrier: yup.string().required(),
    planTypes: yup.array(
      yup.object({
        name: yup.string().required(),
      }),
    ),
  });

  return useForm<FormInputs>({
    ...props,
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });
};
