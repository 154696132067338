import { getCroppedImg } from './utils';
import { snackIt } from '@/components/SnackbarManager';
import { DialogCloseBtn } from '@/components/UIKit/Modal/misc';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Slider,
  Typography,
  styled,
} from '@mui/material';
import { useState } from 'react';
import Cropper from 'react-easy-crop';

type CropType = { width: number; height: number; x: number; y: number };
type Props = {
  onClose: () => void;
  onSave: (file: File) => Promise<void>;
  imageSrc: string;
  minimumPixels?: number;
};
export const ImageCropperModal = (props: Props) => {
  const { minimumPixels = 0 } = props;

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<CropType | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  const onCropComplete = (_croppedArea: any, croppedAreaPixels: CropType) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const cropImage = async () => {
    if (!croppedAreaPixels) {
      cropErrMessage();
      throw Error('expected croppedAreaPixels');
    }

    try {
      const croppedImage = await getCroppedImg(props.imageSrc, croppedAreaPixels, rotation);
      const file = new File([croppedImage.blob], 'name', {
        type: 'image/jpeg',
      });
      return file;
    } catch (e: any) {
      cropErrMessage();
      throw Error(e);
    }
  };

  const onSaveClick = async () => {
    const file = await cropImage();
    try {
      setIsSaving(true);
      await props.onSave(file);
    } catch (e: any) {
      throw Error(e);
    } finally {
      setIsSaving(false);
    }
  };

  const onClose = () => {
    if (isSaving) return;
    props.onClose();
  };

  // validation
  const isSmallerThanMinimumPixels =
    (croppedAreaPixels?.width && croppedAreaPixels.width < minimumPixels) ||
    (croppedAreaPixels?.height && croppedAreaPixels.height < minimumPixels);
  let invalidMessage = null;
  if (isSmallerThanMinimumPixels) {
    invalidMessage = `Image must be larger than ${minimumPixels}x${minimumPixels} pixels`;
  }

  return (
    <CropperDialog open onClose={onClose}>
      <DialogCloseBtn onClick={onClose} />
      <DialogTitle sx={{ pb: '24px', borderBottom: '0px' }}>Edit logo</DialogTitle>
      <DialogContent sx={{ pb: '0px', pt: '0px' }}>
        <CropperContainer>
          <Cropper
            image={props.imageSrc}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            aspect={1 / 1}
            restrictPosition
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape="round"
            style={{
              containerStyle: { borderRadius: 'inherit' },
            }}
          />
        </CropperContainer>
        <Box display="flex" gap="24px">
          <Box flex={1}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body2Emphasized">Zoom</Typography>
              <Typography variant="body2Medium">{zoom}</Typography>
            </Box>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e, zoom) => {
                if (typeof zoom === 'number') {
                  setZoom(zoom);
                }
              }}
            />
          </Box>
          <Box flex={1}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="body2Emphasized">Rotate</Typography>
              <Typography variant="body2Medium">{rotation}</Typography>
            </Box>
            <Slider
              value={rotation}
              min={0}
              max={360}
              step={1}
              aria-labelledby="Rotation"
              onChange={(e, rotation) => {
                if (typeof rotation === 'number') {
                  setRotation(rotation);
                }
              }}
            />
          </Box>
        </Box>
        {invalidMessage && <FormHelperText error>{invalidMessage}</FormHelperText>}
      </DialogContent>
      <DialogActions sx={{ borderTop: '0px', pt: '24px' }}>
        <Box>
          <Button onClick={onClose} fullWidth variant="text">
            Cancel
          </Button>
        </Box>
        <Box>
          <LoadingButton
            onClick={onSaveClick}
            variant="contained"
            color="secondary"
            loading={isSaving}
            fullWidth
            disabled={!!invalidMessage}
          >
            Save
          </LoadingButton>
        </Box>
      </DialogActions>
    </CropperDialog>
  );
};

const CropperDialog = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 700px;
    width: 100%;
  }
`;

const CropperContainer = styled(Box)`
  position: relative;
  width: 100%;
  height: 366px;
  background: #333;
  margin-bottom: 24px;
  border-radius: 8px;
`;

const cropErrMessage = () =>
  snackIt.default({
    message: 'Error, could not crop image',
    severity: 'error',
  });
