import { fetchAuthSession } from './components/GlobalContext/utils/auth';
import config from './config';
import { Amplify, type ResourcesConfig } from 'aws-amplify';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { defaultStorage } from 'aws-amplify/utils';
import axios from 'axios';
import React from 'react';
import { BrowserRouter, HashRouter } from 'react-router-dom';

// -- Rollbar (NOT USING) --
// NOTE: not using rollbar in this project
// import './rollbar';

// -- Auth --
const authConfig: ResourcesConfig['Auth'] = {
  Cognito: {
    userPoolId: config.cognitoUserPoolId,
    userPoolClientId: config.cognitoWebClientId,
  },
};

Amplify.configure({
  Auth: authConfig,
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);

// -- Fetching API --
axios.defaults.baseURL = config.restApiEndpoint;
axios.interceptors.request.use(async function (config) {
  // refresh access token (if its expired) on api call (before its sent out to the backend)
  // - this is in case their access token expires while using the app, seems to be after an hour
  const { tokens } = await fetchAuthSession();
  const bearerToken = tokens.accessToken.toString();
  axios.defaults.headers.common['Authorization'] = `Bearer ${bearerToken}`;

  return config;
});
axios.interceptors.response.use(
  async function (response) {
    return response;
  },
  async function (error) {
    if (401 === error.response.status) {
      await fetchAuthSession({ forceRefresh: true }); // access token might have been revoked, attempt a token refresh
    }
    return Promise.reject(error); // should always return this so axios gets the correct error
  },
);

// -- Router --
// Hash router used for PR builds since we only serve static files.
// https://create-react-app.dev/docs/deployment/#serving-apps-with-client-side-routing
export const Router: React.ElementType = config.isStaticApp ? HashRouter : BrowserRouter;
