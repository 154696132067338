import { SelectProvidersStepView } from './View';
import { GlobalContext } from '@/components/GlobalContext';
import { GetProvidersResponse } from '@/types/apiContract/provider';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useContext } from 'react';

export const SelectProvidersStepContainer = () => {
  const { loggedInProvider } = useContext(GlobalContext);

  // fetch list
  const providersQuery = useQuery({
    queryKey: ['providers', loggedInProvider.id],
    queryFn: async () => {
      return axios.get<GetProvidersResponse>(`/practices/${loggedInProvider.id}/providers`);
    },
    throwOnError: true, // will trigger react error boundary
  });
  const providers = providersQuery.data?.data || [];

  const onAddProviderComplete = async () => {
    await providersQuery.refetch();
  };

  return (
    <SelectProvidersStepView providers={providers} onAddProviderComplete={onAddProviderComplete} />
  );
};
