import { TextField } from './TextField';
import { formatPhoneNum } from '@/utils/formatting';
import { isPhoneNum } from '@/utils/validation';
import { InputAdornment } from '@mui/material';
import { ComponentProps } from 'react';

const INTL_CODE = '+1';

export const PhoneTextField = (props: ComponentProps<typeof TextField>) => (
  <TextField
    onChangeFilter={(val, prevVal) => {
      // only allow phone number characters
      const isPhoneNumChar = val.match(/[0-9\-()+\s]+$/); // /[0-9\-\(\)\+\s]+$/
      const isEmpty = val === '';
      if (!isPhoneNumChar && !isEmpty) {
        return prevVal;
      }

      // strip off INTL_CODE in the beginning (since its already in startAdornment)
      const newVal = val.startsWith(INTL_CODE) ? val.slice(INTL_CODE.length) : val;

      // format if valid phone number
      const formattedPhoneVal = formatPhoneNum(newVal, {
        autoIntlCode: false,
      });
      if (isPhoneNum(formattedPhoneVal)) {
        return formattedPhoneVal;
      }
      return newVal;
    }}
    type="tel"
    {...props}
    InputProps={{
      startAdornment: <InputAdornment position="start">{INTL_CODE}</InputAdornment>,
      ...props.InputProps,
    }}
  />
);
