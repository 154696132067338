import { styled } from '@mui/material';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { isAfter, isBefore, isSameDay } from 'date-fns';

type Props = PickersDayProps<Date> & {
  startDate?: Date | null;
  endDate?: Date | null;
};

function Day(props: Props) {
  const { day, startDate, endDate, ...pickersDayProps } = props;

  const dayIsBetween =
    startDate && endDate ? isAfter(day, startDate) && isBefore(day, endDate) : false;
  const isFirstDay = startDate ? isSameDay(day, startDate) : false;
  const isLastDay = endDate ? isSameDay(day, endDate) : false;

  const classes: string[] = [];
  if (isFirstDay) classes.push('is-first-day');
  if (isLastDay) classes.push('is-last-day');
  const classStr = `${classes.join(' ')} ${pickersDayProps.className ?? ''}`;

  return (
    <CustomPickersDay
      {...pickersDayProps}
      className={classStr}
      day={day}
      dayIsBetween={dayIsBetween}
      isFirstDay={isFirstDay}
      isLastDay={isLastDay}
      sx={dayIsBetween ? { px: 2.5, mx: 0 } : {}}
    />
  );
}

interface CustomPickerDayProps extends PickersDayProps<Date> {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
}

// For some elements, we need to increase the specificity to override the default styles.
const overrideMuiPickersDaySelector = '.MuiPickersDay-root.MuiPickersDay-root.MuiPickersDay-root';

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    !['dayIsBetween', 'isFirstDay', 'isLastDay'].includes(prop.toString()),
})<CustomPickerDayProps>`
  ${(props) =>
    props.dayIsBetween &&
    `
    &, &.MuiPickersDay-root.Mui-selected, &.MuiPickersDay-root.Mui-selected:focus {
      border-radius: 0;
      background-color: ${props.theme.palette.grey[100]};
      &:hover, &:focus {
        background-color: ${props.theme.palette.grey[100]};
      }
    }
  `}

  ${(props) =>
    (props.isFirstDay || props.isLastDay) &&
    `
    &${overrideMuiPickersDaySelector} {
      border-radius: 50%;
      background-color: ${props.theme.palette.primary.main};
      color: #fff;

      &:hover, &:focus {
        background-color: ${props.theme.palette.primary.main};
        color: #fff;
      }

      &:after {
        content: '';
        height: 100%;
        width: 100%;
        background: transparent;
        position: absolute;
        top: 0;
        z-index: -1;
      }
    }
  `}

  ${(props) =>
    props.isFirstDay &&
    !props.isLastDay &&
    `
    &${overrideMuiPickersDaySelector}:after {
      background: ${props.theme.palette.grey[100]};
      left: 2px;
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }
  `}

  ${(props) =>
    props.isLastDay &&
    !props.isFirstDay &&
    `
    &${overrideMuiPickersDaySelector}:after {
      background: ${props.theme.palette.grey[100]};
      left: -2px;
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    }
  `}
` as React.ComponentType<CustomPickerDayProps>;

export { Day };
