import { StepContainer } from '../../StepContainer';
import { FormRow } from '@/components/UIKit/Form/styled';
import { TextField } from '@/components/UIKit/Inputs/TextField/TextField';
import { SurveySectionFields } from '@/scenes/Onboarding/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { UseFormProps, useForm } from 'react-hook-form';
import * as yup from 'yup';

type Props = {
  defaultValues?: Partial<FormInputs>;
  onBack: () => void;
  onContinue: (data: FormInputs) => Promise<void>;
};

// FIELDS
// IMPORTANT: treat this as a contract, this determines which values gets passed up to the backend
export const FIELDS: SurveySectionFields<FormInputs> = {
  specificTreatments: {
    label:
      'Are there specific treatments your practice/provider(s) are well known for within your market?',
    type: 'string',
    example: 'lorem',
  },
  top3Procedures: {
    label: 'What are the top 3 procedures you LOVE to do?',
    type: 'string',
    example: 'lorem',
  },
  highestGrossingRevenueProcedure: {
    label: 'What is the highest grossing revenue procedure in your practice?',
    type: 'string',
    example: 'lorem',
  },
};
export { FIELDS as YourSuperpowersFields };

export const YourSuperpowers = (props: Props) => {
  const { control, handleSubmit, register } = useYourSuperpowersForm({
    defaultValues: props.defaultValues,
  });

  const onSubmit = async (data: FormInputs) => {
    await props.onContinue(data);
  };

  return (
    <StepContainer
      heading="Your superpowers"
      description="Take a minute to think about what you really LOVE to do (i.e. reshaping, body contouring, jawline...)."
      onBack={props.onBack}
      onContinue={() => handleSubmit(onSubmit)()}
    >
      <FormRow>
        <TextField
          label={FIELDS['specificTreatments'].label}
          placeholder="Specify any details such as which provider or location so we can help match the right patients for you"
          control={control}
          register={register('specificTreatments')}
          multiline
          minRows={8}
        />
      </FormRow>

      <FormRow>
        <TextField
          label={FIELDS['top3Procedures'].label}
          placeholder="Type..."
          control={control}
          register={register('top3Procedures')}
          multiline
        />
      </FormRow>
      <FormRow>
        <TextField
          label={FIELDS['highestGrossingRevenueProcedure'].label}
          placeholder="Type..."
          control={control}
          register={register('highestGrossingRevenueProcedure')}
          multiline
        />
      </FormRow>
    </StepContainer>
  );
};
YourSuperpowers.defaultProps = {
  onBack: () => {},
  onContinue: async () => {},
};

// form fields
// IMPORTANT: treat this as a contract, this is how values are read from the backend (see defaultValues prop)
type FormInputs = {
  specificTreatments: string;
  top3Procedures: string;
  highestGrossingRevenueProcedure: string;
};

const useYourSuperpowersForm = (props?: UseFormProps<FormInputs>) => {
  let defaultValues = props?.defaultValues;
  const validationSchema: yup.SchemaOf<FormInputs> = yup.object({
    specificTreatments: yup.string().required(),
    top3Procedures: yup.string().required(),
    highestGrossingRevenueProcedure: yup.string().required(),
  });

  return useForm<FormInputs>({
    ...props,
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });
};
