import { CheckoutView } from './View';
import { GlobalContext } from '@/components/GlobalContext';
import { LoadingScreen } from '@/components/GlobalContext/components/LoadingScreen';
import { Result } from '@/components/PageResult/Result';
import { GetVisitReasonsResponse, GetVisitsResponse } from '@/types/apiContract/visit';
import { useQueries } from '@tanstack/react-query';
import axios from 'axios';
import { useContext } from 'react';
import React from 'react';
import { useParams } from 'react-router-dom';

export const CheckoutContainer: React.FC = () => {
  const { loggedInProvider } = useContext(GlobalContext);
  const { memberId } = useParams<{ memberId: string }>();

  // get member details + get current visits + visit reasons
  const [memberQuery, visitsQuery, visitReasonsQuery] = useQueries({
    queries: [
      {
        queryKey: ['member', memberId],
        queryFn: () => axios.get(`/practices/${loggedInProvider?.id}/members/${memberId}`),
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['visits', memberId],
        queryFn: () =>
          axios.get<GetVisitsResponse>(
            `/practices/${loggedInProvider?.id}/members/${memberId}/visits`,
          ),
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['visits/reasons'],
        queryFn: () => axios.get<GetVisitReasonsResponse>(`/practices/visits/reasons`),
        refetchOnWindowFocus: false,
      },
    ],
  });
  const member = memberQuery.data?.data;
  const visits = [...(visitsQuery.data?.data || [])].slice().reverse(); // reversing because api returns sorted by oldest first
  const visitReasons = visitReasonsQuery.data?.data || [];

  const onNewVisitComplete = async () => {
    await Promise.all([memberQuery.refetch(), visitsQuery.refetch()]);
  };

  const isLoading = memberQuery.isLoading || visitsQuery.isLoading;

  if (
    (!memberQuery.isLoading && !memberQuery.data) ||
    (!visitsQuery.isLoading && !visitsQuery.data)
  ) {
    return <Result title="404" />;
  }

  // ensure that re-render will happen only once after re-fetching both queries
  if (memberQuery.isRefetching || visitsQuery.isRefetching) {
    return <LoadingScreen details="..." />;
  }

  return (
    <CheckoutView
      visits={visits}
      member={member}
      isLoading={isLoading}
      onNewVisitComplete={onNewVisitComplete}
      visitReasons={visitReasons}
    />
  );
};
