import { SxProps } from '@mui/system';

export const shadowedTextFieldsSx: SxProps = {
  '.MuiOutlinedInput-root': {
    '.MuiOutlinedInput-notchedOutline': {
      boxShadow: '0px 2px 16px 0px rgba(181, 176, 168, 0.1)',
    },
    ':not(.Mui-focused, :hover, .Mui-error) .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0)',
    },
  },
};

export const borderedTextFieldsSx: SxProps = {
  '.MuiOutlinedInput-root': {
    '.MuiOutlinedInput-notchedOutline': {
      boxShadow: 'none',
    },
    ':not(.Mui-focused, :hover, .Mui-error) .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(233, 235, 237, 1)',
    },
  },
};
