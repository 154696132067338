import {
  Box,
  FormControl,
  FormHelperText,
  FormControlLabel as MuiFormControlLabel,
  RadioGroup as MuiRadioGroup,
  Radio,
  Typography,
  styled,
} from '@mui/material';
import { forwardRef, useState } from 'react';
import { FieldError } from 'react-hook-form';

type Props = {
  options: { value: string }[];
  value: string | undefined;
  onChange: (value: string | null) => void;
  error?: FieldError;
};

// a radio group that shows more options (taken from props.options) when "Yes" is selected
// - an exception: when an option with value "No" is supplied in props.options, that is its own option below "Yes" (not a part of the new set of options)
export const MultiYesOrNoRadioGroup = forwardRef((props: Props, ref) => {
  const [internalVal, setInternalVal] = useState<string | null>(props.value || null);

  // ensure valid values are being displayed (avoid typos)
  const isShowingYesOptions = internalVal && internalVal !== 'No';
  const otherOptions: { value: string }[] = [];
  const yesInnerOptions: { value: string }[] = [];
  props.options.forEach((opt) => {
    opt.value !== 'No' ? yesInnerOptions.push(opt) : otherOptions.push(opt);
  });

  const onChange = (val: string) => {
    setInternalVal(val);

    // parent component should only see their provided options when they use onChange
    if (props.options.map((opt) => opt.value).includes(val)) {
      props.onChange(val);
      return;
    }
    props.onChange(null);
  };

  return (
    <FormControl error={Boolean(props.error)} fullWidth>
      <MuiRadioGroup ref={ref} value={internalVal} onChange={(e) => onChange(e.target.value)}>
        <RadioBox
          mb="8px"
          {...(!internalVal && {
            error: Boolean(props.error),
          })}
        >
          <FormControlLabel key="Yes" value="Yes" control={<Radio />} label="Yes" />
          {isShowingYesOptions && (
            <Box display="grid" gridTemplateColumns="1fr 1fr" p="0px 20px 20px 20px" gap="8px">
              {yesInnerOptions.map((opt) => {
                let label: string | JSX.Element = opt.value;
                if (opt.value === "Moh's surgery") {
                  label = <MohsSurgeryLabel value={opt.value} />;
                }

                return (
                  <RadioBox key={opt.value} error={Boolean(props.error)}>
                    <FormControlLabel
                      key={opt.value}
                      value={opt.value}
                      control={<Radio />}
                      label={label}
                    />
                  </RadioBox>
                );
              })}
            </Box>
          )}
        </RadioBox>
        {otherOptions.map((opt) => {
          return (
            <RadioBox key={opt.value} error={Boolean(props.error)}>
              <FormControlLabel
                key={opt.value}
                value={opt.value}
                control={<Radio />}
                label={opt.value}
              />
            </RadioBox>
          );
        })}
      </MuiRadioGroup>
      {props.error?.message && (
        <FormHelperText sx={{ mt: '12px' }}>{props.error.message}</FormHelperText>
      )}
    </FormControl>
  );
});

const RadioBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'error',
})<{ error?: boolean }>`
  border: 1px solid rgba(233, 235, 237, 1);
  border-radius: 8px;
  ${(props) =>
    props.error &&
    `
    border-color: ${props.theme.palette.error.main};
  `}
`;

const FormControlLabel = styled(MuiFormControlLabel)`
  padding: 10px 20px 10px 10px;
  margin-left: 0px;
  width: 100%;
`;

const MohsSurgeryLabel = (props: { value: string }) => {
  return (
    <Box>
      <Typography variant="body3Emphasized">{props.value}</Typography>
      <Typography
        sx={{
          fontSize: '12px',
          fontWeight: 400,
          lineHeight: '22px',
          letterSpacing: '0px',
        }}
        color="text.secondary"
      >
        scheduled/referred
      </Typography>
    </Box>
  );
};
