import * as yup from 'yup';

// tests formatting for phone numbers.
export const isPhoneNum = (val: string | null | undefined) => {
  if (!val) {
    return false;
  }

  // limit to 10 - 11 digits for united states numbers
  const digitsOnly = val.replace(/\D/g, '');
  const isUnitedStatesLength = digitsOnly.length === 10 || digitsOnly.length === 11;
  if (!isUnitedStatesLength) {
    return false;
  }

  const phoneNumRegex = new RegExp(
    /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
  );

  const isValid = phoneNumRegex.test(val);
  return isValid;
};

// tests if valid URL
export const isValidUrl = (urlString?: string) => {
  if (!urlString) return false;

  try {
    yup.string().url().validateSync(urlString); // using yup here, their validator seems better (more strict) than just doing new URL(urlString)
    return true;
  } catch (e) {
    return false;
  }
};

export const hasLowerCase = (val?: string) => {
  if (!val) return false;
  return /[a-z]+/.test(val);
};
export const hasUpperCase = (val?: string) => {
  if (!val) return false;
  return /[A-Z]+/.test(val);
};

export const hasNumber = (val?: string) => {
  if (!val) return false;
  return /\d+/.test(val);
};

export const hasSpecialCharacter = (val?: string) => {
  if (!val) return false;
  return /[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`+=]/.test(val);
};

export const isJSONString = (str: string) => {
  try {
    var obj = JSON.parse(str);
    if (obj && typeof obj === 'object' && obj !== null) {
      return true;
    }
  } catch (err) {}
  return false;
};

export const isGoogleMapsUrl = (url: string | undefined) => {
  if (!url) return false;

  const googleMapsRegex = new RegExp(/^(https:\/\/)?maps\.app\.goo\.gl\/[A-Za-z0-9]+/);

  const isValid = googleMapsRegex.test(url);
  return isValid;
};
