import { LoadingSpinner } from '@/components/UIKit/Feedback/LoadingSpinner';
import { ChevronDownIcon } from '@/components/UIKit/Icons';
import { Components, Theme } from '@mui/material';

const selectMenuPropClasses = {
  paper: 'custom-MuiSelect-paper',
  list: 'custom-MuiSelect-list',
};
const inputs = (theme: Theme): Components<Theme> => ({
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        'minWidth': '80px',
        'boxShadow': 'none',
        'borderRadius': '32px',
        'padding': '8px 24px',
        'gap': '8px',
        ':hover': {
          boxShadow: 'none',
        },
        'transition': 'all 0.3s ease-in-out',
      },
      sizeSmall: {
        minWidth: '65px',
        padding: '8px 16px',
      },
      sizeMedium: {
        minWidth: '80px',
        padding: '8px 24px',
      },
      contained: {
        position: 'relative',
        overflow: 'hidden',
        zIndex: 0,
      },
      containedSizeMedium: {
        minWidth: '100px',
      },
      containedPrimary: {
        'color': theme.palette.text.primary,
        ':before': {
          background: theme.palette.primary.main,
        },
        ':hover': {
          background: theme.palette.primary.light,
        },
        ':disabled': {
          background: 'rgba(255, 215, 0, 0.56)',
          color: theme.palette.text.disabled,
        },
      },
      containedSecondary: {
        'position': 'relative', // Ensure the element is positioned for the overlay
        'zIndex': 0,
        ':hover': {
          '::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(255, 255, 255, 0.30)', // The overlay color
            zIndex: 1, // Ensure the overlay is on top
          },
        },
        ':disabled': {
          background: 'rgba(28, 27, 23, 0.56)',
          color: theme.palette.text.disabled,
        },
      },
      outlined: {
        'color': theme.palette.secondary.main,
        'borderColor': theme.palette.secondary.light,
        ':hover': {
          borderColor: theme.palette.secondary.light,
          background: theme.palette.action.hover,
        },
        ':disabled': {
          borderColor: 'rgba(0, 0, 0, 0.12)',
          color: theme.palette.text.disabled,
        },
      },
      text: {
        'padding': '0px',
        ':hover': {
          background: 'none',
          color: theme.palette.text.secondary,
        },
        ':disabled': {
          color: '#C0BDB7',
        },
      },
    },
    defaultProps: {
      color: 'secondary',
    },
  },
  MuiCheckbox: {
    defaultProps: {
      icon: <div className="MuiCheckbox-icon"></div>,
      checkedIcon: (
        <div className="MuiCheckbox-icon">
          <svg
            width="14"
            height="10"
            viewBox="0 0 14 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.2141 1.21412L5.14305 8.28519L1.92893 5.07107"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </div>
      ),
      indeterminateIcon: <div className="MuiCheckbox-icon is-partial"></div>,
    },
    styleOverrides: {
      root: {
        'borderRadius': '6px',
        'transitionDuration': '0.2s',
        'padding': '9px 12px',
        'marginTop': '-11px',
        'marginBottom': '-11px',
        '.MuiCheckbox-icon': {
          'display': 'flex',
          'alignItems': 'center',
          'justifyContent': 'center',
          'borderRadius': 'inherit',
          'boxSizing': 'border-box',
          'border': `1.5px solid #D2CEC6`,
          'height': '20px',
          'width': '20px',
          'color': theme.palette.text.primary,
          'transitionDuration': 'inherit',
          '&.is-partial': {
            'borderColor': theme.palette.primary.main,
            'background': theme.palette.primary.main,
            '&:before': {
              content: '""',
              width: '60%',
              border: '1px solid #fff',
              borderRadius: '2px',
            },
          },
        },
        ':hover': {
          'backgroundColor': 'transparent',
          '.MuiCheckbox-icon': {
            borderColor: theme.palette.primary.main,
          },
        },
        '&.Mui-checked': {
          '.MuiCheckbox-icon': {
            borderColor: theme.palette.primary.main,
            background: theme.palette.primary.main,
          },
        },
        '&.Mui-disabled': {
          '.MuiCheckbox-icon': {
            background: '#f6f6f6',
            borderColor: '#d2d2d2',
          },
          '&.Mui-checked': {
            '.MuiCheckbox-icon': {
              background: theme.palette.grey[100],
            },
          },
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        'margin': '8px 0px 0px 0px',
        '&.Mui-error': {
          ...theme.typography.body2Emphasized,
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        'color': theme.palette.text.primary,
        '&&': {
          whiteSpace: 'normal',
        },
        '&.Mui-error': {
          color: theme.palette.text.primary,
        },
        ':focus': {
          color: theme.palette.text.primary,
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        'opacity': '1',
        ':hover': {
          opacity: '0.8',
          background: 'none',
        },
        '&.MuiIconButton-colorInherit': {
          '&.Mui-disabled': {
            color: 'inherit',
            opacity: 0.4,
          },
        },
      },
      sizeMedium: {
        padding: '14px',
      },
      sizeSmall: {
        padding: '8px',
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        '&&': {
          padding: '13px 16px',
        },
      },
      inputAdornedStart: {
        borderLeft: `1px solid #E9EBED`,
      },
      inputAdornedEnd: {
        borderRight: `1px solid #E9EBED`,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        'color': theme.palette.text.primary,
        'marginTop': '0px',
        // disable fancy shrink effect and puts label above input
        '&.MuiInputLabel-shrink': {
          marginBottom: '12px',
          transform: 'none',
          position: 'static',
        },
        '&.Mui-focused': {
          color: theme.palette.text.primary,
        },
      },
    },
    defaultProps: { shrink: true }, // keep label out of input text field
  },
  MuiLoadingButton: {
    styleOverrides: {
      root: {
        '.MuiLoadingButton-loadingIndicator': {
          color: theme.palette.text.primary,
          height: '18px',
        },
        '&.MuiButton-sizeSmall': {
          '.MuiLoadingButton-loadingIndicator': {
            height: '14px',
          },
        },
      },
    },
    defaultProps: {
      loadingIndicator: <LoadingSpinner />,
    },
  },
  // MuiOutlinedInput affects form Select & TextField (this is the main field type)
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        'background': '#fff',
        'borderRadius': '8px',
        '.MuiOutlinedInput-notchedOutline': {
          boxShadow: 'none',
          borderColor: '#E9EBED',
          top: '0px',
          legend: {
            display: 'none',
          },
        },
        ':hover .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.primary.main,
        },
        '&.Mui-error .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.error.main,
          boxShadow: '0px 0px 0px 3px rgba(254, 228, 226, 0.4)',
        },
        '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
          borderColor: 'rgba(0,0,0,0.0)',
          boxShadow: 'none',
        },
        '.MuiInputAdornment-positionStart': {
          paddingRight: '6px',
          paddingLeft: '2px',
        },
        '.MuiInputAdornment-positionEnd': {
          paddingLeft: '6px',
          paddingRight: '2px',
        },
        'textarea.MuiInputBase-inputMultiline.MuiOutlinedInput-input': {
          padding: '0px',
        },
        '&.Mui-disabled': {
          background: '#ECEAE6',
          color: theme.palette.secondary.main,
          input: {
            WebkitTextFillColor: theme.palette.secondary.main,
          },
        },
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      icon: {
        transition: 'all 0.2s',
      },
    },
    defaultProps: {
      displayEmpty: true,
      IconComponent: ChevronDownIcon,
      MenuProps: {
        classes: {
          ...selectMenuPropClasses,
        },
      },
    },
  },
  MuiSlider: {
    styleOverrides: {
      thumb: {
        '::before': {
          background: '#fff',
          border: `3px solid ${theme.palette.primary.main}`,
        },
      },
      root: {
        '&&': {
          '.MuiSlider-thumb': {
            boxShadow: 'none',
          },
        },
        // START - keep thumb in inside the rail when on the edge (https://stackoverflow.com/questions/69911295/how-to-make-material-ui-slider-thumb-not-outside-of-the-rail)
        'width': 'calc(100% - 20px)',
        'left': '10px',
        '& .MuiSlider-rail': {
          transform: 'scaleX(1.07)',
          top: 'initial',
          background: '#F7F8FB',
          opacity: 1,
        },
        '& .MuiSlider-track': {
          'border': 'none',
          'backgroundColor': 'transparent',
          '&:after': {
            content: '""',
            position: 'absolute',
            width: 'calc(100% + 10px)',
            height: 'inherit',
            borderRadius: 'inherit',
            backgroundColor: theme.palette.primary.main,
            transform: 'translateX(-10px)',
          },
        },
        // END - keep thumb in inside the rail when on the edge
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      root: {
        'padding': 0,
        'margin': '12px',
        'height': '20px',
        'width': '36px',
        '.MuiSwitch-track': {
          background: '#ECEAE6',
          borderRadius: '10px',
          opacity: 1,
        },
        '.MuiSwitch-switchBase': {
          background: 'none',
          padding: 0,
        },
        '.MuiSwitch-switchBase.Mui-checked': {
          'color': '#fff',
          'transform': 'translateX(16px)',
          '& + .MuiSwitch-track': {
            opacity: 1,
            background: theme.palette.primary.main,
          },
        },
        '.MuiSwitch-thumb': {
          boxShadow: '0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)',
          width: '16px',
          height: '16px',
          margin: '2px',
        },
        '.Mui-disabled': {
          '.MuiSwitch-thumb': {
            backgroundColor: theme.palette.grey[50],
          },
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.grey[200],
            opacity: 1,
          },
          '&.Mui-checked': {
            '.MuiSwitch-thumb': {
              backgroundColor: theme.palette.primary.main,
            },
            '& + .MuiSwitch-track': {
              backgroundColor: theme.palette.primary.main,
            },
          },
        },
        '&:hover': {
          '.MuiSwitch-switchBase': {
            'background': 'none',
            '&:not(.Mui-disabled)': {
              '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.grey[200],
              },
              '&.Mui-checked + .MuiSwitch-track': {
                backgroundColor: theme.palette.primary.light,
              },
            },
          },
        },
      },
    },
  },
});

export { inputs, selectMenuPropClasses };
