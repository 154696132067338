import { OnUpdateProviderParams, OnboardingView } from './View';
import { SectionIdType, isValidSectionId } from './utils/survey';
import { GlobalContext } from '@/components/GlobalContext';
import { Offering, Practice, UpdatePracticeRequest } from '@/types/apiContract/practice';
import { UpdateSurveyRequest } from '@/types/apiContract/survey';
import { getApiErrorMessage, handleApiError, throwErrOnMessageResponse } from '@/utils/feedback';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router-dom';

type SafeUpdateSurveyRequest = UpdateSurveyRequest &
  Required<Pick<UpdateSurveyRequest, 'name' | 'questions'>>; // since this is a PUT - ensure fields like name and questions are always included so they dont get deleted
export const OnboardingContainer = () => {
  const { loggedInProvider, refetchProvider } = useContext(GlobalContext);
  let { sectionId: sectionIdFromURL } = useParams<{ sectionId: string }>();
  const navigate = useNavigate();
  const [sectionId, setSectionId] = useState<SectionIdType | undefined>(
    sectionIdFromURL as SectionIdType,
  );

  // need to have this effect to trigger re-render when sectionId or sectionIdFromURL changes
  useEffect(() => {
    if (sectionIdFromURL && sectionIdFromURL !== sectionId) {
      setSectionId(sectionIdFromURL as SectionIdType);
    }
  }, [sectionIdFromURL, sectionId]);

  // UPDATE SURVEY MUTATION
  const surveyMutation = useMutation({
    onError: handleApiError,
    onSuccess: async () => {
      await refetchProvider({ includeSurvey: true });
    },
    mutationFn: async (params: {
      questions?: UpdateSurveyRequest['questions'];
      state?: UpdateSurveyRequest['state'];
    }) => {
      // if current one exist, update it
      const currSurvey = loggedInProvider.survey;
      if (currSurvey) {
        // override the current questions (in the array) if they have the same id
        const mergedQuestionsObj: { [key: string]: any } = {};
        currSurvey.questions?.forEach((q) => {
          mergedQuestionsObj[q.meta.id] = q;
        });
        params.questions?.forEach((q) => {
          mergedQuestionsObj[q.meta.id] = q;
        });
        const mergedQuestions = Object.values(mergedQuestionsObj);

        const updatedSurvey: SafeUpdateSurveyRequest = {
          ...currSurvey,
          questions: mergedQuestions,
          ...(params.state && { state: params.state }),
        };
        try {
          await axios.put(`/legacy/api/v1/surveys/${currSurvey.id}`, updatedSurvey);
        } catch (e: any) {
          const errorFromSurvey = getApiErrorMessage(e);
          if ('invalid googleLink' === errorFromSurvey) {
            const practiceLinksSectionId = 'practice-links';
            // if error is from google Map link, redirect to practice links
            // if sectionIdFromUrl is not empty it means that survey is already done
            // and changes are made from dashboard, so navigate to path will work properly,
            // but if sectionIdFromUrl is empty, it means that survey is not done yet, and we not
            // allow direct navigation to specific section, so we need to set sectionId and scroll
            // to the top of the page manually
            if (!!sectionIdFromURL) {
              navigate('/onboarding/' + practiceLinksSectionId);
            } else {
              setSectionId(practiceLinksSectionId);
              window.scrollTo(0, 0);
            }
            throw new Error('Invalid Google Map Link');
          } else {
            throw e;
          }
        }
        return;
      }

      // if no survey exists, create new one
      const newSurvey: UpdateSurveyRequest = {
        name: 'Provider Survey',
        questions: params.questions,
      };
      await axios.post('/legacy/api/v1/surveys', newSurvey);
    },
  });

  // UPDATE PRACTICE PHOTO MUTATION
  const uploadPhotoMutation = useMutation({
    onError: handleApiError,
    mutationFn: async (file: File) => {
      const formData = new FormData();
      formData.append('photo', file);
      const res = await axios.post<Practice>(`/practices/${loggedInProvider.id}/photo`, formData);
      throwErrOnMessageResponse(res);
    },
    onSuccess: async () => {
      await refetchProvider({ includeSurvey: false });
    },
  });

  // PROVIDER MUTATION
  const providerMutation = useMutation({
    onError: handleApiError,
    mutationFn: async (params: OnUpdateProviderParams) => {
      const { survey, ...currentProvider } = loggedInProvider;

      // override the current offerings (in the array) if they have the same id
      const mergedOfferingsObj: { [key: string]: any } = {};
      const customOfferings: Partial<Offering>[] = [];
      currentProvider.offerings?.forEach((off) => {
        if (off.id) {
          mergedOfferingsObj[off.id] = off;
        } else {
          // offerings without id will just be added in
          // - an exception: if new offerings being added in dont have id (overrideOfferingsWithoutId is true), they will override these values (instead of adding on top of it)
          if (!params.overrideOfferingsWithoutId) {
            customOfferings.push(off);
          }
        }
      });
      params.data.offerings?.forEach((off) => {
        if (off.id) {
          mergedOfferingsObj[off.id] = off;
        } else {
          customOfferings.push(off);
        }
      });
      const mergedOfferingsWithIds = Object.values(mergedOfferingsObj);
      const mergedOfferings = [...mergedOfferingsWithIds, ...customOfferings];

      // remove offerings that were purposely left unchecked
      const checkedOnlyOfferings = mergedOfferings.filter((off) => {
        if (!params.uncheckedOfferings) return true;
        const hasBeenUnchecked = params.uncheckedOfferings.find((x) => x.id === off.id);
        return !hasBeenUnchecked;
      });

      // setup PUT object
      const updatedProvider: UpdatePracticeRequest = {
        ...currentProvider,
        ...params.data,
        offerings: checkedOnlyOfferings,
      };
      const res = await axios.put<Practice>(`/practices/${loggedInProvider.id}`, updatedProvider);
      throwErrOnMessageResponse(res);
    },
    onSuccess: async () => {
      await refetchProvider({ includeSurvey: false });
    },
  });

  // OFFERINGS QUERY
  const offeringsQuery = useQuery({
    queryKey: ['offerings'],
    queryFn: () => axios.get<Offering[]>(`/practices/offerings`),
    throwOnError: true,
  });
  const offerings = offeringsQuery.data?.data || [];

  // if invalid sectionId in URL, redirect to dashboard
  if (sectionIdFromURL && !isValidSectionId({ sectionId: sectionIdFromURL })) {
    return <Navigate to="/dashboard" replace />;
  }

  return (
    <OnboardingView
      loggedInProvider={loggedInProvider}
      offerings={offerings}
      onUpdateProvider={providerMutation.mutateAsync}
      onUpdateProviderPhoto={uploadPhotoMutation.mutateAsync}
      onUpdateSurvey={surveyMutation.mutateAsync}
      sectionIdFromURL={sectionId}
    />
  );
};
