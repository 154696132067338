import { Hero } from './components/Hero';
import { MembersList } from './components/MembersList';
import { LoggedInProvider } from '@/components/GlobalContext';

type Props = {
  provider: LoggedInProvider;
};
export const DashboardView = (props: Props) => {
  const { provider } = props;

  return (
    <div>
      <Hero
        provider={{
          name: provider.name,
          type: provider.type,
          address: provider.address,
        }}
      />
      <MembersList />
    </div>
  );
};
