import { StepContainer } from '../../StepContainer';
import { LoadingScreen } from '@/components/GlobalContext/components/LoadingScreen';
import { ExpandableCheckbox } from '@/components/UIKit/Inputs/Checkbox/ExpandableCheckbox';
import { NumberTextField } from '@/components/UIKit/Inputs/TextField/NumberTextField';
import { SurveySectionFields } from '@/scenes/Onboarding/types';
import { Offering } from '@/types/apiContract/practice';
import { yupNumber } from '@/utils/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormHelperText, InputAdornment } from '@mui/material';
import { useState } from 'react';
import { UseFormProps, useForm } from 'react-hook-form';
import * as yup from 'yup';

type SubmitData = {
  offerings: Required<Offering>[];
};
type Props = {
  offeringOptions: Offering[];
  defaultValues?: Partial<SubmitData>;
  onBack: () => void;
  onContinue: (data: SubmitData) => Promise<void>;
};

const PRICE_PER_OPTIONS = [{ value: 'PER_UNIT', label: 'Per unit' }] satisfies {
  value: Offering['basis'];
  label: string;
}[]; // based off backend
type PricePerType = (typeof PRICE_PER_OPTIONS)[number]['value'];

const TITLE = 'Neurotoxins offered';
const SUBTITLE =
  'Please check all boxes below for neurotoxins that your practice offers. Specify your price per area or per unit.';

// FIELDS
// IMPORTANT: treat this as a contract, this determines which values gets passed up to the backend
const FIELDS: SurveySectionFields<SubmitData> = {
  offerings: {
    label: 'Neurotoxins offers',
    subtitle: SUBTITLE,
    type: 'json',
    example: [
      {
        id: 'id123',
        name: 'name123',
        type: 'JOYA',
        basis: PRICE_PER_OPTIONS[0].value,
        price: '12.50',
      },
    ],
  },
};
export { FIELDS as NeurotoxinOffersFields };

export const NeurotoxinsOffers = (props: Props) => {
  // wait for options to load, since the fields (useForm) are dependent on it
  if (props.offeringOptions.length === 0) {
    return <LoadingScreen />;
  }
  return <NeurotoxinsOffersView {...props} />;
};
export const NeurotoxinsOffersView = (props: Props) => {
  const defaultOfferingValues = props.offeringOptions.map((offering) => {
    const defaultVal = props.defaultValues?.offerings?.find((x) => x.id === offering.id);
    return {
      isChecked: !!defaultVal,
      price: defaultVal?.price || '',
      pricePer: (defaultVal?.basis as PricePerType) || ('PER_UNIT' satisfies PricePerType),
    };
  });

  const { control, handleSubmit, register } = useNeurotoxinOffersForm({
    defaultValues: {
      offerings: defaultOfferingValues,
    },
  });
  const [formErrMessage, setFormErrMessage] = useState<string>();

  const onSubmit = async (values: FormInputs) => {
    // setup submit data
    const offeringsArr: Required<Offering>[] = [];
    props.offeringOptions.forEach((offeringOption, i) => {
      const data = values.offerings[i];
      if (!data.isChecked) return;
      if (!data.price || !data.pricePer) {
        throw new Error('expected price and pricePer values');
      }

      offeringsArr.push({
        ...offeringOption,
        price: data.price,
        basis: data.pricePer,
      });
    });

    if (offeringsArr.length < 1) {
      setFormErrMessage('Please select at least one neurotoxin offer');
      return;
    }

    setFormErrMessage(undefined);
    await props.onContinue({ offerings: offeringsArr });
  };

  return (
    <StepContainer
      heading={TITLE}
      description={SUBTITLE}
      onBack={props.onBack}
      onContinue={() => handleSubmit(onSubmit)()}
    >
      {props.offeringOptions.map((offer, i) => {
        return (
          <ExpandableCheckbox
            control={control}
            register={register(`offerings.${i}.isChecked`)}
            key={offer.id}
            label={offer.name}
          >
            <NumberTextField
              control={control}
              register={register(`offerings.${i}.price`)}
              placeholder="Enter price"
              numberFieldProps={{
                maxDecimals: 2,
              }}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{PRICE_PER_OPTIONS[0].label}</InputAdornment>
                ),
              }}
            />
          </ExpandableCheckbox>
        );
      })}
      {formErrMessage && <FormHelperText error>{formErrMessage}</FormHelperText>}
    </StepContainer>
  );
};
NeurotoxinsOffers.defaultProps = {
  onBack: () => {},
  onContinue: async () => {},
};

type Offer = {
  isChecked: boolean;
  price?: string;
  pricePer?: PricePerType;
};

// form fields
// IMPORTANT: treat this as a contract, this is how values are read from the backend (see defaultValues prop)
type FormInputs = {
  offerings: Offer[];
};

const yupPricePer = () => {
  return yup.mixed<PricePerType>().oneOf(PRICE_PER_OPTIONS.map((opt) => opt.value));
};

const yupOffer = () => {
  return yup.object({
    isChecked: yup.boolean().required(),
    price: yupNumber().when('isChecked', {
      is: true,
      then: yupNumber()
        .required()
        .test({
          message: 'Price must be greater than 0',
          test: (value) => {
            return Number(value) > 0;
          },
        }),
    }),
    pricePer: yupPricePer().when('isChecked', {
      is: true,
      then: yupPricePer().required(),
    }),
  });
};

const useNeurotoxinOffersForm = (props?: UseFormProps<FormInputs>) => {
  let defaultValues = props?.defaultValues;
  const validationSchema: yup.SchemaOf<FormInputs> = yup.object({
    offerings: yup.array().of(yupOffer()),
  });

  return useForm<FormInputs>({
    ...props,
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });
};
