import { SvgIcon, SvgIconProps } from '@mui/material';

function LogoutIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M2.91683 12.8333C2.59467 12.8333 2.3335 12.5721 2.3335 12.25V1.74996C2.3335 1.4278 2.59467 1.16663 2.91683 1.16663H11.0835C11.4057 1.16663 11.6668 1.4278 11.6668 1.74996V3.49996H10.5002V2.33329H3.50016V11.6666H10.5002V10.5H11.6668V12.25C11.6668 12.5721 11.4057 12.8333 11.0835 12.8333H2.91683ZM10.5002 9.33329V7.58329H6.41683V6.41663H10.5002V4.66663L13.4168 6.99996L10.5002 9.33329Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export { LogoutIcon };
