import { GlobalContext } from '../GlobalContext';
import { Header } from './components/Header/Header';
import { OnboardingLayout } from './components/OnboardingLayout';
import { Box } from '@mui/material';
import { useContext } from 'react';
import { Outlet, useLocation, useMatch } from 'react-router-dom';

export const AppLayout = () => {
  const { loggedInProvider } = useContext(GlobalContext);
  const location = useLocation();
  const isEditingOnboarding = useMatch('/onboarding/:sectionId');

  const isOnboarding = location.pathname === '/onboarding';
  const providerEmail = loggedInProvider.email;
  const providerRoles = loggedInProvider.roles;
  const providerType = loggedInProvider.type;

  if (isOnboarding || isEditingOnboarding) {
    return (
      <Box>
        <OnboardingLayout
          provider={{
            email: providerEmail,
            roles: providerRoles,
            type: providerType,
          }}
        >
          <Outlet />
        </OnboardingLayout>
      </Box>
    );
  }

  return (
    <Box>
      <Header
        provider={{
          email: providerEmail,
          roles: providerRoles,
          type: providerType,
        }}
      />
      <Outlet />
    </Box>
  );
};
