import { FillersOffersFields } from './components/Steps/FillersOffers';
import { GettingToKnowYouFields } from './components/Steps/GettingToKnowYou';
import { GettingToKnowYourPracticeFields } from './components/Steps/GettingToKnowYourPractice';
import { LetsLearnAboutYourPracticeFields } from './components/Steps/LetsLearnAboutYourPractice';
import { NeurotoxinOffersFields } from './components/Steps/NeurotoxinsOffers';
import { OurOfferingsFields } from './components/Steps/OurOfferings';
import { PracticeEnergyBasedDevicesFields } from './components/Steps/PracticeEnergyBasedDevices';
import { ProviderLanguagesFields } from './components/Steps/PracticeLanguages';
import { PracticeLinksFields } from './components/Steps/PracticeLinks';
import { SchedulingMethodFields } from './components/Steps/SchedulingMethod';
import { YourSuperpowersFields } from './components/Steps/YourSuperpowers';

// SURVEY_SECTIONS has the fields (specifically "survey.questions") that will be sent to the survey backend,
// it essentially dictates the shape of the data within the survey api, so writing and reading data from the survey api depends on this
// - IMPORTANT: treat this as a contract, changing this (the SURVEY_SECTIONS id or field ids) can potentially break reading the current values from the backend
// - NOTE: changes to this will be tracked (check surveySectionsSchema folder) to prevent accidental updates
export const SURVEY_SECTIONS = {
  'getting-to-know-you': {
    step: 1,
    fields: GettingToKnowYouFields,
  },
  'getting-to-know-your-practice': {
    step: 2,
    fields: GettingToKnowYourPracticeFields,
  },
  'practice-links': {
    step: 3,
    fields: PracticeLinksFields,
  },
  'providers-in-your-practice': {
    step: 4,
    fields: {}, // does not send or receive survey data
  },
  'our-offerings': {
    step: 5,
    fields: OurOfferingsFields,
  },
  'neurotoxin-offers': {
    step: 6,
    fields: NeurotoxinOffersFields,
  },
  'fillers-offers': {
    step: 7,
    fields: FillersOffersFields,
  },
  'practice-energy-based-devices': {
    step: 8,
    fields: PracticeEnergyBasedDevicesFields,
  },
  'practice-promotions': {
    step: 9,
    fields: {},
  },
  'scheduling-method': {
    step: 10,
    fields: SchedulingMethodFields,
  },
  'your-superpowers': {
    step: 11,
    fields: YourSuperpowersFields,
  },
  'practice-languages': {
    step: 12,
    fields: ProviderLanguagesFields,
  },
  'lets-learn-about-your-practice': {
    step: 13,
    fields: LetsLearnAboutYourPracticeFields,
  },
  'insurance-details': {
    step: 14,
    fields: {},
  },
};

// generate the all questionIds from SURVEY_STEPS,
// - the generated questionIds will be based on a {sectionId}_{fieldId} notation (ie: getting-to-know-you_email, getting-to-know-you_phoneNum)
const QUESTION_IDS_SET = new Set();
const STEPS_SET = new Set();
Object.entries(SURVEY_SECTIONS).forEach(([sectionKey, surveySection]) => {
  // ensure there are no duplicate steps
  if (STEPS_SET.has(surveySection.step)) {
    throw Error('duplicate step found');
  }
  STEPS_SET.add(surveySection.step);

  // generate the questionId using the {sectionId}_{fieldId} notation
  Object.keys(surveySection.fields).forEach((fieldId) => {
    const questionId = `${sectionKey}_${fieldId}`;
    QUESTION_IDS_SET.add(questionId);
  });
});

export { QUESTION_IDS_SET };
