import { Box, InputAdornment, styled, Switch, TextField, Typography } from '@mui/material';
import { TimeField as MuiTimeField, TimeFieldProps } from '@mui/x-date-pickers/TimeField';
import { format, parse, setHours, setMinutes } from 'date-fns';

const NINE_AM = setMinutes(setHours(new Date(), 9), 0);
const FIVE_PM = setMinutes(setHours(new Date(), 17), 0);
const DEFAULT_TIMES = { start: NINE_AM, end: FIVE_PM };
const DISABLED_DAYS = {
  monday: { isEnabled: false, ...DEFAULT_TIMES },
  tuesday: { isEnabled: false, ...DEFAULT_TIMES },
  wednesday: { isEnabled: false, ...DEFAULT_TIMES },
  thursday: { isEnabled: false, ...DEFAULT_TIMES },
  friday: { isEnabled: false, ...DEFAULT_TIMES },
  saturday: { isEnabled: false, ...DEFAULT_TIMES },
  sunday: { isEnabled: false, ...DEFAULT_TIMES },
};
const DEFAULT_DAYS = {
  ...DISABLED_DAYS,
  monday: { isEnabled: true, ...DEFAULT_TIMES },
  tuesday: { isEnabled: true, ...DEFAULT_TIMES },
  wednesday: { isEnabled: true, ...DEFAULT_TIMES },
  thursday: { isEnabled: true, ...DEFAULT_TIMES },
};

type DayKey = keyof typeof DEFAULT_DAYS;
export type DaysValue<T> = {
  [key in DayKey]: { isEnabled: boolean; start: T; end: T };
};
type Props = {
  value: Partial<DaysValue<Date>>;
  onChange: (data: DaysValue<Date>) => void;
};
const ScheduleInput = (props: Props) => {
  const scheduleVal = {
    ...DISABLED_DAYS, // if day is not provided, show as disabled values (so the user can enable it if they want)
    ...props.value,
  };

  const onChange = (params: {
    key: DayKey;
    val: { start?: Date | null; end?: Date | null; isEnabled?: boolean };
  }) => {
    const { key, val } = params;
    const newData = {
      ...scheduleVal,
      [key]: { ...scheduleVal[key], ...val },
    };
    props.onChange(newData);
  };

  return (
    <div>
      {Object.entries(scheduleVal).map((entry) => {
        const key = entry[0] as DayKey;
        const val = entry[1];
        return (
          <DayRow key={key}>
            <Box display="flex" alignItems="center" gap="16px">
              <Switch
                checked={val.isEnabled}
                onChange={(e) => {
                  onChange({ key, val: { isEnabled: e.target.checked } });
                }}
              />
              <Typography variant="body1Medium" textTransform="capitalize">
                {key}
              </Typography>
            </Box>
            <Box width="457px">
              {val.isEnabled ? (
                <Box display="flex" alignItems="center" gap="16px">
                  <TimeField
                    value={val.start}
                    onChange={(val) => {
                      onChange({ key, val: { start: val } });
                    }}
                    format="hh:mm a"
                  />
                  <TimeField
                    value={val.end}
                    inputPlaceholder={'To'}
                    onChange={(val) => {
                      onChange({ key, val: { end: val } });
                    }}
                    format="hh:mm a"
                  />
                </Box>
              ) : (
                <TextField disabled placeholder="Closed" fullWidth />
              )}
            </Box>
          </DayRow>
        );
      })}
    </div>
  );
};
ScheduleInput.defaultProps = {
  onChange: () => {},
};

const DayRow = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export type TextFieldExtendedProps = TimeFieldProps<Date> & {
  inputPlaceholder?: string;
};

const TimeField = (props: TextFieldExtendedProps) => {
  const { inputPlaceholder, ...restProps } = props;
  return (
    <MuiTimeField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{props.inputPlaceholder || 'From'}</InputAdornment>
        ),
        ...props.InputProps,
      }}
      sx={{ input: { textAlign: 'end', border: '0px' }, ...props.sx }}
      {...restProps}
    />
  );
};

const scheduleValueStringToDate = (val: DaysValue<string>, formatString: string) => {
  const valueWithDate = {} as DaysValue<Date>;
  Object.entries(val).forEach((entry) => {
    const key = entry[0] as DayKey;
    const val = entry[1];
    const parseStart = parse(val.start, formatString, new Date());
    const parsedEnd = parse(val.end, formatString, new Date());

    valueWithDate[key] = {
      ...val,
      start: parseStart,
      end: parsedEnd,
    };
  });

  return valueWithDate;
};

const scheduleValueDateToString = (val: DaysValue<Date>, formatString: string) => {
  const valueWithString = {} as DaysValue<string>;
  Object.entries(val).forEach((entry) => {
    const key = entry[0] as DayKey;
    const val = entry[1];

    const formattedStart = format(new Date(val.start), formatString);
    const formattedEnd = format(new Date(val.end), formatString);
    valueWithString[key] = {
      ...val,
      start: formattedStart,
      end: formattedEnd,
    };
  });

  return valueWithString;
};

const scheduleValueEnabledOnly = (val: DaysValue<Date>) => {
  const valueWithOnlyEnabled = {} as DaysValue<Date>;
  Object.entries(val).forEach((entry) => {
    const key = entry[0] as DayKey;
    const val = entry[1];
    if (val.isEnabled) {
      valueWithOnlyEnabled[key] = val;
    }
  });

  return valueWithOnlyEnabled;
};

export {
  DEFAULT_DAYS,
  ScheduleInput,
  scheduleValueStringToDate,
  scheduleValueDateToString,
  scheduleValueEnabledOnly,
};
