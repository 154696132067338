import { dataDisplay } from './dataDisplay';
import { feedback } from './feedback';
import { inputs } from './inputs';
import { layout } from './layout';
import { navigation } from './navigation';
import { surfaces } from './surfaces';
import { utils } from './utils';
import { Theme } from '@mui/material/styles/createTheme';

const components = (theme: Theme) => ({
  ...dataDisplay(theme),
  ...feedback(theme),
  ...inputs(theme),
  ...layout(theme),
  ...navigation(theme),
  ...surfaces(theme),
  ...utils(theme),
});

export { components };
