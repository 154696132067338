import { NewInsuranceRequestInputs, NewInsuranceRequestModalView } from './View';
import { GlobalContext } from '@/components/GlobalContext';
import { CreateNewInsuranceRequest } from '@/types/apiContract/insurance';
import { handleApiError } from '@/utils/feedback';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useContext } from 'react';

type Props = {
  onClose: () => void;
};
export const NewInsuranceRequestModalContainer = (props: Props) => {
  const { loggedInProvider } = useContext(GlobalContext);

  // send insurance request
  const sendNewInsuranceRequestMutation = useMutation({
    onError: handleApiError,
    onSuccess: async () => {
      props.onClose();
    },
    mutationFn: async (req: CreateNewInsuranceRequest) => {
      return axios.post(`/practices/${loggedInProvider.id}/insurances`, req);
    },
  });

  const onSubmit = async (values: NewInsuranceRequestInputs) => {
    const req: CreateNewInsuranceRequest = {
      insuranceName: values.insuranceCarrier,
      planNames: values.planTypes.map((x) => x.name),
    };
    await sendNewInsuranceRequestMutation.mutateAsync(req);
  };

  return <NewInsuranceRequestModalView onClose={props.onClose} onSubmit={onSubmit} />;
};
