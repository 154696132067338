// This will let you set arbitrary props prefixed with '$' in components.
// Then you can freely access these props when using the styled function
// from @mui/material.
function shouldForwardProp(prop: string) {
  return isValidProp(prop);
}

function isValidProp(name: string) {
  return name.indexOf('$') !== 0;
}

export { shouldForwardProp };
