import { AuthLayout } from '../AuthLayout';
import { AuthFormHeading } from '../UI/misc';
import { PASSWORD_GUIDELINES } from '@/components/Modals/ChangePasswordModal/constants';
import { snackIt } from '@/components/SnackbarManager';
import { FormRow } from '@/components/UIKit/Form/styled';
import { CheckCircleFilledIcon, CheckCircleOutlineIcon } from '@/components/UIKit/Icons';
import { TextField } from '@/components/UIKit/Inputs/TextField/TextField';
import { yupPassword } from '@/utils/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, ButtonBase, Typography, styled } from '@mui/material';
import { useState } from 'react';
import { UseFormProps, useForm } from 'react-hook-form';
import * as yup from 'yup';

type Props = {
  onSubmit: (data: FormInputs) => Promise<void>;
  onResendCode?: () => Promise<{ success: boolean }>;
  hasConfirmCode?: boolean;
};

export const SetPasswordForm = (props: Props) => {
  const { control, register, handleSubmit, watch } = useSetPasswordForm({
    defaultValues: { hasConfirmCode: props.hasConfirmCode },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isOnResendCooldown, setIsOnResendCooldown] = useState(false);

  const onSubmit = async (data: FormInputs) => {
    try {
      setIsLoading(true);
      await props.onSubmit(data);
    } finally {
      setIsLoading(false);
    }
  };

  // put a 1 second cooldown on the resend code button to prevent spam click
  const onResendCodeClick = async () => {
    if (!props.onResendCode) throw Error('expected props.onResendCode');

    setIsOnResendCooldown(true);
    try {
      const res = await props.onResendCode();
      if (res.success) {
        snackIt.default({
          severity: 'success',
          message: 'Code sent successfully.',
        });
      }
    } catch (e: any) {
      throw Error(e);
    } finally {
      setTimeout(() => {
        setIsOnResendCooldown(false);
      }, 1000);
    }
  };

  const newPassword = watch('newPassword');
  const passesAllGuidelines = PASSWORD_GUIDELINES.every((pg) => pg.test(newPassword));

  return (
    <AuthLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AuthFormHeading
          title="Set a new password"
          description={
            props.hasConfirmCode
              ? 'We have sent a password reset code via email. Please enter it below to reset and set a new password'
              : 'To create a new password, please fill in the fields below.'
          }
        />
        {props.hasConfirmCode && (
          <FormRow mb="24px">
            <Box display="flex" flexDirection="column">
              <TextField
                control={control}
                register={register('code')}
                label="Code"
                placeholder="Enter Code"
              />
              {props.onResendCode && (
                <Typography variant="body3Emphasized" mt="12px">
                  Didn’t get a code? Check your spam or{' '}
                  <ResetCodeButton onClick={onResendCodeClick} disabled={isOnResendCooldown}>
                    <ResetCodeText variant="body2">resend code</ResetCodeText>
                  </ResetCodeButton>
                </Typography>
              )}
            </Box>
          </FormRow>
        )}
        <FormRow mb="24px">
          <PasswordTextField
            control={control}
            register={register('newPassword')}
            type={showPassword ? 'text' : 'password'}
            label="New Password"
            placeholder="New Password"
            InputProps={{
              endAdornment: (
                <Box>
                  <ShowHideButton onClick={() => setShowPassword((curr) => !curr)}>
                    <Typography variant="body2Medium">{showPassword ? 'Hide' : 'Show'}</Typography>
                  </ShowHideButton>
                </Box>
              ),
            }}
          />
        </FormRow>

        {PASSWORD_GUIDELINES.map((pg) => {
          const isPassing = pg.test(newPassword);

          return (
            <Box mb="10px" display="flex" alignItems="center" key={pg.message}>
              {isPassing ? (
                <CheckCircleFilledIcon fontSize="inherit" sx={{ color: '#FFD700' }} />
              ) : (
                <CheckCircleOutlineIcon fontSize="inherit" sx={{ color: '#C0BDB7' }} />
              )}
              <Typography ml="12px">{pg.message}</Typography>
            </Box>
          );
        })}

        <Box mt="32px">
          <LoadingButton
            variant="contained"
            type="submit"
            fullWidth
            loading={isLoading}
            disabled={!passesAllGuidelines}
            color="primary"
          >
            Save
          </LoadingButton>
        </Box>
      </form>
    </AuthLayout>
  );
};

type FormInputs = {
  hasConfirmCode?: boolean; // NOTE: this is not a real field in the UI, just being used for yup conditional
  code?: string;
  newPassword: string;
};
export type { FormInputs as SetPasswordFormInputs };

const useSetPasswordForm = (props?: UseFormProps<FormInputs>) => {
  const validationSchema: yup.SchemaOf<FormInputs> = yup.object({
    hasConfirmCode: yup.boolean(),
    code: yup.string().when('hasConfirmCode', {
      is: true,
      then: yup.string().min(6).required(),
    }),
    newPassword: yupPassword(),
  });

  return useForm<FormInputs>({
    ...props,
    resolver: yupResolver(validationSchema),
    defaultValues: props?.defaultValues,
  });
};

const ShowHideButton = styled(ButtonBase)`
  padding: 15px 17px 15px 0px;
`;

const PasswordTextField = styled(TextField)`
  .MuiInputBase-root {
    padding-right: 0px;
  }
  && {
    input {
      border-right: 0px;
    }
  }
`;

const ResetCodeButton = styled(ButtonBase)`
  vertical-align: inherit;

  :disabled {
    color: ${(props) => props.theme.palette.text.disabled};
  }
`;

const ResetCodeText = styled(Typography)`
  font-weight: 600;
  font-size: 14px;
  text-decoration: underline;
`;
