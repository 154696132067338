import { StepContainer } from '../../StepContainer';
import { ImageCropperModal } from './components/ImageCropperModal';
import avatarPlaceholderSrc from '@/assets/misc/placeholders/avatar-placeholder.png';
import { snackIt } from '@/components/SnackbarManager';
import { FormRow } from '@/components/UIKit/Form/styled';
import { BoxedRadioGroup } from '@/components/UIKit/Inputs/RadioGroup/RadioGroup';
import {
  DEFAULT_DAYS,
  DaysValue,
  ScheduleInput,
  scheduleValueDateToString,
  scheduleValueEnabledOnly,
  scheduleValueStringToDate,
} from '@/components/UIKit/Inputs/Schedule/ScheduleInput';
import { Select } from '@/components/UIKit/Inputs/Select/Select';
import { SELECT_OPTIONS_US_STATES } from '@/components/UIKit/Inputs/Select/constants/address';
import { NumberTextField } from '@/components/UIKit/Inputs/TextField/NumberTextField';
import { PhoneTextField } from '@/components/UIKit/Inputs/TextField/PhoneTextField';
import { TextField } from '@/components/UIKit/Inputs/TextField/TextField';
import useOpenable from '@/hooks/useOpenable';
import { SurveySectionFields } from '@/scenes/Onboarding/types';
import { cleanPhoneNum, formatPhoneNum } from '@/utils/formatting';
import { checkIfImageExists } from '@/utils/image';
import { yupNumber, yupPhoneNumber } from '@/utils/yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Avatar, Box, Button, FormHelperText, Typography, styled } from '@mui/material';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Controller, UseFormProps, useForm } from 'react-hook-form';
import * as yup from 'yup';

type SubmitData = Omit<FormInputs, 'businessHours'> & {
  businessHours: DaysValue<string>;
};
type Props = {
  onBack: () => void;
  onContinue: (values: SubmitData) => Promise<void>;
  onUploadAvatar: (file: File) => Promise<void>;
  avatarImgSrc?: string;
  defaultValues?: Partial<SubmitData>;
};
const MEDICAL_SPECIALTY_OPTIONS = [
  { value: 'MEDICAL_SPA', label: 'Medical Spa' },
  { value: 'DERMATOLOGY', label: 'Dermatology' },
  { value: 'PLASTIC_SURGERY', label: 'Plastic Surgery' },
];

const SKIN_CANCER_SCREENING_OPTIONS = [
  { value: 'full', label: 'Full body screening' },
  { value: 'partial', label: 'Partial screening (face, neck, hands) ' },
];

// FIELDS
// IMPORTANT: treat this as a contract, this determines which values gets passed up to the backend
const FIELDS: SurveySectionFields<SubmitData> = {
  businessHours: {
    label: 'Business Hours',
    type: 'json',
    example: {
      monday: { isEnabled: true, start: '9:00 AM', end: '5:00 PM' },
      tuesday: { isEnabled: true, start: '9:00 AM', end: '5:00 PM' },
      wednesday: { isEnabled: true, start: '9:00 AM', end: '5:00 PM' },
      thursday: { isEnabled: true, start: '9:00 AM', end: '5:00 PM' },
      friday: { isEnabled: true, start: '9:00 AM', end: '5:00 PM' },
      saturday: { isEnabled: false, start: '9:00 AM', end: '5:00 PM' },
      sunday: { isEnabled: false, start: '9:00 AM', end: '5:00 PM' },
    },
  },
  name: {
    label: 'Practice Name',
    type: 'string',
    example: 'lorem',
  },
  addressLine: {
    label: 'Street address or P.O. Box',
    type: 'string',
    example: 'lorem',
  },
  addressLine2: {
    label: 'Apt, suite, unit, building, floor, etc.',
    type: 'string',
    example: 'lorem',
  },
  zipCode: {
    label: 'Zip Code',
    type: 'string',
    example: 'lorem',
  },
  city: {
    label: 'City',
    type: 'string',
    example: 'lorem',
  },
  state: {
    label: 'State',
    type: 'string',
    example: 'lorem',
  },
  phoneNum: {
    label: 'Phone Number',
    type: 'string',
    example: 'lorem',
  },
  medicalSpecialty: {
    label: 'Medical Specialty',
    type: 'single-select',
    selectOptions: MEDICAL_SPECIALTY_OPTIONS.map((option) => option.value),
    example: MEDICAL_SPECIALTY_OPTIONS[0].value,
  },
  skinCancerScreening: {
    label: 'Skin Cancer Screening',
    type: 'single-select',
    selectOptions: SKIN_CANCER_SCREENING_OPTIONS.map((option) => option.value),
    example: SKIN_CANCER_SCREENING_OPTIONS[0].value,
  },
};
export { FIELDS as GettingToKnowYourPracticeFields };

export const GettingToKnowYourPractice = (props: Props) => {
  const { defaultValues } = props;

  const {
    isOpen: isUploadModalOpen,
    onOpen: onUploadModalOpen,
    onClose: onUploadModalClose,
  } = useOpenable();

  // we send formatted data on submit, so wed also want to be able to take in that same formatted data
  const defaultBusinessHours = defaultValues?.businessHours
    ? scheduleValueStringToDate(defaultValues.businessHours, 'p')
    : DEFAULT_DAYS;

  const { control, handleSubmit, register } = usePracticeForm({
    defaultValues: {
      medicalSpecialty: MEDICAL_SPECIALTY_OPTIONS[0].value,
      skinCancerScreening: SKIN_CANCER_SCREENING_OPTIONS[0].value,
      ...defaultValues,
      phoneNum: formatPhoneNum(defaultValues?.phoneNum || ''),
      businessHours: defaultBusinessHours,
    },
  });

  const [formErrMessage, setFormErrMessage] = useState<string>();
  const [uploadKey, setUploadKey] = useState(new Date().getTime()); // provider.photo url doesnt actually change, so manually forcing an image refetch on upload

  const { open, acceptedFiles, getInputProps, getRootProps } = useDropzone({
    multiple: false,
    accept: { 'image/*': ['.jpg', '.jpeg', '.png'] },
    maxSize: 15 * 1024 * 1024, // 15MB
    onDrop: async () => {
      onUploadModalOpen();
    },
    onError: (err) => {
      snackIt.default({ severity: 'error', message: err.message });
    },
    noClick: true, // Disable click behavior
    noKeyboard: true, // Disable keydown behavior
  });

  const onSubmit = async (values: FormInputs) => {
    const enabledBusinessHours = scheduleValueEnabledOnly(values.businessHours);
    const businessHours = scheduleValueDateToString(enabledBusinessHours, 'p'); // Date values to 9:00 AM style
    if (!props.avatarImgSrc) {
      setFormErrMessage('Please upload a logo');
      return;
    }

    // provider.image always exists even before uploading, so have to check like this
    const { doesExist } = await checkIfImageExists(props.avatarImgSrc);
    if (!doesExist) {
      setFormErrMessage('Please upload a logo');
      return;
    }

    setFormErrMessage(undefined);
    await props.onContinue({
      ...values,
      phoneNum: cleanPhoneNum(values.phoneNum),
      businessHours,
    });
  };

  const onImageCropSave = async (file: File) => {
    await props.onUploadAvatar(file);
    setUploadKey((curr) => curr + 1);
    onUploadModalClose();
  };

  return (
    <StepContainer
      heading="Getting to know your practice"
      description="We would love to represent your practice in the best possible way."
      onBack={props.onBack}
      onContinue={() => handleSubmit(onSubmit)()}
    >
      {isUploadModalOpen && (
        <ImageCropperModal
          imageSrc={URL.createObjectURL(acceptedFiles[0])}
          onClose={onUploadModalClose}
          onSave={onImageCropSave}
          minimumPixels={500}
        />
      )}
      {/* Upload Section */}
      <Box mb="40px" display="flex" gap="40px" alignItems="start">
        <Avatar
          sx={{ height: '164px', width: '164px' }}
          src={props.avatarImgSrc + '?uploadKey=' + uploadKey}
        >
          {/* fallback */}
          <img src={avatarPlaceholderSrc} alt="placeholder" />
        </Avatar>
        <Box>
          <LogoText mb="12px">Logo</LogoText>
          <Typography
            variant="body2Medium"
            mb="20px"
            color="text.secondary"
            paragraph
            width="300px"
          >
            Minimum 500x500 px. JPG or PNG is allowed.
          </Typography>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <Button variant="contained" color="primary" type="button" onClick={open} size="small">
              Upload
            </Button>
          </div>
        </Box>
      </Box>
      {/* Details Section */}
      <FormRow>
        <TextField
          control={control}
          register={register('name')}
          label={FIELDS['name'].label}
          placeholder="Name"
        />
      </FormRow>
      <FormRow>
        <BoxedRadioGroup
          label={FIELDS.medicalSpecialty.label}
          control={control}
          register={register('medicalSpecialty')}
          row
          options={MEDICAL_SPECIALTY_OPTIONS}
        />
      </FormRow>
      <FormRow>
        <BoxedRadioGroup
          label={FIELDS.skinCancerScreening.label}
          control={control}
          register={register('skinCancerScreening')}
          row
          options={SKIN_CANCER_SCREENING_OPTIONS}
        />
      </FormRow>
      <FormRow>
        <Box display="flex" flexDirection="column" gap="12px">
          <TextField
            control={control}
            register={register('addressLine')}
            label="Address"
            placeholder={FIELDS['addressLine'].label}
          />
          <TextField
            control={control}
            register={register('addressLine2')}
            placeholder={FIELDS['addressLine2'].label}
          />
        </Box>
      </FormRow>
      <FormRow>
        <NumberTextField
          control={control}
          register={register('zipCode')}
          label="ZIP Code"
          placeholder={FIELDS['zipCode'].label}
          numberFieldProps={{
            selectAllOnFocus: false,
          }}
        />
      </FormRow>
      <FormRow mb="12px">
        <TextField
          control={control}
          register={register('city')}
          label={FIELDS['city'].label}
          placeholder={FIELDS['city'].label}
        />
        <Select
          control={control}
          register={register('state')}
          label={FIELDS['state'].label}
          placeholder={FIELDS['state'].label}
          options={SELECT_OPTIONS_US_STATES}
        />
      </FormRow>
      <Typography display="block" mb="20px" variant="body2Light" color="text.secondary">
        If you have several locations, you should create an account for each one.
      </Typography>
      <FormRow mb="40px">
        <PhoneTextField
          control={control}
          register={register('phoneNum')}
          label={FIELDS['phoneNum'].label}
        />
      </FormRow>

      <Typography variant="h4" mb="24px">
        {FIELDS['businessHours'].label}
      </Typography>
      <Controller
        control={control}
        name="businessHours"
        render={({ field }) => (
          <ScheduleInput
            value={field.value}
            onChange={(data) => {
              field.onChange(data);
            }}
          />
        )}
      />
      {formErrMessage && <FormHelperText error>{formErrMessage}</FormHelperText>}
    </StepContainer>
  );
};
GettingToKnowYourPractice.defaultProps = {
  onBack: () => {},
  onContinue: async () => {},
  onUploadAvatar: async () => {},
};

// form fields
// IMPORTANT: treat this as a contract, this is how values are read from the backend (see defaultValues prop)
type FormInputs = {
  name: string;
  medicalSpecialty: string;
  skinCancerScreening?: string;
  phoneNum: string;
  businessHours: DaysValue<Date>;
  addressLine: string;
  addressLine2?: string;
  zipCode: string;
  city: string;
  state: string;
};

const yupUnitedStatesZipCode = yupNumber()
  .min(5, 'Must have exactly 5 digits')
  .max(5, 'Must have exactly 5 digits');

const usePracticeForm = (props?: UseFormProps<FormInputs>) => {
  let defaultValues = props?.defaultValues;
  const validationSchema: yup.SchemaOf<FormInputs> = yup.object({
    name: yup.string().required(),
    medicalSpecialty: yup.string().required(),
    skinCancerScreening: yup.string().when('medicalSpecialty', {
      is: (val: string) => val !== 'DERMATOLOGY',
      then: yup.string().required().nullable(),
      otherwise: yup.string().notRequired().nullable(),
    }),
    phoneNum: yupPhoneNumber().required(),
    businessHours: yup.object().shape({}).required(),
    addressLine: yup.string().required(),
    addressLine2: yup.string(),
    zipCode: yupUnitedStatesZipCode.required(),
    city: yup.string().required(),
    state: yup.string().required(),
  });

  return useForm<FormInputs>({
    ...props,
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });
};

const LogoText = styled(Typography)`
  font-family: The Seasons;
  font-size: 26px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.6px;
`;
