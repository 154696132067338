import { AddMoreButton } from '../../../UI/buttons';
import { FormRow } from '@/components/UIKit/Form/styled';
import { TrashIcon } from '@/components/UIKit/Icons';
import { ExpandableCheckbox } from '@/components/UIKit/Inputs/Checkbox/ExpandableCheckbox';
import { TextField } from '@/components/UIKit/Inputs/TextField/TextField';
import { IconButton, InputAdornment } from '@mui/material';
import { ChangeEvent } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';

// AddableDeviceFields Component:
// - starts as a checkbox that when checked, creates a new set of (specific) fields
// - more sets of fields can be added as desired
// - when the checkbox is unchecked, fields are removed
type DeviceTypeField = { brandName: string };
export type DeviceFieldsData = {
  isChecked: boolean;
  fields?: DeviceTypeField[];
};
type Props = {
  label: string;
  onChange: (data: DeviceFieldsData) => void;
  value: DeviceFieldsData;
  errors?: FieldErrors<DeviceFieldsData>;
};
export const AddableDeviceFields = (props: Props) => {
  const { control, register, watch } = useForm<{ isChecked: boolean }>({
    defaultValues: {
      isChecked: props.value.isChecked,
    },
  });
  const isChecked = watch('isChecked');
  const fields = props.value.fields || [];

  const onChange = (index: number, newVal: DeviceTypeField) => {
    const newArr = [...fields];
    newArr[index] = newVal;
    props.onChange({ isChecked, fields: newArr });
  };

  const onAdd = () => {
    const newArr = [...fields, { brandName: '' }];
    props.onChange({ isChecked, fields: newArr });
  };

  const onDelete = (index: number) => {
    const newArr = [...fields];
    newArr.splice(index, 1);
    props.onChange({ isChecked, fields: newArr });
  };

  const onCheckChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      // on first open
      props.onChange({
        isChecked: Boolean(e.target.value),
        fields: [{ brandName: '' }],
      });
    } else {
      // on close, remove fields
      props.onChange({ isChecked: Boolean(e.target.value), fields: [] });
    }
  };

  return (
    <ExpandableCheckbox
      label={props.label}
      control={control}
      register={register('isChecked', {
        onChange: onCheckChange,
      })}
    >
      {fields.map((currValues, i) => {
        return (
          <FormRow key={i} sx={{ gap: '8px', mb: '16px' }}>
            <TextField
              placeholder="Select brand name"
              value={currValues.brandName}
              onChange={(e) => {
                const val = e.target.value;
                onChange(i, { ...currValues, brandName: val });
              }}
              error={Boolean(props.errors?.fields && props.errors.fields[i]?.brandName)}
              helperText={props.errors?.fields && props.errors.fields[i]?.brandName?.message}
              sx={{
                input: { borderRight: '0px' },
              }}
              {...(i !== 0 && {
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => onDelete(i)}>
                        <TrashIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              })}
            />
          </FormRow>
        );
      })}
      <AddMoreButton onClick={onAdd} btnText="Add more" />
    </ExpandableCheckbox>
  );
};
AddableDeviceFields.defaultProps = {
  onChange: () => {},
};
