import { GlobalContext } from '../../components/GlobalContext';
import { AddYourTeam, OnNextResponse } from './View';
import { snackIt } from '@/components/SnackbarManager';
import { Assistant, CreateAssistantRequest } from '@/types/apiContract/assistant';
import { getApiErrorMessage, handleApiError } from '@/utils/feedback';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

export const AddYourTeamContainer = () => {
  const { loggedInProvider } = useContext(GlobalContext);
  const navigate = useNavigate();

  // create new assistant mutation
  const createAssistantMutation = useMutation({
    mutationFn: (email: string) => {
      const createAssistant: CreateAssistantRequest = {
        email: email.toLowerCase(), // TODO: this is a temporary fix - case sensitivity should be handled on the backend,
        role: 'USER',
      };
      return axios.post<Assistant>(`/practices/${loggedInProvider.id}/assistants`, createAssistant);
    },
  });

  // want user to see if specific emails have failed
  const createAssistants = async (emails: string[]) => {
    const failedEmails: { email: string; errMessage: string }[] = [];
    const successfulEmails: { email: string; assistantId: string }[] = [];

    await Promise.all(
      emails.map(async (email) => {
        try {
          const res = await createAssistantMutation.mutateAsync(email);
          successfulEmails.push({
            email: res.data.email,
            assistantId: res.data.id,
          });
          return res;
        } catch (e: any) {
          let errMessage = getApiErrorMessage(e);
          if (errMessage.startsWith('An account with the given email already exists.')) {
            errMessage = 'An account with the given email already exists.';
          } else {
            handleApiError(e);
          }
          failedEmails.push({ email, errMessage });
        }
      }),
    );

    return { failedEmails, successfulEmails };
  };

  // delete assistant mutation
  const deleteAssistantMutation = useMutation({
    onError: handleApiError,
    mutationFn: (assistantId: string) => {
      return axios.delete(`/practices/${loggedInProvider.id}/assistants/${assistantId}`);
    },
  });

  const onNext = async (emails: string[]): Promise<OnNextResponse> => {
    if (!emails.length) {
      navigate('/onboarding');
      return null;
    }

    const { failedEmails, successfulEmails } = await createAssistants(emails);
    if (failedEmails.length > 0) {
      return { failedEmails, successfulEmails };
    }

    snackIt.default({
      severity: 'success',
      message: 'Team member(s) added successfully.',
    });
    navigate('/onboarding');
    return null;
  };

  const onCancelInvitation = async (assistantId: string) => {
    await deleteAssistantMutation.mutateAsync(assistantId);
  };

  return <AddYourTeam onNext={onNext} onCancelInvitation={onCancelInvitation} />;
};
