import { ProviderFormInputs, ProviderModalView } from './View';
import { GlobalContext } from '@/components/GlobalContext';
import {
  CreateProviderRequest,
  Provider,
  UpdateProviderRequest,
} from '@/types/apiContract/provider';
import { handleApiError } from '@/utils/feedback';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useContext } from 'react';

type Props = {
  currentProvider?: ProviderFormInputs & {
    id: string;
  };
  onClose: () => void;
  onComplete: () => Promise<void>;
};
export const ProviderModalContainer = (props: Props) => {
  const { loggedInProvider } = useContext(GlobalContext);

  // create new or edit current (if currentProvider exists)
  const { mutate, isPending } = useMutation({
    onError: handleApiError,
    mutationFn: (providerFormVals: ProviderFormInputs) => {
      const provider = formValsToProvider(providerFormVals);
      // create new
      if (!props.currentProvider) {
        const createProviderRequest: CreateProviderRequest = provider;
        return axios.post<Provider>(
          `/practices/${loggedInProvider.id}/providers`,
          createProviderRequest,
        );
      }

      // edit current
      const updateProviderRequest: UpdateProviderRequest = {
        id: props.currentProvider.id,
        ...provider,
      };
      return axios.put<Provider>(
        `/practices/${loggedInProvider.id}/providers/${props.currentProvider.id}`,
        updateProviderRequest,
      );
    },
    onSuccess: async () => {
      await props.onComplete();
    },
  });

  return (
    <ProviderModalView
      defaultValues={props.currentProvider}
      onClose={props.onClose}
      isLoading={isPending}
      onSubmit={(data) => {
        mutate(data);
      }}
    />
  );
};
ProviderModalContainer.defaultProps = {
  onComplete: async () => {},
};

const formValsToProvider = (provider: ProviderFormInputs): CreateProviderRequest => {
  return {
    email: provider.email,
    firstName: provider.firstName,
    lastName: provider.lastName,
    phone: provider.phone,
    specialization: provider.specialization,
  };
};
