import { ThemeOptions } from '@mui/material';

const palette: ThemeOptions['palette'] = {
  primary: {
    main: '#FFD700',
    dark: '#F9C50C',
    light: '#FFE248',
  },
  secondary: {
    main: '#181816',
    light: 'D2CEC6',
  },
  text: {
    primary: '#171C17',
    secondary: '#78756e',
    disabled: '#C0BDB7',
  },
  action: {
    hover: '#ECEAE6',
  },
  success: {
    main: '#586944',
  },
  error: {
    main: '#C1281D',
    light: '#F9C50C',
  },
};

export { palette };
