import {
  BENEFITS_TO_ONE_TIME_USE_VISIT_REASONS,
  ONE_TIME_USE_VISIT_REASONS,
} from '@/scenes/Checkout/constants';
import { Member } from '@/types/apiContract/member';
import { VisitReason } from '@/types/apiContract/visit';

// check if the member has claimed skin check
export const hasClaimedSkinCancerCheck = (member: Member): boolean => {
  return !!member.consumerClaimHistory.benefitsInfo.find(
    (b) => b.type === 'SKIN_CANCER_CHECK' && b.claimed,
  );
};

// get one time use visit reasons that have already been used by this member
export const getOneTimeUseVisitReasonsFromBenefits = (member: Member): VisitReason['type'][] => {
  const claimedVisitReasonTypes: VisitReason['type'][] = [];
  member.consumerClaimHistory.benefitsInfo.forEach((benefit) => {
    if (benefit.claimed) {
      const oneTimeVisitReasonFromBenefit = BENEFITS_TO_ONE_TIME_USE_VISIT_REASONS[benefit.type];
      // get "one time use" visit reasons that have already been used by this member and disable them
      if (
        oneTimeVisitReasonFromBenefit &&
        ONE_TIME_USE_VISIT_REASONS.includes(oneTimeVisitReasonFromBenefit)
      ) {
        claimedVisitReasonTypes.push(oneTimeVisitReasonFromBenefit);
      }
    }
  });
  return claimedVisitReasonTypes;
};
