import { REMEMBER_ME_EXPIRY_TIME_LOCAL_STORAGE_KEY } from '@/constants/localStorage';
import { isPast, isValid } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';

// checks if user should log out (based on a localstorage value - which should be set if "remember me" was unchecked in login screen)
export const useRememberMeLogout = (props: { onSessionExpire: () => void }) => {
  const { onSessionExpire } = props;
  const [hasCalledExpire, setHasCalledExpire] = useState(false);

  const logoutCheck = useCallback(() => {
    if (hasCalledExpire) return;

    const logoutTime = getLogoutTimeFromLocalStorage();
    if (!logoutTime) return;

    if (isPast(logoutTime)) {
      setHasCalledExpire(true);
      onSessionExpire();
    }
  }, [onSessionExpire, hasCalledExpire]);

  // do an initial check + check every once in a while if user should be logged out
  // - could also just do a single setTimeout(), but feel like this is simpler (ie: no need to wait for user login)
  // - some notes about how browsers handle setTimeout/setInterval when tab is inactive:
  //   - https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#timeouts_in_inactive_tabs
  //   - https://stackoverflow.com/questions/15871942/how-do-browsers-pause-change-javascript-when-tab-or-window-is-not-active
  useEffect(() => {
    logoutCheck();
    const interval = setInterval(() => {
      logoutCheck();
    }, 1000 * 30); // check every 30 seconds
    return () => clearInterval(interval);
  }, [logoutCheck]);
};

const getLogoutTimeFromLocalStorage = () => {
  const logoutTimeNumber = window.localStorage.getItem(REMEMBER_ME_EXPIRY_TIME_LOCAL_STORAGE_KEY);
  if (!logoutTimeNumber) return undefined;

  try {
    const logoutTime = new Date(parseInt(logoutTimeNumber));
    if (!isValid(logoutTime)) {
      throw Error(
        `invalid date time from localstorage key: ${REMEMBER_ME_EXPIRY_TIME_LOCAL_STORAGE_KEY}`,
      );
    }
    return logoutTime;
  } catch (e) {
    console.error(e);
    console.warn(
      `clearing invalid localstorage data: ${REMEMBER_ME_EXPIRY_TIME_LOCAL_STORAGE_KEY}`,
    );
    window.localStorage.removeItem(REMEMBER_ME_EXPIRY_TIME_LOCAL_STORAGE_KEY);
    return undefined;
  }
};
