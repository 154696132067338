import { yupResolver } from '@hookform/resolvers/yup';
import { UseFormProps, useForm } from 'react-hook-form';
import * as yup from 'yup';

// form fields
// IMPORTANT: treat this as a contract, this is how values are read from the backend (see defaultValues prop)
type FormInputs = {
  facialPeelSunscreenTypes: {
    facialPeelType: string;
    sunscreenType: string;
    cost: string;
  }[];
  privateLabelDiscount: string | null | undefined;
  brandLabelDiscount: string | null | undefined;
  selectedEmrPmrSchedulingSystems: {
    MODERNIZING_MEDICINE?: boolean;
    NEXTECH?: boolean;
    EZDERM?: boolean;
    AESTHETIC_RECORDS?: boolean;
    ADVANCED_MD?: boolean;
    OTHER?: { isChecked?: boolean; specifyText: string };
  };
};
export type { FormInputs as LearnAboutYourPracticeFormInputs };

const yupIsCheckedAndSpecifyText = () => {
  return yup
    .object({
      isChecked: yup.boolean(),
      specifyText: yup.string().when('isChecked', {
        is: true,
        then: yup.string().required('Please specify the other system.'),
      }),
    })
    .default(undefined)
    .optional();
};

export const useLearnAboutYourPracticeForm = (props?: UseFormProps<FormInputs>) => {
  let defaultValues = props?.defaultValues;
  const validationSchema: yup.SchemaOf<FormInputs> = yup.object({
    facialPeelSunscreenTypes: yup.array(
      yup.object({
        facialPeelType: yup.string().required(''),
        sunscreenType: yup.string().required(''),
        cost: yup
          .string()
          .required('')
          .test({
            message: '',
            test: (value) => {
              return Number(value) > 0;
            },
          }),
      }),
    ),
    privateLabelDiscount: yup.string().nullable(),
    brandLabelDiscount: yup.string().nullable(),
    selectedEmrPmrSchedulingSystems: yup.object({
      MODERNIZING_MEDICINE: yup.boolean(),
      NEXTECH: yup.boolean(),
      EZDERM: yup.boolean(),
      AESTHETIC_RECORDS: yup.boolean(),
      ADVANCED_MD: yup.boolean(),
      OTHER: yupIsCheckedAndSpecifyText(),
    }),
  });

  return useForm<FormInputs>({
    ...props,
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
    mode: 'onChange',
  });
};
